/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ordered new application
 * @export
 * @interface ApplicationOrderDTO
 */
export interface ApplicationOrderDTO {
    /**
     * applicationKey
     * @type {string}
     * @memberof ApplicationOrderDTO
     */
    applicationKey: string;
    /**
     * additionalInformation for processing the order of the application
     * @type {{ [key: string]: string | undefined; }}
     * @memberof ApplicationOrderDTO
     */
    additionalInformation?: { [key: string]: string | undefined; };
    /**
     * 
     * @type {string}
     * @memberof ApplicationOrderDTO
     */
    iamOrderId?: string;
}

export function ApplicationOrderDTOFromJSON(json: any): ApplicationOrderDTO {
    return ApplicationOrderDTOFromJSONTyped(json, false);
}

export function ApplicationOrderDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationOrderDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationKey': json['applicationKey'],
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'iamOrderId': !exists(json, 'iamOrderId') ? undefined : json['iamOrderId'],
    };
}

export function ApplicationOrderDTOToJSON(value?: ApplicationOrderDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationKey': value.applicationKey,
        'additionalInformation': value.additionalInformation,
        'iamOrderId': value.iamOrderId,
    };
}


