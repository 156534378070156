import { ContentTreeItem } from '../gen/ppapiclient';

export function isAncestorOf(ancestorSlug: string, compareSlug: string): boolean {
    return compareSlug.startsWith(ancestorSlug);
}

export function translateToSlug(pathname: string): string {
    // removes leading '/' of path
    return pathname.substring(1);
}

export function getTopLevelNavigationOfSlug(
    navigationTree: ContentTreeItem[],
    slug: string
): ContentTreeItem | undefined {
    return navigationTree.find((item) => isAncestorOf(item.fullSlug, slug));
}
