import React from 'react';
import { ThemeProps, useFela, WithTheme } from '@dm/design-system';

const offCanvasBackdrop = ({ theme }: WithTheme) => ({
    zIndex: theme.dimension.zIndex.layerBackground,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.2)',
    padding: 0,
    border: 'none',
});

export default function OffCanvasBackdrop() {
    const { css } = useFela<ThemeProps>();

    return <div data-dmid="off-canvas-backdrop" className={css(offCanvasBackdrop)} tabIndex={-1} />;
}
