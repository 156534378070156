import { Box, EyeIcon, EyeStrokeIcon, InputWithIcon, Style, WithTheme } from '@dm/design-system';
import React, { ChangeEventHandler, ReactElement, useRef, useState } from 'react';

const boxStyle: Style = ({ theme }: WithTheme) => ({
    marginBottom: 10,
    justifyContent: 'start',
    [theme.breakpoints.horizontal.s.mediaQuery]: {
        flex: 1,
    },
});

export interface ValidatedPasswordFieldProps {
    label: string;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    dmId: string;
    invalid: boolean;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    customErrorMessage?: string;
    onCheckValidity?: () => void;
}

export default function ValidatedPasswordField({
    label,
    maxLength,
    minLength,
    required,
    dmId,
    invalid,
    customErrorMessage,
    onChange,
    onCheckValidity,
}: Readonly<ValidatedPasswordFieldProps>): ReactElement {
    const [passwordType, setPasswordType] = useState<'password' | 'text'>('password');
    const togglePasswordType = () => {
        // eslint-disable-next-line no-unused-expressions
        passwordType === 'password' ? setPasswordType('text') : setPasswordType('password');
    };

    const ref = useRef<HTMLInputElement>();
    return (
        <Box style={boxStyle}>
            <InputWithIcon
                label={label}
                dmId={dmId}
                invalid={invalid}
                required={required}
                error="Error Message"
                maxLength={maxLength}
                minLength={minLength}
                // InputField is still typed for legacy ref api
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                reference={ref}
                onChange={onChange}
                icon={passwordType === 'password' ? EyeIcon : EyeStrokeIcon}
                iconButtonTitle={passwordType === 'password' ? 'Passwort anzeigen' : 'Passwort verbergen'}
                onIconClick={togglePasswordType}
                type={passwordType}
            />
        </Box>
    );
}
