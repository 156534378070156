import { ComponentType, createElement, Fragment, ReactElement, ReactNode } from 'react';
import { Blok } from '../StoryblokFieldTypes';

export interface RichtextNodeAttrs {
    id?: string;
    class?: string;
    level?: number;
    alt?: string;
    src?: string;
    title?: string;
    order?: number;
    body?: Blok[];
}

export type LinkType = 'story' | 'url' | 'asset' | 'email';

export interface RichtextMarkAttrs {
    href: string;
    uuid: string | null;
    anchor: string | null;
    target: '_self' | '_blank' | '';
    linktype: LinkType;
}

export interface RichtextMark {
    type: 'bold' | 'italic' | 'strike' | 'underline' | 'code' | 'link';
    attrs?: RichtextMarkAttrs;
}

export type ComponentMapKey =
    | 'blok'
    | 'code_block'
    | 'heading'
    | 'image'
    | 'blockquote'
    | 'hard_break'
    | 'horizontal_rule'
    | 'bullet_list'
    | 'list_item'
    | 'ordered_list'
    | 'paragraph';

export interface RichTextNode {
    type: ComponentMapKey | 'text';
    content?: RichTextNode[];
    text?: string;
    attrs?: RichtextNodeAttrs;
    marks?: RichtextMark[];
}

export interface RichtextComponentAttrs {
    class?: string;
    level?: number;
    alt?: string;
    src?: string;
    body?: Blok[];
}

export interface RichTextComponentProps {
    children?: ReactNode;
    attrs?: RichtextComponentAttrs;
    text?: string;
    marks?: RichtextMark[];
}

export type ComponentMapValue = ComponentType<RichTextComponentProps>;
export type ComponentMap = Record<ComponentMapKey, ComponentMapValue>;

export type RenderMap = ComponentMap & Record<'text', ComponentMapValue>;

export function determineKey(index: number, attrs: RichtextNodeAttrs | undefined, text: string | undefined): string {
    if (attrs?.id) {
        return `${attrs.id}-${index}`;
    }
    return text ? `${text.trim()}-${index}` : `content-${index}`;
}

export function renderNode(
    { type, content, text, attrs, marks }: RichTextNode,
    componentMap: RenderMap,
    index: number
): ReactElement {
    const key = determineKey(index, attrs, text);

    return createElement(
        componentMap[type] ?? Fragment,
        {
            key,
            text,
            attrs,
            marks,
        },
        renderContent(content, componentMap)
    );
}

export function renderContent(
    content: RichTextNode[] | undefined,
    componentMap: RenderMap
): ReactElement[] | undefined {
    return content?.map((node, index) => renderNode(node, componentMap, index));
}
