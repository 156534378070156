/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Consent,
    ConsentFromJSON,
    ConsentToJSON,
    Tenant,
    TenantFromJSON,
    TenantToJSON,
    TermsResponse,
    TermsResponseFromJSON,
    TermsResponseToJSON,
} from '../models';

export interface CreateConsentRequest {
    consent: Consent;
}

export interface GetTermsRequest {
    tenant: Tenant;
    showAllTerms?: boolean;
}

export interface ListConsentsRequest {
    termsName?: string;
    termsVersion?: string;
    userId?: string;
    tenant?: string;
    accepted?: boolean;
}

/**
 * TermsofuseApi - interface
 * 
 * @export
 * @interface TermsofuseApiInterface
 */
export interface TermsofuseApiInterface {
    /**
     * Creates a consent.
     * @summary Consent Management
     * @param {Consent} consent 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsofuseApiInterface
     */
    createConsentRaw(requestParameters: CreateConsentRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Creates a consent.
     * Consent Management
     */
    createConsent(requestParameters: CreateConsentRequest): Promise<void>;

    /**
     * 
     * @summary returns the terms the current user is allowed to see
     * @param {Tenant} tenant the tenant to get the terms for
     * @param {boolean} [showAllTerms] in case of false (default) only terms for the users\&#39; applications (and partnerportal) are returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsofuseApiInterface
     */
    getTermsRaw(requestParameters: GetTermsRequest): Promise<runtime.ApiResponse<Array<TermsResponse>>>;

    /**
     * returns the terms the current user is allowed to see
     */
    getTerms(requestParameters: GetTermsRequest): Promise<Array<TermsResponse>>;

    /**
     * Provides users consents. Usable by admin users only.
     * @summary Consent Management
     * @param {string} [termsName] 
     * @param {string} [termsVersion] 
     * @param {string} [userId] 
     * @param {string} [tenant] 
     * @param {boolean} [accepted] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TermsofuseApiInterface
     */
    listConsentsRaw(requestParameters: ListConsentsRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Provides users consents. Usable by admin users only.
     * Consent Management
     */
    listConsents(requestParameters: ListConsentsRequest): Promise<void>;

}

/**
 * 
 */
export class TermsofuseApi extends runtime.BaseAPI implements TermsofuseApiInterface {

    /**
     * Creates a consent.
     * Consent Management
     */
    async createConsentRaw(requestParameters: CreateConsentRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.consent === null || requestParameters.consent === undefined) {
            throw new runtime.RequiredError('consent','Required parameter requestParameters.consent was null or undefined when calling createConsent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/cms/consents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConsentToJSON(requestParameters.consent),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates a consent.
     * Consent Management
     */
    async createConsent(requestParameters: CreateConsentRequest): Promise<void> {
        await this.createConsentRaw(requestParameters);
    }

    /**
     * returns the terms the current user is allowed to see
     */
    async getTermsRaw(requestParameters: GetTermsRequest): Promise<runtime.ApiResponse<Array<TermsResponse>>> {
        if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
            throw new runtime.RequiredError('tenant','Required parameter requestParameters.tenant was null or undefined when calling getTerms.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        if (requestParameters.showAllTerms !== undefined) {
            queryParameters['showAllTerms'] = requestParameters.showAllTerms;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/terms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TermsResponseFromJSON));
    }

    /**
     * returns the terms the current user is allowed to see
     */
    async getTerms(requestParameters: GetTermsRequest): Promise<Array<TermsResponse>> {
        const response = await this.getTermsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Provides users consents. Usable by admin users only.
     * Consent Management
     */
    async listConsentsRaw(requestParameters: ListConsentsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.termsName !== undefined) {
            queryParameters['termsName'] = requestParameters.termsName;
        }

        if (requestParameters.termsVersion !== undefined) {
            queryParameters['termsVersion'] = requestParameters.termsVersion;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        if (requestParameters.accepted !== undefined) {
            queryParameters['accepted'] = requestParameters.accepted;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/consents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Provides users consents. Usable by admin users only.
     * Consent Management
     */
    async listConsents(requestParameters: ListConsentsRequest): Promise<void> {
        await this.listConsentsRaw(requestParameters);
    }

}
