import React, { ReactNode } from 'react';
import { useFela } from '@dm/design-system';
import { findNavigationItemInContentTreeById } from '../../../../navigation/NavigationUtil';
import { useContentTree } from '../../../../content/StoryProvider';
import { ContentTreeItem } from '../../../../gen/ppapiclient';
import { ROOT_ID } from '../offCanvas/OffCanvas';

const menuItems = () => ({
    listStyleType: 'none',
    padding: 0,
    margin: 0,
});

type OffCanvasNavigationItemsProps = {
    id: string;
    renderItem: ({ item }: { item: ContentTreeItem; customTitle?: string }) => ReactNode;
};

function OffCanvasNavigationItems({ id, renderItem }: Readonly<OffCanvasNavigationItemsProps>) {
    const { css } = useFela();
    const { navigation } = useContentTree();

    let visibleChildren: ContentTreeItem[];
    if (id === ROOT_ID) {
        visibleChildren = navigation;
    } else {
        const currentNavigationItem = findNavigationItemInContentTreeById(id, navigation);
        visibleChildren = currentNavigationItem?.item.children ?? [];
    }
    return (
        <div data-dmid="off-canvas-navigation-items">
            <ul className={css(menuItems)}>{visibleChildren.map((item: ContentTreeItem) => renderItem({ item }))}</ul>
        </div>
    );
}

export default OffCanvasNavigationItems;
