import { ThemeProps, useFela } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { RichTextComponentProps } from '../render';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const paragraphStyle = ({ theme }: { theme: ThemeProps }) => ({
    textAlign: 'justify',
    lineHeight: 1.6,
    marginBottom: theme.dimension.spacing.xxs.rem,
    marginTop: '5px',
});

export default function Paragraph({
    children,
}: Readonly<RichTextComponentProps>): ReactElement<RichTextComponentProps> {
    const { css } = useFela<ThemeProps>();

    return <p className={css(paragraphStyle)}>{children as string}</p>;
}
