import { checkMaintenanceState } from '../content/StoryProvider';
import { FetchParams, RequestContext } from '../gen/ppapiclient';

const maintenanceMiddleware = async (context: RequestContext): Promise<void | FetchParams> => {
    return checkMaintenanceState(context).then((fetchParams) => {
        if (fetchParams) {
            // Proceed with fetch request and possibly other logic
            return { ...context, url: fetchParams.url, init: fetchParams.init };
        }
        return undefined;
    });
};

export default maintenanceMiddleware;
