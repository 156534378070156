import React, { ReactElement, useState } from 'react';
import { idmApi } from '../../../../apiConfig';
import { ConfirmUserRequest, PasswordResetError, RecaptchaAction } from '../../../../gen/ppapiclient';
import SuccessRedirect from '../SuccessRedirect';
import SubmitChecker, { SubmitCheckerUserProps } from '../util/SubmitChecker';
import ConfirmUserForm, { ConfirmUserFormProps } from './ConfirmUserForm';

export interface ConfirmUserProps extends Omit<ConfirmUserFormProps, 'onSubmit'>, SubmitCheckerUserProps {
    invalidTokenErrorMessage?: string;
}

const FormFn = ({ getValidationErrorMessage, newPasswordLabel, repeatPasswordLabel, submitLabel }) =>
    function Form({ onSubmit }): ReactElement {
        return (
            <ConfirmUserForm
                submitLabel={submitLabel}
                onSubmit={onSubmit}
                newPasswordLabel={newPasswordLabel}
                repeatPasswordLabel={repeatPasswordLabel}
                validationErrorMessage={getValidationErrorMessage()}
            />
        );
    };

export default function ConfirmUser({
    newPasswordLabel,
    repeatPasswordLabel,
    submitLabel,
    errorMessage,
    validationErrorMessage,
    invalidTokenErrorMessage,
}: Readonly<ConfirmUserProps>): ReactElement {
    const [backendError, setBackendError] = useState<PasswordResetError | null>(null);
    const errorConfig = {
        [PasswordResetError.PASSWORD_POLICY_VIOLATION]: (): void =>
            setBackendError(PasswordResetError.PASSWORD_POLICY_VIOLATION),
        [PasswordResetError.INVALID_TOKEN_OR_USER]: (): void =>
            setBackendError(PasswordResetError.INVALID_TOKEN_OR_USER),
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function getValidationErrorMessage() {
        if (backendError === PasswordResetError.PASSWORD_POLICY_VIOLATION) return validationErrorMessage;
        if (backendError === PasswordResetError.INVALID_TOKEN_OR_USER) return invalidTokenErrorMessage;
        return undefined;
    }

    return (
        <SubmitChecker
            post={async (confirmUserRequest: ConfirmUserRequest, captchaToken): Promise<void> => {
                setBackendError(null);
                await idmApi.confirmUser({
                    userConfirmation: {
                        ...confirmUserRequest.userConfirmation,
                        recaptchaToken: captchaToken,
                    },
                });
            }}
            form={FormFn({ getValidationErrorMessage, newPasswordLabel, repeatPasswordLabel, submitLabel })}
            successComponent={SuccessRedirect}
            errorMessage={errorMessage}
            errorConfig={errorConfig}
            recaptchaAction={RecaptchaAction.CONFIRM_USER}
        />
    );
}
