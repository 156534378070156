/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Users home tenant
 * @export
 * @enum {string}
 */
export enum Tenant {
    AT = 'AT',
    BA = 'BA',
    BG = 'BG',
    CZ = 'CZ',
    DE = 'DE',
    HR = 'HR',
    HU = 'HU',
    IT = 'IT',
    RO = 'RO',
    RS = 'RS',
    SI = 'SI',
    SK = 'SK'
}

export function TenantFromJSON(json: any): Tenant {
    return TenantFromJSONTyped(json, false);
}

export function TenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tenant {
    return json as Tenant;
}

export function TenantToJSON(value?: Tenant | null): any {
    return value as any;
}

