import { TFunction } from 'i18next';
import { ErrorFieldMessages } from './types';

export const defaultErrorMessage = 'registrationForm.field.Invalid';

export function findErrorMessageByField(field: string, errorFieldMessages: ErrorFieldMessages, t: TFunction) {
    const errorMessage = errorFieldMessages.find((fieldErr) => fieldErr.field === field)?.message;
    if (!errorMessage) {
        return undefined;
    }

    return errorMessage === field ? t(defaultErrorMessage) : t(`${errorMessage}`);
}
