import { TermsResponse } from '../../gen/ppapiclient';

export type ApplicationStatus = {
    bannerKind: 'positive' | 'neutral' | 'warning' | 'error';
    bannerText: string;
    orderButtonDisabled: boolean;
    showSelection: boolean;
};

export type ApplicationStates = {
    [key: string]: ApplicationData;
};

export type ApplicationData = {
    terms?: TermsResponse;
    orderState?: string;
    expanded?: boolean;
};

export interface AppplicationAdditionalInformationFieldProps {
    label: string;
    name: string;
    required: boolean;
}

export const statusConfig = {
    ORDERED: {
        bannerKind: 'warning',
        bannerText: 'application.status.ordered',
        orderButtonDisabled: true,
        showSelection: true,
    },
    REJECTED: {
        bannerKind: 'error',
        bannerText: 'application.status.rejected',
        orderButtonDisabled: true,
        showSelection: true,
    },
    OWNED: {
        bannerKind: 'neutral',
        bannerText: 'application.status.owned',
        orderButtonDisabled: true,
        showSelection: true,
    },
    ORDERABLE: {
        bannerKind: 'positive',
        bannerText: 'application.status.orderable',
        orderButtonDisabled: false,
        showSelection: true,
    },
    UNAVAILABLE: {
        bannerKind: 'warning',
        bannerText: 'application.status.unavailable',
        orderButtonDisabled: true,
        showSelection: false,
    },
    SUBTYPE_NOT_ORDERABLE: {
        bannerKind: 'neutral',
        bannerText: 'application.status.subtype_not_orderable',
        orderButtonDisabled: true,
        showSelection: true,
    },
};
