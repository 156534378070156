import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import TranslationLoader from './i18n/TranslationLoader';
import getTenant, { supportedLanguagesForTenants } from './content/getTenant';

i18n.use(TranslationLoader)
    .use(initReactI18next)
    .init({
        fallbackLng: false,
        supportedLngs: Object.values(supportedLanguagesForTenants),
        lng: supportedLanguagesForTenants[getTenant()],
        load: 'currentOnly',
        debug: false,
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
