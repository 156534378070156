// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
import { ThemeProps } from '@dm/design-system';

export default ({ theme }: { theme: ThemeProps }): Record<string, unknown> => ({
    textDecoration: 'none',
    color: theme.palette.icon.hex,
    fontWeight: 500,
    marginRight: theme.dimension.spacing.m.rem,
    ':last-child': {
        marginRight: 0,
    },
    ':focus': {
        fontWeight: 700,
    },
    ':hover': {
        display: 'inline-block',
        transform: 'scale(1.05)',
    },
    outline: 'none !important',
});
