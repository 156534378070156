import React, { ReactElement } from 'react';
import ValidatedInputField from './ValidatedInputField';

export interface TextInputProps {
    label: string;
    type?: 'text' | 'email' | 'tel';
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    pattern?: RegExp;
    onChange?: (value: string) => void;
    dmId: string;
    error?: string;
    value?: string;
    disableTrimming?: boolean;
}

/**
 * Matches all strings that consist entirely of whitespace or that do not start
 * with a whitespace and do not have two whitespaces next to each other except
 * at start and end. At start and end multiple whitespace chars are allowed.
 */
const defaultPattern = /^\s*$|^\s*\S(\s?\S)*\s*$/;

export default function TextInput({
    label,
    type,
    required,
    minLength,
    maxLength,
    pattern,
    onChange,
    dmId,
    error,
    value,
    disableTrimming,
}: Readonly<TextInputProps>): ReactElement {
    return (
        <ValidatedInputField
            label={label}
            type={type}
            dmId={dmId}
            required={required}
            minLength={minLength}
            maxLength={maxLength}
            pattern={pattern ?? defaultPattern}
            onChange={(event): void => {
                if (onChange !== undefined) {
                    const inputValue = event.target.value;
                    onChange(disableTrimming ? inputValue : inputValue.trim());
                }
            }}
            customErrorMessage={error}
            value={value}
        />
    );
}
