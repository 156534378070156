import { Form } from '@dm/design-system';
import React, { CSSProperties, FormEventHandler, ReactElement, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ResetPasswordRequest } from '../../../../gen/ppapiclient';
import getTenant from '../../../getTenant';
import PasswordInputs, { NewPasswordInputsProps } from '../util/fields/PasswordInputs';
import { SubmitterProps } from '../util/SubmitChecker';

const rowStyle: CSSProperties = {
    flexDirection: 'row',
    width: '100%',
};

export interface ResetPasswordFormProps
    extends SubmitterProps<ResetPasswordRequest>,
        Omit<NewPasswordInputsProps, 'onChange'> {
    submitLabel: string;
}

export default function ResetPasswordForm({
    onSubmit,
    newPasswordLabel,
    repeatPasswordLabel,
    submitLabel,
    validationErrorMessage,
}: Readonly<ResetPasswordFormProps>): ReactElement {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const newPassword = useRef('');
    const email = queryParams.get('email') ?? '';
    const token = queryParams.get('token') ?? '';

    const submit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        onSubmit({
            passwordReset: {
                tenant: getTenant(),
                email,
                token,
                newPassword: newPassword.current,
            },
        });
    };

    return (
        <Form name="password-reset-form" method="post" onSubmit={submit} style={rowStyle}>
            <PasswordInputs
                onChange={(value: string): void => {
                    newPassword.current = value;
                }}
                repeatPasswordLabel={repeatPasswordLabel}
                newPasswordLabel={newPasswordLabel}
                validationErrorMessage={validationErrorMessage}
                submitLabel={submitLabel}
            />
        </Form>
    );
}
