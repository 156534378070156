import { List } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { RichTextComponentProps } from '../render';

export default function OrderedList({
    children,
}: Readonly<RichTextComponentProps>): ReactElement<RichTextComponentProps> {
    return (
        <List dmId="list" ordered>
            {
                children as JSX.Element // List declares a JSX.Element as children type, which is not necessary
            }
        </List>
    );
}
