import React, { ReactElement } from 'react';
import { Collapsible as DmCollapsible } from '@dm/design-system';
import { RichTextNode } from '../richtextRenderer/render';
import RichText from '../richtextRenderer/RichText';
import { componentMap, linkMap } from '../richtextRenderer/config';

const CollapsibleState = {
    expanded: 'expanded',
    collapsed: 'collapsed',
};

export interface CollapsibleProps {
    title: string;
    content: RichTextNode;
    initialState: string;
}

export default function Collapsible({ title, content, initialState }: Readonly<CollapsibleProps>): ReactElement {
    return (
        <DmCollapsible
            dmId="accordion"
            header={title}
            headerAriaLabel=""
            isInitiallyExpanded={initialState === CollapsibleState.expanded}
        >
            <RichText richText={content} componentMap={componentMap} linkMap={linkMap} />
        </DmCollapsible>
    );
}
