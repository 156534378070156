import React, { useCallback, useState } from 'react';
import { Box, Stepper, useFela } from '@dm/design-system';
import { STEP_STATUS } from '@dm/design-system/lib/Molecules/Stepper/models';
import { RecaptchaAction, TermsResponse, ValidateUserRequest } from '../../gen/ppapiclient';
import SubmitChecker from '../../content/components/idm/util/SubmitChecker';
import RegistrationForm from './components/RegistrationForm';
import { idmApi } from '../../apiConfig';
import SuccessRedirect from '../../content/components/idm/SuccessRedirect';
import RegistrationMfaForm from './components/RegistrationMfaForm';
import { ErrorFieldMessages, RegistrationBlok } from './types';

// eslint-disable-next-line no-shadow
export enum RegistrationStepIds {
    REGISTER = 0,
    MFA = 1,
}

const RegistrationFormFn = ({ formValues, labels, errorFieldMessages, terms }) =>
    function Form({ onSubmit }) {
        return (
            <RegistrationForm
                formValues={formValues}
                onSubmit={onSubmit}
                firstNameLabel={labels.firstNameLabel}
                lastNameLabel={labels.lastNameLabel}
                companyLabel={labels.companyLabel}
                vendorNumberLabel={labels.vendorNumberLabel}
                dmContactLabel={labels.dmContactLabel}
                categoryLabel={labels.categoryLabel}
                streetLabel={labels.streetLabel}
                zipCodeLabel={labels.zipCodeLabel}
                cityLabel={labels.cityLabel}
                countryCodeLabel={labels.countryCodeLabel}
                emailLabel={labels.emailLabel}
                requiredLabel={labels.requiredLabel}
                submitLabel={labels.submitLabel}
                companyFieldSetLabel={labels.companyFieldSetLabel}
                userFieldSetLabel={labels.userFieldSetLabel}
                errorFieldMessages={errorFieldMessages}
                partnerPortalTerms={terms}
            />
        );
    };

const mfaFormFn = ({ phoneLabel, submitLabel, errorFieldMessages, mfaDescription, onBackButtonClick, backLabel }) =>
    function Form({ onSubmit }) {
        return (
            <RegistrationMfaForm
                onSubmit={onSubmit}
                submitLabel={submitLabel}
                phoneLabel={phoneLabel}
                errorFieldMessages={errorFieldMessages}
                mfaDescription={mfaDescription}
                onPreviousStepButtonClick={onBackButtonClick}
                backLabel={backLabel}
            />
        );
    };

const hideLinkCursorForCurrentAndFutureSteps = (currentStep: number) => () => ({
    [`& > * > *:nth-child(n+${currentStep + 1}) > *:first-child > a`]: {
        cursor: 'default',
    },
});

export default function RegistrationSteps({
    labels,
    partnerPortalTerms,
}: Readonly<{
    labels: RegistrationBlok;
    partnerPortalTerms: TermsResponse;
}>) {
    const [errorFields, setErrorFields] = useState<ErrorFieldMessages>([]);
    const [activeStep, setActiveStep] = useState<RegistrationStepIds>(RegistrationStepIds.REGISTER);
    const [formValues, setFormValues] = useState<ValidateUserRequest>();
    const [goBackToRegistrationForm, setGoBackToRegistrationForm] = useState<boolean>(false);

    const { css } = useFela();
    const backToFirstStep = useCallback(() => {
        setActiveStep(RegistrationStepIds.REGISTER);
        setGoBackToRegistrationForm(true);
    }, []);

    const registrationMfaStep = () => {
        return (
            <SubmitChecker
                post={async (dto: { phone: string }, captchaToken): Promise<void> => {
                    setErrorFields([]);
                    await idmApi.registerUser({
                        userMultiFactorRegistration: {
                            phone: dto.phone,
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            userRegistration: formValues!.userRegistration,
                            recaptchaToken: captchaToken,
                        },
                    });
                }}
                form={mfaFormFn({
                    phoneLabel: labels.phoneLabel,
                    submitLabel: labels.submitLabel,
                    errorFieldMessages: errorFields,
                    mfaDescription: labels.mfaDescription,
                    onBackButtonClick: backToFirstStep,
                    backLabel: labels.backLabel,
                })}
                successComponent={SuccessRedirect}
                errorMessage={labels.errorMessage}
                setErrorFields={setErrorFields}
                recaptchaAction={RecaptchaAction.REGISTRATION_MFA}
            />
        );
    };

    const trim = (validateUserRequest: ValidateUserRequest) => {
        const result: ValidateUserRequest = {
            userRegistration: {
                tenant: validateUserRequest.userRegistration.tenant,
                countryCode: validateUserRequest.userRegistration.countryCode,
                email: validateUserRequest.userRegistration.email.trim(),
                street: validateUserRequest.userRegistration.street.trim(),
                zip: validateUserRequest.userRegistration.zip.trim(),
                category: validateUserRequest.userRegistration.category?.trim(),
                phone: validateUserRequest.userRegistration.phone,
                company: validateUserRequest.userRegistration.company.trim(),
                vendorNumber: validateUserRequest.userRegistration.vendorNumber.trim(),
                firstName: validateUserRequest.userRegistration.firstName.trim(),
                dmContact: validateUserRequest.userRegistration.dmContact.trim(),
                lastName: validateUserRequest.userRegistration.lastName.trim(),
                city: validateUserRequest.userRegistration.city.trim(),
            },
        };
        return result;
    };

    return (
        <Box>
            <div className={css(hideLinkCursorForCurrentAndFutureSteps(activeStep))}>
                <Stepper
                    steps={[{ label: labels.registrationStep1Label }, { label: labels.registrationStep2Label }]}
                    currentStep={activeStep}
                    onClick={(stepStatus, index) => {
                        if (index === RegistrationStepIds.REGISTER && stepStatus === STEP_STATUS.COMPLETE) {
                            backToFirstStep();
                        }
                    }}
                />
            </div>
            <SubmitChecker
                post={async (dto: ValidateUserRequest): Promise<void> => {
                    setErrorFields([]);
                    const trimmedDto = trim(dto);
                    await idmApi
                        .validateUser({
                            userRegistration: {
                                ...trimmedDto.userRegistration,
                            },
                        })
                        .then(() => {
                            setFormValues(trimmedDto);
                            setGoBackToRegistrationForm(false);
                            setActiveStep(RegistrationStepIds.MFA);
                        });
                }}
                form={RegistrationFormFn({
                    formValues,
                    labels,
                    errorFieldMessages: errorFields,
                    terms: partnerPortalTerms,
                })}
                successComponent={registrationMfaStep}
                errorMessage={labels.errorMessage}
                setErrorFields={setErrorFields}
                resetSubmitted={goBackToRegistrationForm}
            />
        </Box>
    );
}
