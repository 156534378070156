import { NotificationProps } from '@dm/design-system/lib/Molecules/Notification/NotificationProps';
import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import Notifications from './Notifications';

export interface NotifyProps {
    message: string;
    kind: NotificationProps['kind'];
}

export interface CustomNotificationProps {
    dmId: string;
    message: string;
    kind: NotificationProps['kind'];
}

export interface NotificationContext {
    notify: (notification: NotifyProps) => void;
    closeNotification: (dmId: string) => void;
}

interface NotificationProviderProps {
    children: ReactNode;
}

const Context = createContext<NotificationContext>({
    notify: () => undefined,
    closeNotification: () => undefined,
});

Context.displayName = 'NotificationContext';

export default function NotificationProvider({ children }: Readonly<NotificationProviderProps>): ReactElement {
    const [notifications, setNotifications] = useState<CustomNotificationProps[]>([]);

    const notify = useCallback(
        (notifyProps: NotifyProps): void => {
            const newNotification: CustomNotificationProps = {
                dmId: Date.now().toString(),
                kind: notifyProps.kind,
                message: notifyProps.message,
            };

            setNotifications([...notifications, newNotification]);
            setTimeout(() => closeNotification(newNotification.dmId), 5000);
        },
        [notifications]
    );

    const closeNotification = (dmId: string): void => {
        setNotifications((old) => old.filter((notification) => notification.dmId !== dmId));
    };

    const notificationContext: NotificationContext = useMemo(
        () => ({
            notify,
            closeNotification,
        }),
        [notify]
    );

    return (
        <Context.Provider value={notificationContext}>
            <Notifications notifications={notifications} />
            {children}
        </Context.Provider>
    );
}

export const useNotification = (): NotificationContext => useContext(Context);
