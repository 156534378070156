import React, { memo, useEffect, useState } from 'react';
import { theme, ThemeProps, useFela } from '@dm/design-system';
import { useOffCanvasStyle } from './offcanvasStyle';
import { useIsOffCanvasVisible } from './OffCanvasContext';

type OffCanvasRootLevelProps = {
    renderCloseButton?: React.ElementType;
    children: React.ReactNode;
    defaultRender?: boolean;
};
const topOffCanvasItemsStyles = {
    minHeight: theme.dimension.spacing.xxxl.rem,
    marginTop: theme.dimension.spacing.xs.rem,
};

function OffCanvasRootLevel({
    renderCloseButton: CloseButton,
    children,
    defaultRender = false,
}: Readonly<OffCanvasRootLevelProps>) {
    const [render, setRender] = useState(defaultRender);
    const { css } = useFela<ThemeProps>();
    const isOffCanvasVisible = useIsOffCanvasVisible();
    const offCanvasStyle = useOffCanvasStyle(() => isOffCanvasVisible);

    useEffect(() => {
        if (isOffCanvasVisible) {
            setRender(true);
        }
    }, [isOffCanvasVisible]);

    const onAnimationEnd = () => {
        if (!isOffCanvasVisible) {
            setRender(false);
        }
    };

    return (
        <>
            {render && (
                <div data-dmid="off-canvas-root-level" className={css(offCanvasStyle)} onAnimationEnd={onAnimationEnd}>
                    <div style={topOffCanvasItemsStyles} />
                    {children}
                    {CloseButton && <CloseButton />}
                </div>
            )}
        </>
    );
}

export default memo<OffCanvasRootLevelProps>(OffCanvasRootLevel);
