import { AngleDownIcon, AngleRightIcon, Box, FelaStyle, ThemeProps, useFela } from '@dm/design-system';

import React, { ReactElement } from 'react';
import defaultLinkStyle from '../../linkStyle';
import Sublinks from './Sublinks';
import { ContentTreeItem } from '../../../gen/ppapiclient';
import TrackedLink from '../tracking/TrackedLink';

const itemStyle = ({ theme }: { theme: ThemeProps }) => ({
    display: 'flex',
    alignItems: 'center',
    flex: '1 0 auto',
    // this selector is necessary to prevent the css of ListItem to overwrite specific marginBottom
    '&:not(:last-child)': {
        marginBottom: theme.dimension.spacing.xs.rem,
    },
    ':hover': {
        display: 'flex',
        transform: 'scale(1.05)',
    },
    marginBottom: theme.dimension.spacing.xs.rem,
});

const currentItemStyle = {
    fontWeight: 900,
};

interface RenderTreeRecursiveProps {
    tree: ContentTreeItem;
    currentSlug: string;
}

function Icon({ isCurrentPage, hasChildren }: Readonly<{ isCurrentPage: boolean; hasChildren: boolean }>) {
    let icon = <></>;
    if (hasChildren) {
        if (isCurrentPage) {
            icon = <AngleDownIcon style={{ height: '0.75em' }} />;
        } else {
            icon = <AngleRightIcon style={{ height: '0.75em' }} />;
        }
    }
    return <div style={{ minWidth: '24px', width: '24px' }}>{icon}</div>;
}

export default function RenderTree({ tree, currentSlug }: Readonly<RenderTreeRecursiveProps>): ReactElement {
    const { css } = useFela<ThemeProps>();
    const isInCurrentTree = currentSlug.includes(tree.fullSlug);
    const isCurrentPage = currentSlug === tree.fullSlug;

    const hasChildren = !!tree.children?.length;

    const style = isCurrentPage
        ? css(combineStyles(defaultLinkStyle, itemStyle), currentItemStyle)
        : css(combineStyles(defaultLinkStyle, itemStyle));
    return (
        <Box key={tree.fullSlug}>
            <Box dmId={tree.fullSlug}>
                <TrackedLink to={`/${tree.fullSlug}`} className={style} trackedType="sideBar">
                    <>
                        <Icon hasChildren={hasChildren} isCurrentPage={isInCurrentTree} />
                        <div>{tree.name}</div>
                    </>
                </TrackedLink>
            </Box>
            <Sublinks tree={tree} currentSlug={currentSlug} />
        </Box>
    );
}

function combineStyles(
    ...styles: ((props: { theme: ThemeProps }) => Record<string, unknown>)[]
): FelaStyle<ThemeProps, Record<string, unknown>> {
    return (theme) => {
        let result = {};
        styles.forEach((style) => {
            result = { ...result, ...style(theme) };
        });
        return result;
    };
}
