/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Labels
 */
export interface Labels {
    /**
     * locale in format de-DE language-country
     * @type {string}
     * @memberof Labels
     */
    locale: string;
    /**
     * translations
     * @type {{ [key: string]: string | undefined; }}
     * @memberof Labels
     */
    translations: { [key: string]: string | undefined; };
}

export function LabelsFromJSON(json: any): Labels {
    return LabelsFromJSONTyped(json, false);
}

export function LabelsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Labels {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locale': json['locale'],
        'translations': json['translations'],
    };
}

export function LabelsToJSON(value?: Labels | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locale': value.locale,
        'translations': value.translations,
    };
}


