import { useEffect, useState } from 'react';
import { configApi } from '../../../apiConfig';

interface RecaptchaConfig {
    siteKey: string;
    enabled: boolean;
}

const disabledRecaptcha: RecaptchaConfig = {
    siteKey: '',
    enabled: false,
};

const useRecaptchaConfig = (): RecaptchaConfig => {
    const [recaptchaConfig, setRecaptchaConfig] = useState<RecaptchaConfig>(disabledRecaptcha);
    useEffect(() => {
        configApi
            .getRecaptchaConfig()
            .then((recaptchaConfigDTO) => {
                setRecaptchaConfig({ siteKey: recaptchaConfigDTO.siteKey, enabled: !!recaptchaConfigDTO.enabled });
            })
            .catch(() => setRecaptchaConfig(disabledRecaptcha));
    }, []);

    return recaptchaConfig;
};

export default useRecaptchaConfig;
