import { ArrowDownIcon } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { Asset } from '../StoryblokFieldTypes';
import TrackedButton from '../../app/components/tracking/TrackedButton';

export interface DownloadProps {
    asset: Asset;
    text: string;
}

const downloadStyle = {
    width: 'max-content',
    margin: '8px',
    flexDirection: 'row-reverse',
    '& div': {
        padding: '0px',
        paddingRight: '8px',
    },
};

const IconFn = () =>
    function Icon(): ReactElement {
        return <ArrowDownIcon />;
    };

export default function Download({ asset, text }: Readonly<DownloadProps>): ReactElement {
    return (
        <TrackedButton
            kind="secondary"
            icon={IconFn()}
            as="a"
            href={asset.filename}
            style={downloadStyle}
            target="_blank"
            trackedType="download"
        >
            {text}
        </TrackedButton>
    );
}
