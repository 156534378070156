import { ThemeProps, useFela } from '@dm/design-system';
import React from 'react';
import UserProfilePageCardHeader from './UserProfilePageCardHeader';
import UserProfilePageCardDescription from './UserProfilePageCardDescription';
import TrackedLink from '../../../app/components/tracking/TrackedLink';
import { UserProfileCardDetails } from '../types';
import MyUserProfileCardContainer from './MyUserProfileCardContainer';
import { getAdfsChangeMfaUrl, getAdfsChangePasswordUrl } from '../../../content/components/idm/routes';

const noUnderliningStyle = { textDecoration: 'none' };

export default function UserProfilePageCard(userProfileCardDetails: Readonly<UserProfileCardDetails>) {
    const { css } = useFela<ThemeProps>();

    const { slug, _uid, title, description, type } = userProfileCardDetails;
    const isExternalLink = ['password', 'mfa'].includes(type);

    let href = `/${slug.cached_url}`;
    if (type === 'password') {
        href = getAdfsChangePasswordUrl();
    } else if (type === 'mfa') {
        href = getAdfsChangeMfaUrl();
    }

    return (
        <TrackedLink
            to={href}
            className={css(noUnderliningStyle)}
            trackedType="userProfil"
            isExternalLink={isExternalLink}
            openInNewTab={isExternalLink}
        >
            <MyUserProfileCardContainer key={_uid}>
                <UserProfilePageCardHeader heading={title} />
                <UserProfilePageCardDescription description={description} />
            </MyUserProfileCardContainer>
        </TrackedLink>
    );
}
