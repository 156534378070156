import React, { createElement, ReactElement } from 'react';
import { Cell, Heading } from '@dm/design-system';
import { useLocation } from 'react-router-dom';
import useStoryData from '../../content/useStoryData';
import { PageProps } from '../../content/components/Page';
import { translateToSlug } from '../../navigation/slug';
import ErrorMessage from '../../content/components/errorPage/ErrorMessage';
import Transitioning from '../../content/components/empty/Transitioning';
import { useTerms } from '../../content/components/termsPage/TermsProvider';
import RichText from '../../content/richtextRenderer/RichText';
import { componentMap, linkMap } from '../../content/richtextRenderer/config';
import RegistrationSteps from './RegistrationSteps';
import { RegistrationBlok } from './types';

const registrationPageStyle = { marginBottom: 20 };

export default function RegistrationPage(): ReactElement {
    const { pathname } = useLocation();
    const { story, error, pending } = useStoryData(translateToSlug(pathname));

    const { terms } = useTerms();
    const partnerPortalTerms = terms.find((termsResponse) => termsResponse.application === 'partnerportal');

    if (pending) {
        return createElement(Transitioning);
    }
    if (error) {
        return createElement(ErrorMessage);
    }

    const registrationPageProps: PageProps = story?.content as PageProps;
    const labels: RegistrationBlok | undefined = registrationPageProps.contentBloks?.[0] as
        | RegistrationBlok
        | undefined;

    if (!labels || !partnerPortalTerms) {
        return createElement(ErrorMessage);
    }

    return (
        <div>
            <Heading kind="primary" style={{ paddingBottom: '20px' }}>
                {registrationPageProps.Title}
            </Heading>
            <Cell style={registrationPageStyle}>
                <RichText richText={registrationPageProps.Description} componentMap={componentMap} linkMap={linkMap} />
            </Cell>

            <RegistrationSteps labels={labels} partnerPortalTerms={partnerPortalTerms} />
        </div>
    );
}
