import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@dm/design-system';
import ErrorPage from './ErrorPage';
import getTenant from '../../getTenant';

export default function AuthenticatedMessage(): ReactElement {
    const { t } = useTranslation();

    const translateOrFallback = (key: string, fallback: string): string => {
        const translation = t(key);
        return translation === key ? fallback : translation;
    };

    const mainPageBtn = (
        <Link kind="secondary" as={Link} href={`/${getTenant().toLocaleLowerCase()}/`}>
            {translateOrFallback('homeButtonLabel', 'Home')}
        </Link>
    );

    return (
        <ErrorPage
            title={translateOrFallback('errorPage.authenticated.title', 'Sorry, the requested page is not active.')}
            description={translateOrFallback(
                'errorPage.authenticated.description',
                'Content could not be loaded. Please try again later or contact support.'
            )}
            additionalButtons={mainPageBtn}
        />
    );
}
