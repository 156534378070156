/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
    UserDetails,
    UserDetailsFromJSON,
    UserDetailsFromJSONTyped,
    UserDetailsToJSON,
} from './';

/**
 * User info, will be null if the user is not logged in
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * User's app roles
     * @type {Array<string>}
     * @memberof UserInfo
     */
    appRoles: Array<string>;
    /**
     * User's email
     * @type {string}
     * @memberof UserInfo
     */
    email: string;
    /**
     * User's full name
     * @type {string}
     * @memberof UserInfo
     */
    fullName: string;
    /**
     * registryDate
     * @type {Date}
     * @memberof UserInfo
     */
    registryDate: Date;
    /**
     * User's roles. USER for a generic Extranet user, additionally contains tenant role like DE_USER
     * @type {Array<Role>}
     * @memberof UserInfo
     */
    roles: Array<Role>;
    /**
     * 
     * @type {Tenant}
     * @memberof UserInfo
     */
    tenant: Tenant;
    /**
     * User hash
     * @type {string}
     * @memberof UserInfo
     */
    userHash: string;
    /**
     * User id
     * @type {string}
     * @memberof UserInfo
     */
    userId: string;
    /**
     * 
     * @type {UserDetails}
     * @memberof UserInfo
     */
    details?: UserDetails;
    /**
     * Users Supplier Number
     * @type {string}
     * @memberof UserInfo
     */
    supplierNumber?: string;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appRoles': json['appRoles'],
        'email': json['email'],
        'fullName': json['fullName'],
        'registryDate': (new Date(json['registryDate'])),
        'roles': ((json['roles'] as Array<any>).map(RoleFromJSON)),
        'tenant': TenantFromJSON(json['tenant']),
        'userHash': json['userHash'],
        'userId': json['userId'],
        'details': !exists(json, 'details') ? undefined : UserDetailsFromJSON(json['details']),
        'supplierNumber': !exists(json, 'supplierNumber') ? undefined : json['supplierNumber'],
    };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appRoles': value.appRoles,
        'email': value.email,
        'fullName': value.fullName,
        'registryDate': (value.registryDate.toISOString()),
        'roles': ((value.roles as Array<any>).map(RoleToJSON)),
        'tenant': TenantToJSON(value.tenant),
        'userHash': value.userHash,
        'userId': value.userId,
        'details': UserDetailsToJSON(value.details),
        'supplierNumber': value.supplierNumber,
    };
}


