import { Box, theme, useBreakpoint } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useContentTree } from '../../../content/StoryProvider';
import { getTopLevelNavigationOfSlug, translateToSlug } from '../../../navigation/slug';
import { ContentTreeItem } from '../../../gen/ppapiclient';
import RenderTree from './RenderTree';

const sideBarStyle = {
    flexDirection: 'column',
};

export default function Sidebar(): ReactElement {
    const { navigation } = useContentTree();
    const { pathname } = useLocation();
    const currentSlug = translateToSlug(pathname);
    const isDesktop = useBreakpoint(theme.breakpoints.horizontal.m.query);

    const secondLevelRoot: ContentTreeItem = getTopLevelNavigationOfSlug(navigation, currentSlug) ?? {
        content: {
            component: '',
            customerroles: '',
            showInNavigation: '',
            visibility: '',
        },
        name: '',
        uuid: '',
        fullSlug: '',
        children: [],
    };

    if (isDesktop && secondLevelRoot.children?.length) {
        return (
            <nav
                style={{
                    width: '15%',
                    overflow: 'visible',
                }}
            >
                <Box style={sideBarStyle}>
                    {secondLevelRoot.children?.map((subTree) => (
                        <RenderTree key={subTree.fullSlug} tree={subTree} currentSlug={currentSlug} />
                    ))}
                </Box>
            </nav>
        );
    }

    return <></>;
}
