import { AngleRightIcon } from '@dm/design-system';
import React, { ReactElement } from 'react';
import TrackedButton from '../../app/components/tracking/TrackedButton';
import { getDwhUrl } from './idm/routes';

export interface DwhLinkProps {
    text: string;
}

const IconFn = () =>
    function Icon(): ReactElement {
        return <AngleRightIcon />;
    };

export default function DwhLink({ text }: Readonly<DwhLinkProps>): ReactElement {
    return (
        <TrackedButton trackedType="dwhLink" kind="secondary" icon={IconFn()} as="a" href={getDwhUrl()}>
            {text}
        </TrackedButton>
    );
}
