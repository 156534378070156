/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Colleague
 */
export interface Colleague {
    /**
     * 
     * @type {string}
     * @memberof Colleague
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Colleague
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Colleague
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Colleague
     */
    phone?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Colleague
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Colleague
     */
    supplierNumber?: string;
}

export function ColleagueFromJSON(json: any): Colleague {
    return ColleagueFromJSONTyped(json, false);
}

export function ColleagueFromJSONTyped(json: any, ignoreDiscriminator: boolean): Colleague {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'supplierNumber': !exists(json, 'supplierNumber') ? undefined : json['supplierNumber'],
    };
}

export function ColleagueToJSON(value?: Colleague | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phone': value.phone,
        'roles': value.roles,
        'supplierNumber': value.supplierNumber,
    };
}


