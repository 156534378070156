import React, { ReactElement } from 'react';
import { RichTextComponentProps } from '../render';

export default function CodeBlock({
    children,
    attrs,
}: Readonly<RichTextComponentProps>): ReactElement<RichTextComponentProps> {
    return (
        <pre>
            <code className={attrs?.class}>{children}</code>
        </pre>
    );
}
