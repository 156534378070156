import { ApplicationStates } from './types';

export function getApplicationType(type: string, subtype: string | undefined): string {
    return !subtype ? type : subtype;
}

export function getOrderState(applicationStates: ApplicationStates, type: string, subtype: string | undefined): string {
    const typeStatus = applicationStates[getApplicationType(type, subtype)]?.orderState ?? 'UNAVAILABLE';
    if (!subtype) {
        return typeStatus;
    }

    const subTypeStatus = applicationStates[subtype]?.orderState ?? 'UNAVAILABLE';
    const isSubtypeNotOrderable = () =>
        Object.keys(applicationStates)
            .filter((key) => key.startsWith(type) && key !== subtype)
            .some(
                (key) =>
                    applicationStates[key].orderState === 'ORDERED' || applicationStates[key].orderState === 'OWNED'
            );

    if (subTypeStatus === 'ORDERABLE' && isSubtypeNotOrderable()) {
        return 'SUBTYPE_NOT_ORDERABLE';
    }

    return subTypeStatus;
}
