import { FelaStyle, Loading, ThemeProps, useFela } from '@dm/design-system';
import React, { ReactElement } from 'react';

const centrallyPositioned: FelaStyle<ThemeProps, Record<string, unknown>> = {
    position: 'relative',
    height: '57vh',
};

export default function Transitioning(): ReactElement {
    const { css } = useFela<ThemeProps>();
    return (
        <div className={css(centrallyPositioned)}>
            <Loading />
        </div>
    );
}
