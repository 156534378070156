/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Supplier,
    SupplierFromJSON,
    SupplierFromJSONTyped,
    SupplierToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserDetails
     */
    entryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserDetails
     */
    exitDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    phone?: string;
    /**
     * 
     * @type {Supplier}
     * @memberof UserDetails
     */
    supplier?: Supplier;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    supplierNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    userId?: string;
}

export function UserDetailsFromJSON(json: any): UserDetails {
    return UserDetailsFromJSONTyped(json, false);
}

export function UserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'entryDate': !exists(json, 'entryDate') ? undefined : (new Date(json['entryDate'])),
        'exitDate': !exists(json, 'exitDate') ? undefined : (new Date(json['exitDate'])),
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'supplier': !exists(json, 'supplier') ? undefined : SupplierFromJSON(json['supplier']),
        'supplierNumber': !exists(json, 'supplierNumber') ? undefined : json['supplierNumber'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function UserDetailsToJSON(value?: UserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'entryDate': value.entryDate === undefined ? undefined : (value.entryDate.toISOString()),
        'exitDate': value.exitDate === undefined ? undefined : (value.exitDate.toISOString()),
        'firstname': value.firstname,
        'lastname': value.lastname,
        'phone': value.phone,
        'supplier': SupplierToJSON(value.supplier),
        'supplierNumber': value.supplierNumber,
        'tenantId': value.tenantId,
        'userId': value.userId,
    };
}


