import React from 'react';
import { theme, useBreakpoint } from '@dm/design-system';
import OffCanvas from './OffCanvas';

export default function OffCanvasMenu(): React.ReactElement | null {
    const isDesktop = useBreakpoint(theme.breakpoints.horizontal.m.query);

    if (isDesktop) {
        return null;
    }

    return (
        <div data-dmid="widget-container-menue-mobile">
            <OffCanvas />
        </div>
    );
}
