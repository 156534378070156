import { Button, FelaStyle, ThemeProps } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { getLoginSlug } from '../../content/components/idm/routes';
import getTenant from '../../content/getTenant';

const loginButtonStyle: FelaStyle<ThemeProps, Record<string, unknown>> = ({ theme }: { theme: ThemeProps }) => ({
    width: 150,
    margin: theme.dimension.spacing.xxxs.rem,
});

export interface LoginProps {
    label: string;
    redirectSlug?: string;
}

export default function Login({ label, redirectSlug }: Readonly<LoginProps>): ReactElement {
    const successSlug = redirectSlug ?? 'login-success';
    return (
        // FIX THAT Success Url to be news page FIX WHEN news page is implemented
        // FIX THAT Failure Url to be new failure page FIX WHEN failure page is implemented
        // FIX THAT dynamically set tenant FIX WHEN we have multiple tenants
        <form action={getLoginSlug()} method="GET">
            <input type="hidden" name="successSlug" value={successSlug} />
            <input type="hidden" name="errorSlug" value={`${getTenant().toLowerCase()}/login-error`} />
            <input type="hidden" name="apiId" value={backendApiId} />
            <Button style={loginButtonStyle} type="submit" title={label}>
                {label}
            </Button>
        </form>
    );
}
