import { Text, ThemeProps, useFela } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import FooterLink from './FooterLink';
import appInfo from '../../gen/AppInfo';
import { useContentTree } from '../../content/StoryProvider';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const footerStyle = ({ theme }: { theme: ThemeProps }) => ({
    backgroundColor: theme.palette.backgroundAlternative.hex,
    fontSize: theme.typography.fontSize.m.rem,
    fontWeight: 'normal',
    lineHeight: theme.typography.lineHeight.m.value,
    padding: `${theme.radius.s.rem} 0px`,
    borderTop: '2px solid rgb(229, 233, 241)',
    marginTop: '13px',
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const footerInnerStyle = ({ theme }: { theme: ThemeProps }) => ({
    margin: '0 auto',
    maxWidth: theme.dimension.contentSize.l.value,
    [theme.breakpoints.horizontal.s.mediaQuery]: {
        paddingLeft: '1.25rem',
        paddingRight: '1.25rem',
    },
});

const footerVersionStyle = ({ theme }: { theme: ThemeProps }) => ({
    fontSize: '0.875rem',
    lineHeight: '1.29',
});

function getCurrentYear(): number {
    return new Date().getFullYear();
}

export default function Footer(): ReactElement {
    const { css } = useFela<ThemeProps>();
    const { t } = useTranslation();

    const { footer } = useContentTree();

    return (
        <div className={css(footerStyle)}>
            <div className={css(footerInnerStyle)}>
                <Text className={css({ border: 'flex', padding: '0px 1.25rem', color: '#525260' })}>
                    {`© ${getCurrentYear()} ${t('dm.name')}`}
                    <br />
                    <span className={css(footerVersionStyle)}>
                        V: {appInfo.commitDateTimeShort} - {appInfo.gitShortHash}
                        <br />
                    </span>
                    <br />
                    <FooterLink items={footer} />
                </Text>
            </div>
        </div>
    );
}
