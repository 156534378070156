/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationResponse
 */
export interface NotificationResponse {
    /**
     * message
     * @type {string}
     * @memberof NotificationResponse
     */
    message: string;
    /**
     * type
     * @type {string}
     * @memberof NotificationResponse
     */
    type: NotificationResponseTypeEnum;
    /**
     * link
     * @type {string}
     * @memberof NotificationResponse
     */
    link?: string;
}

/**
* @export
* @enum {string}
*/
export enum NotificationResponseTypeEnum {
    POSITIVE = 'POSITIVE',
    NEUTRAL = 'NEUTRAL',
    WARNING = 'WARNING',
    ERROR = 'ERROR'
}

export function NotificationResponseFromJSON(json: any): NotificationResponse {
    return NotificationResponseFromJSONTyped(json, false);
}

export function NotificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'type': json['type'],
        'link': !exists(json, 'link') ? undefined : json['link'],
    };
}

export function NotificationResponseToJSON(value?: NotificationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'type': value.type,
        'link': value.link,
    };
}


