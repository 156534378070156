/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * User's roles. USER for a generic Extranet user, additionally contains tenant role like DE_USER
 * @export
 * @enum {string}
 */
export enum Role {
    USER = 'USER',
    ADMIN = 'ADMIN',
    DM_INTERNAL_USER = 'DM_INTERNAL_USER',
    DM_EXTERNAL_USER = 'DM_EXTERNAL_USER',
    DM_IP_USER = 'DM_IP_USER',
    DM_SERVICE_USER = 'DM_SERVICE_USER',
    PREVIEW_USER = 'PREVIEW_USER',
    AT_USER = 'AT_USER',
    BA_USER = 'BA_USER',
    BG_USER = 'BG_USER',
    CZ_USER = 'CZ_USER',
    DE_USER = 'DE_USER',
    HR_USER = 'HR_USER',
    HU_USER = 'HU_USER',
    IT_USER = 'IT_USER',
    RO_USER = 'RO_USER',
    RS_USER = 'RS_USER',
    SI_USER = 'SI_USER',
    SK_USER = 'SK_USER'
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    return json as Role;
}

export function RoleToJSON(value?: Role | null): any {
    return value as any;
}

