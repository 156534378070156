import React, { ReactElement } from 'react';
import { LinkProps } from '../TextNode';
import TrackedLink from '../../../app/components/tracking/TrackedLink';

export function ExternalLink({ href, target, className, text }: Readonly<LinkProps>): ReactElement {
    return (
        <TrackedLink
            to={href}
            isExternalLink
            openInNewTab={target === '_blank'}
            className={className}
            trackedType="externalLink"
        >
            <>{text}</>
        </TrackedLink>
    );
}

export function InternalLink({ href, target, className, text }: Readonly<LinkProps>): ReactElement {
    return (
        <TrackedLink to={href} openInNewTab={target === '_blank'} className={className} trackedType="internalLink">
            <>{text}</>
        </TrackedLink>
    );
}
