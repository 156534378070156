/* eslint-disable no-underscore-dangle */
import { FelaStyle, ThemeProps, useFela } from '@dm/design-system';
import React, { createElement, Fragment, ReactElement } from 'react';
import componentMap from './componentMap';
import { Blok } from './StoryblokFieldTypes';

const containerStyle: FelaStyle<ThemeProps, BlokRendererProps> = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

export interface BlokRendererProps {
    bloks: Blok[];
}

export default function BlokRenderer({ bloks }: Readonly<BlokRendererProps>): ReactElement {
    const { css } = useFela<ThemeProps>();
    return (
        <div className={css(containerStyle)}>
            {bloks.map((props) => {
                const component = componentMap[props.component];
                return component ? (
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    createElement(component, {
                        ...props,
                        key: props._uid,
                    })
                ) : (
                    <Fragment key={props._uid} />
                );
            })}
        </div>
    );
}
