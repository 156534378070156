import { useTranslation } from 'react-i18next';
import React from 'react';
import { UserRegistration } from '../../../gen/ppapiclient';
import TextInput from '../../../content/components/idm/util/fields/TextInput';
import { findErrorMessageByField } from '../utils';
import { ErrorFieldMessages, RegistrationFormField, RegistrationFormLabels, UpdateRegistrationValue } from '../types';

export default function RegistrationInput({
    labels,
    errors,
    values,
    field,
    setDtoItem,
    optional,
    maxLength,
    minLength,
    pattern,
    type,
}: Readonly<{
    labels: RegistrationFormLabels;
    errors: ErrorFieldMessages;
    values: UserRegistration;
    field: RegistrationFormField;
    setDtoItem: UpdateRegistrationValue;
    optional?: boolean;
    maxLength?: number;
    minLength?: number;
    pattern?: RegExp;
    type?: 'email' | 'text' | 'tel';
}>) {
    const { t } = useTranslation();
    const label: string = labels[field];

    return (
        <TextInput
            label={label}
            required={!optional}
            maxLength={maxLength ?? 50}
            minLength={minLength}
            pattern={pattern}
            dmId={field}
            type={type}
            error={findErrorMessageByField(field, errors, t)}
            onChange={(value) => setDtoItem(field, optional && value === '' ? undefined : value)}
            value={values[field]}
            disableTrimming
        />
    );
}
