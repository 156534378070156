/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LoginSuccessResponse,
    LoginSuccessResponseFromJSON,
    LoginSuccessResponseToJSON,
    ValidationErrorResponse,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models';

/**
 * LoginSuccessApi - interface
 * 
 * @export
 * @interface LoginSuccessApiInterface
 */
export interface LoginSuccessApiInterface {
    /**
     * 
     * @summary handle login success
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginSuccessApiInterface
     */
    loginSuccessRaw(): Promise<runtime.ApiResponse<LoginSuccessResponse>>;

    /**
     * handle login success
     */
    loginSuccess(): Promise<LoginSuccessResponse>;

}

/**
 * 
 */
export class LoginSuccessApi extends runtime.BaseAPI implements LoginSuccessApiInterface {

    /**
     * handle login success
     */
    async loginSuccessRaw(): Promise<runtime.ApiResponse<LoginSuccessResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/login-success`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginSuccessResponseFromJSON(jsonValue));
    }

    /**
     * handle login success
     */
    async loginSuccess(): Promise<LoginSuccessResponse> {
        const response = await this.loginSuccessRaw();
        return await response.value();
    }

}
