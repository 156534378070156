import { Text } from '@dm/design-system';
import React from 'react';

type Props = {
    description: string;
};

const cardDescriptionStyle = {
    color: '#525260',
    fontSize: '16px',
};

export default function UserProfilePageCardDescription({ description }: Readonly<Props>) {
    return (
        <Text dmId="userProfilePageCardDescription-id" style={cardDescriptionStyle}>
            {description}
        </Text>
    );
}
