import React, { memo } from 'react';
import { AngleRightIcon, Button, Link, Separator, ThemeProps, useFela, WithTheme } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import { useAddSelectedItemToStack, useHideOffCanvas } from '../offCanvas/OffCanvasContext';
import { ContentTreeItem } from '../../../../gen/ppapiclient';
import TrackedLink from '../../tracking/TrackedLink';

const menuItem = ({ theme }: WithTheme) => ({
    fontSize: theme.typography.fontSize.xl.rem,
    lineHeight: 1,
    outlineOffset: 0,
});

const menuItemLink = ({ theme }: WithTheme) => ({
    fontFamily: theme.typography.fontFamily.primary.main,
    fontWeight: 400,
    textDecoration: 'none',
    color: theme.palette.color2.rgba,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: `${theme.dimension.spacing.m.rem} ${theme.dimension.spacing.xl.rem}`,
    outlineOffset: 0,
    textAlign: 'start',
});

type OffCanvasNavigationItemProps = {
    item: ContentTreeItem;
    customTitle?: string;
};

const separatorStyle = { marginTop: 0, marginBottom: 0 };

function OffCanvasNavigationItem({ item, customTitle }: Readonly<OffCanvasNavigationItemProps>) {
    const { uuid, name, fullSlug, children } = item;
    const { css } = useFela<ThemeProps>();
    const hideOffCanvas = useHideOffCanvas();

    const addSelectedItemToStack = useAddSelectedItemToStack();
    const { t } = useTranslation();

    const clickItem = () => {
        addSelectedItemToStack(item.uuid);
    };
    const clickLink = () => {
        hideOffCanvas();
    };

    const title = customTitle ?? name;

    return children?.length === 0 || customTitle ? (
        <li className={css(menuItem)}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <TrackedLink
                data-menuitemid={uuid}
                className={css(menuItemLink)}
                onClick={clickLink}
                to={fullSlug}
                title={title}
                trackedType="mobileMenu"
            >
                <>{title}</>
            </TrackedLink>
            <Separator style={separatorStyle} />
        </li>
    ) : (
        <li className={css(menuItem)}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/anchor-is-valid */}
            <Link
                as={Button}
                data-menuitemid={uuid}
                style={menuItemLink}
                onClick={clickItem}
                aria-label={t('offCanvas.item.openSubmenu.ariaLabel', { name })}
            >
                {name}
                <AngleRightIcon width={16} height="100%" aria-hidden />
            </Link>
            <Separator style={separatorStyle} />
        </li>
    );
}

export default memo(OffCanvasNavigationItem);
