import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

function getForCurrentPath() {
    let path = window.location.pathname;

    if (!path.endsWith('/success')) {
        if (!path.endsWith('/')) {
            path += '/';
        }

        return `${path}success`;
    }

    return path;
}

export default function SuccessRedirect(): ReactElement {
    return <Navigate replace to={getForCurrentPath()} />;
}
