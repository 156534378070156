import { FelaStyle, ThemeProps, useFela } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { Asset, StoryblokLink } from '../StoryblokFieldTypes';
import TileTitle from './TileTitle';
import TileSubtitle from './TileSubtitle';
import { buildPreviewImageUrl } from '../StoryblokPreviewProvider';
import TrackedLink from '../../app/components/tracking/TrackedLink';
import { getDwhUrl } from './idm/routes';

const tileWidth = 150;
const containerStyle: FelaStyle<ThemeProps, TileProps> = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    textDecoration: 'none',
    width: `${tileWidth}px`,
    height: '170px',
    marginRight: theme.dimension.spacing.s.rem,
    marginBottom: theme.dimension.spacing.xl.rem,
    ':last-of-type': {
        marginRight: '0',
    },
    position: 'relative',
    [theme.breakpoints.horizontal.s.mediaQuery]: {
        width: '160px',
        height: '190px',
    },
    [theme.breakpoints.horizontal.m.mediaQuery]: {
        width: '170px',
        height: '200px',
    },
});

export interface TileProps {
    link: StoryblokLink;
    title?: string;
    subtitle?: string;
    color?: string;
    backgroundImage?: Asset;
    dwhRedirect?: boolean;
}

function createInternalLink(
    link: StoryblokLink,
    // eslint-disable-next-line
        css: any,
    title: string | undefined,
    subtitle: string | undefined
): React.ReactElement {
    const href = `/${link.cached_url}`;
    return (
        <TrackedLink to={href} className={css(containerStyle)} trackedType="tileLink">
            <>
                <TileTitle css={css}>{title ?? <></>}</TileTitle>
                <TileSubtitle css={css}>{subtitle}</TileSubtitle>
            </>
        </TrackedLink>
    );
}

function getExternalUri(link: StoryblokLink): string {
    if (link.linktype === 'email') {
        return `mailto:${link.email}`;
    }
    return link.url;
}

function createExternalLink(
    href: string,
    // eslint-disable-next-line
        css: any,
    title: string | undefined,
    subtitle: string | undefined,
    openInNewTab: boolean
): ReactElement {
    return (
        <TrackedLink
            to={href}
            openInNewTab={openInNewTab}
            isExternalLink
            className={css(containerStyle)}
            trackedType="tileLink"
        >
            <>
                <TileTitle css={css}>{title ?? <></>}</TileTitle>
                <TileSubtitle css={css}>{subtitle}</TileSubtitle>
            </>
        </TrackedLink>
    );
}

export default function Tile({ link, dwhRedirect, title, subtitle, color, backgroundImage }: TileProps): ReactElement {
    const appendSizeParamToImageUrl = (imageFileName: string) => {
        const doubleTileWidthToAvoidBlurryImages = tileWidth * 2;
        const url = buildPreviewImageUrl(imageFileName);
        url.searchParams.append('w', String(doubleTileWidthToAvoidBlurryImages));
        return url.toString();
    };

    const backgroundImageWithOptimizedSize = backgroundImage
        ? {
              ...backgroundImage,
              filename: backgroundImage.filename ? appendSizeParamToImageUrl(backgroundImage.filename) : '',
          }
        : undefined;

    const shortenedSubtitle = (subtitle?.length ?? 0) > 60 ? `${subtitle?.substring(0, 60)}...` : subtitle;

    const { css } = useFela<ThemeProps, TileProps>({
        link,
        color,
        backgroundImage: backgroundImageWithOptimizedSize,
    });

    if (dwhRedirect) {
        return createExternalLink(getDwhUrl(), css, title, shortenedSubtitle, false);
    }

    if (link.linktype !== 'story') {
        const href = getExternalUri(link);
        return createExternalLink(href, css, title, shortenedSubtitle, true);
    }

    return createInternalLink(link, css, title, shortenedSubtitle);
}
