import React, { ChangeEvent, ReactElement, useRef, useState } from 'react';
import { Box, Button, SearchForm, SearchIcon, ThemeProps, WithTheme, useFela } from '@dm/design-system';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getTenant from '../../content/getTenant';

const searchFormStyles = ({ theme }: WithTheme) => ({
    display: 'none',
    [theme.breakpoints.horizontal.xs.mediaQuery]: {
        display: 'block',
    },
});

const searchIconStyles = ({ theme }: WithTheme) => ({
    backgroundColor: 'transparent',
    border: 'none',

    [theme.breakpoints.horizontal.xs.mediaQuery]: {
        display: 'none',
    },
});

export default function SearchBox(): ReactElement {
    const { css } = useFela<ThemeProps>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const searchFormInputRef = useRef<HTMLInputElement>(null);
    const [searchInputValue, setSearchInputValue] = useState('');
    const tenant = getTenant().toLowerCase();

    const handleClear = () => {
        setSearchInputValue('');
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target?.value?.length > 0) {
            const queryValue = event.target.value;
            setSearchInputValue(queryValue);
        } else {
            setSearchInputValue('');
        }
    };

    const handleSubmit = (): void => {
        if (!searchInputValue) {
            return;
        }
        const queryString = new URLSearchParams({ query: searchInputValue }).toString();
        navigate(`${tenant}/search?${queryString}`);
    };

    const searchLabel = t('search.header.hint');

    const handleIconClick = () => {
        navigate(`${tenant}/search`);
    };

    return (
        <Box
            class={css({
                marginRight: 20,
            })}
        >
            <SearchForm
                dmId="icon-search-box"
                inputRef={searchFormInputRef}
                // @ts-expect-error onchange expect form event, but here we get a change event
                onChange={handleChange}
                onSubmit={handleSubmit}
                onClear={handleClear}
                onClick={handleSubmit}
                placeholder={searchLabel}
                value={searchInputValue}
                styles={{ form: searchFormStyles }}
            />
            <Button onClick={handleIconClick} type="button" data-dmid="search-goto" style={searchIconStyles}>
                <SearchIcon />
            </Button>
        </Box>
    );
}
