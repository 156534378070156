import { Form } from '@dm/design-system';
import React, { CSSProperties, FormEventHandler, ReactElement, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ConfirmUserRequest, UserConfirmation } from '../../../../gen/ppapiclient';
import getTenant from '../../../getTenant';
import PasswordInputs, { NewPasswordInputsProps } from '../util/fields/PasswordInputs';
import { SubmitterProps } from '../util/SubmitChecker';

const rowStyle: CSSProperties = {
    flexDirection: 'row',
    width: '100%',
};

export interface ConfirmUserFormProps
    extends SubmitterProps<ConfirmUserRequest>,
        Omit<NewPasswordInputsProps, 'onChange'> {
    submitLabel: string;
}

export default function ConfirmUserForm({
    onSubmit,
    newPasswordLabel,
    repeatPasswordLabel,
    submitLabel,
    validationErrorMessage,
}: Readonly<ConfirmUserFormProps>): ReactElement {
    const { search } = useLocation();

    const queryParams = new URLSearchParams(search);
    const userConfirmation = useRef<UserConfirmation>({
        tenant: getTenant(),
        email: queryParams.get('email') ?? '',
        newPassword: '',
        token: queryParams.get('token') ?? '',
    });

    const submit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        onSubmit({ userConfirmation: userConfirmation.current });
    };

    return (
        <Form name="password-reset-form" method="post" onSubmit={submit} style={rowStyle}>
            <PasswordInputs
                onChange={(value: string): void => {
                    userConfirmation.current.newPassword = value;
                }}
                repeatPasswordLabel={repeatPasswordLabel}
                newPasswordLabel={newPasswordLabel}
                validationErrorMessage={validationErrorMessage}
                submitLabel={submitLabel}
            />
        </Form>
    );
}
