import { theme, ThemeProps, useFela } from '@dm/design-system';
import * as React from 'react';

const flyoutElementStyle = {
    borderBottom: `1px solid ${theme.palette.backgroundAlternative.hex}`,
    width: '100%',
    // change styling of children so links work on the whole width of an element without having to set it on each and every element
    '> *': {
        padding: theme.dimension.spacing.xs.rem,
        position: 'relative',
        boxSizing: 'border-box',
        width: '100%',
        // allow elements to be non-interactive by setting the css class "noninteractive"
        '&:not(.noninteractive):hover': {
            transform: 'scale(1.05)',
            fontWeight: 'bold',
        },
    },
};

type Label = string | JSX.Element;

export interface FlyoutLinkProps {
    content: Label;
    dmId?: string;
}

export default function FlyoutElement({ content, dmId }: Readonly<FlyoutLinkProps>): JSX.Element {
    const { css } = useFela<ThemeProps>();

    return (
        <div className={css(flyoutElementStyle)} data-dmid={dmId}>
            {content}
        </div>
    );
}
