import { Button } from '@dm/design-system';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationsContext } from '../Applications';

type Props = {
    applicationType: string;
};

export default function ApplicationExpandButton({ applicationType }: Readonly<Props>) {
    const { t } = useTranslation();
    const { expandApplication } = useContext(ApplicationsContext);

    return (
        <Button
            onClick={() => expandApplication(applicationType)}
            dmId={`application-${applicationType}-expand-button`}
        >
            {t('application.expandButton')}
        </Button>
    );
}
