import { Banner, Box } from '@dm/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TermsResponse } from '../../../gen/ppapiclient';
import { ApplicationStatus } from '../types';

type Props = {
    terms?: TermsResponse;
    type: string;
    status: ApplicationStatus;
};

export default function ApplicationTerms({ terms, type, status }: Readonly<Props>) {
    const { t } = useTranslation();

    return (
        <Box>
            {terms ? (
                <Banner dmId={`${type}-status`} kind={status.bannerKind}>
                    {t(status.bannerText)}
                </Banner>
            ) : (
                <Banner dmId={`${type}-status`} kind="error">
                    {t('application.error.noTermsFound')}
                </Banner>
            )}
        </Box>
    );
}
