import { Box, Button, FelaStyle, Link, Text, ThemeProps } from '@dm/design-system';
import React, { ReactElement } from 'react';
import Login from '../../../session/components/Login';
import { useSession } from '../../../session/SessionProvider';
import { getRegisterSlug } from '../idm/routes';
import getTenant from '../../getTenant';

const buttonStyle: FelaStyle<ThemeProps, Record<string, unknown>> = ({ theme }: { theme: ThemeProps }) => ({
    minWidth: 150,
    margin: theme.dimension.spacing.xxxs.rem,
});

const buttonLinkStyle: FelaStyle<ThemeProps, Record<string, unknown>> = ({ theme }: { theme: ThemeProps }) => ({
    minWidth: 150,
    margin: theme.dimension.spacing.xxxs.rem,
    textDecoration: 'underline',
    paddingLeft: 0,
});

const buttonRowStyle = {
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
};

export interface WelcomePageButtonsProps {
    notLoggedInText: string;
    loginButtonLabel: string;
    registerButtonLabel: string;
    resetPasswortLabel: string;
}

export default function WelcomePageButtons({
    notLoggedInText,
    loginButtonLabel,
    registerButtonLabel,
    resetPasswortLabel,
}: Readonly<WelcomePageButtonsProps>): ReactElement {
    const { authentication } = useSession();
    if (authentication === 'success') {
        return <></>;
    }
    return (
        <div style={{ display: 'block' }}>
            <Text style={{ marginBottom: '10px', color: 'black' }}>{notLoggedInText}</Text>
            <Box style={buttonRowStyle}>
                <Button
                    kind="secondary"
                    as={Link}
                    href={`/${getRegisterSlug()}`}
                    style={buttonStyle}
                    title={registerButtonLabel}
                >
                    {registerButtonLabel}
                </Button>
                <Login label={loginButtonLabel} />
            </Box>
            <Box style={buttonRowStyle}>
                <Button
                    kind="invisible"
                    as={Link}
                    href={`/${getTenant().toLocaleLowerCase()}/org/user_management/forgot_password/`}
                    style={buttonLinkStyle}
                    title={resetPasswortLabel ?? 'missing: resetPasswordLabel'}
                >
                    {resetPasswortLabel ?? 'missing: resetPasswordLabel'}
                </Button>
            </Box>
        </div>
    );
}
