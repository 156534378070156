import {
    ApplicationsApi,
    AuthenticationApi,
    ConfigApi,
    Configuration,
    ContentApi,
    IdmApi,
    LoginSuccessApi,
    NewsletterApi,
    NotificationsApi,
    TermsofuseApi,
    UserProfileApi,
} from '../gen/ppapiclient';
import csrfMiddleware from './csrfMiddleware';
import maintenanceMiddleware from './maintenanceMiddleware';
import previewMiddleware from './previewMiddleware';

const configuration = new Configuration({
    basePath: `${backendUrlWithPathPrefix}`,
    credentials: 'include',
});

export const authenticationApi = new AuthenticationApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const idmApi = new IdmApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const newsletterApi = new NewsletterApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const applicationApi = new ApplicationsApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const termsApi = new TermsofuseApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const contentApi = new ContentApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const configApi = new ConfigApi(configuration);

export const notificationsApi = new NotificationsApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const userProfileApi = new UserProfileApi(configuration)
    .withPreMiddleware(csrfMiddleware)
    .withPreMiddleware(maintenanceMiddleware)
    .withPreMiddleware(previewMiddleware);

export const loginSuccessApi = new LoginSuccessApi(configuration).withPreMiddleware(csrfMiddleware);
