import React, { ReactElement, useEffect, useState } from 'react';
import { Banner, Box, Spacer, ThemeProps, useFela, View } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import TrackedLink from '../tracking/TrackedLink';
import { NotificationResponse } from '../../../gen/ppapiclient';
import getTenant from '../../../content/getTenant';
import { notificationsApi } from '../../../apiConfig';

export default function ServiceNotifications(): ReactElement {
    const { theme } = useFela<ThemeProps>();
    const [notifications, setNotifications] = useState<NotificationResponse[]>([]);
    const { t } = useTranslation();

    const tenant = getTenant();

    useEffect(() => {
        notificationsApi
            .getNotifications({
                tenant,
            })
            .then(setNotifications);
    }, [tenant]);

    const bannerElement = (notification: NotificationResponse, sizeOfNotifications: number) => {
        const kind = notification.type.toLowerCase() as 'positive' | 'neutral' | 'warning' | 'error';

        return (
            <Box key={kind}>
                {sizeOfNotifications >= 2 && <Spacer size={theme.dimension.spacing.xxs.rem} />}
                <Banner kind={kind}>
                    {notification.message}
                    {notification.link && (
                        <TrackedLink
                            to={notification.link}
                            title={t('clickToMoreInformation')}
                            trackedType="serviceNotifications"
                        >
                            <>{t('clickToMoreInformation')}</>
                        </TrackedLink>
                    )}
                </Banner>
            </Box>
        );
    };

    return <View>{notifications.map((item) => bannerElement(item, notifications.length))}</View>;
}
