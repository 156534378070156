import React, { useCallback } from 'react';
import { MenuIcon, ThemeProps, useFela } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type OffCanvasMenuButtonProps = {
    showOffCanvas: () => void;
};

const link = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
});

function OffCanvasMenuButton({ showOffCanvas }: Readonly<OffCanvasMenuButtonProps>) {
    const { css } = useFela<ThemeProps>();
    const { t } = useTranslation();
    const clickHandler = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            showOffCanvas();
        },
        [showOffCanvas]
    );
    return (
        <Link to="#offCanvas" title={t('offCanvas.link.name')} onClick={clickHandler} className={css(link)}>
            <MenuIcon aria-label={t('offCanvas.link.name')} />
        </Link>
    );
}

export default OffCanvasMenuButton;
