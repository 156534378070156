import { FelaStyle, Tab, TabList, Tabs, ThemeProps, useFela } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { getTopLevelNavigationOfSlug, translateToSlug } from '../../../navigation/slug';
import TrackedLink from '../tracking/TrackedLink';
import { useContentTree } from '../../../content/StoryProvider';

const tabStyle: FelaStyle<ThemeProps, Record<string, unknown>> = {
    ':hover': {
        transform: 'scale(1.03)',
    },
};

const linkStyle: FelaStyle<ThemeProps, Record<string, unknown>> = {
    minWidth: '10%',
    display: 'contents',

    '> div > div > button': {
        outline: 'none !important',
        width: 'auto',
        display: 'contents',
    },
    '> div > div > button:active': {
        outline: 'none !important',
    },
    '> div > div > button > span': {
        width: '100%',
        display: 'inline-block',
    },
    '> div > div > button > span > span': {
        width: '100%',
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

export default function HeaderTabList(): ReactElement {
    const { navigation } = useContentTree();
    const firstLevelLinks = navigation;
    const { css } = useFela<ThemeProps>();

    const { pathname } = useLocation();
    const currentTopLevelNavigation = getTopLevelNavigationOfSlug(navigation, translateToSlug(pathname));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const firstLevelLinkList: Array<any> = Array.of(
        firstLevelLinks.map(
            ({ name, fullSlug }): ReactElement => (
                <TrackedLink
                    to={`/${fullSlug}`}
                    key={fullSlug}
                    className={css(linkStyle)}
                    tabIndex={-1}
                    trackedType="navigation"
                    title={name}
                >
                    <Tab type="Tab" key={fullSlug} tabId={fullSlug}>
                        <span className={css(tabStyle)}>{name}</span>
                    </Tab>
                </TrackedLink>
            )
        )
    );

    const activeTabId = currentTopLevelNavigation?.fullSlug ?? '';
    return navigation.length > 0 ? (
        <Tabs activeTabId={activeTabId}>
            {[
                <TabList key="TabList" type="TabList">
                    {firstLevelLinkList}
                </TabList>,
            ]}
        </Tabs>
    ) : (
        <></>
    );
}
