import { Box, InputField, Style, WithTheme } from '@dm/design-system';
import React, { ChangeEventHandler, ReactElement, useRef, useState } from 'react';
import useValidation from './useValidation';

export interface Invalid {
    invalid: boolean;
    errorMsg: string;
}

export interface ValidatedInputFieldProps {
    label: string;
    type?: 'text' | 'email' | 'password' | 'tel';
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    pattern?: RegExp;
    dmId: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    checkValidity?: boolean;
    onCheckValidity?: () => void;
    customErrorMessage?: string;
    value?: string;
}

const boxStyle: Style = ({ theme }: WithTheme) => ({
    marginBottom: 10,
    justifyContent: 'start',
    [theme.breakpoints.horizontal.s.mediaQuery]: {
        flex: 1,
    },
});

export default function ValidatedInputField({
    label,
    maxLength,
    minLength,
    required,
    type,
    onChange,
    pattern,
    dmId,
    checkValidity,
    onCheckValidity,
    customErrorMessage = '',
    value,
}: Readonly<ValidatedInputFieldProps>): ReactElement {
    const [{ invalid, errorMsg }, setInvalid] = useState<Invalid>({
        invalid: false,
        errorMsg: '',
    });
    const ref = useRef<HTMLInputElement>();
    const {
        invalidHandler,
        // invalid,
        // errorMsg,
        onChangeInput,
        onBlurInput,
    } = useValidation({
        ref,
        required,
        checkValidity,
        onCheckValidity,
        onChange,
        customErrorMessage,
        setInvalid,
    });

    return (
        <Box style={boxStyle}>
            <InputField
                style={{ flexGrow: 1 }}
                label={label}
                type={type}
                dmId={dmId}
                invalid={invalid}
                pattern={pattern?.toString().slice(1, -1)}
                required={required}
                error={errorMsg}
                maxLength={maxLength}
                minLength={minLength}
                onChange={onChangeInput}
                onBlur={onBlurInput}
                onInvalid={invalidHandler}
                // InputField is still typed for legacy ref api
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                reference={ref}
                value={value}
            />
        </Box>
    );
}
