/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRegistration,
    UserRegistrationFromJSON,
    UserRegistrationFromJSONTyped,
    UserRegistrationToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMultiFactorRegistration
 */
export interface UserMultiFactorRegistration {
    /**
     * 
     * @type {UserRegistration}
     * @memberof UserMultiFactorRegistration
     */
    userRegistration: UserRegistration;
    /**
     * phone number, must contain a valid phone number
     * @type {string}
     * @memberof UserMultiFactorRegistration
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMultiFactorRegistration
     */
    recaptchaToken?: string;
}

export function UserMultiFactorRegistrationFromJSON(json: any): UserMultiFactorRegistration {
    return UserMultiFactorRegistrationFromJSONTyped(json, false);
}

export function UserMultiFactorRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMultiFactorRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userRegistration': UserRegistrationFromJSON(json['userRegistration']),
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'recaptchaToken': !exists(json, 'recaptchaToken') ? undefined : json['recaptchaToken'],
    };
}

export function UserMultiFactorRegistrationToJSON(value?: UserMultiFactorRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userRegistration': UserRegistrationToJSON(value.userRegistration),
        'phone': value.phone,
        'recaptchaToken': value.recaptchaToken,
    };
}


