import { Box, Button, Form, Spacer, Text, ThemeProps, useFela } from '@dm/design-system';
import React, { ChangeEvent, CSSProperties, FormEventHandler, ReactElement, useRef } from 'react';
import { RequestPasswordResetRequest } from '../../../../gen/ppapiclient';
import getTenant from '../../../getTenant';
import { SubmitterProps } from '../util/SubmitChecker';
import ValidatedInputField from '../util/fields/ValidatedInputField';

export interface PasswordForgottenFormProps extends SubmitterProps<RequestPasswordResetRequest> {
    emailLabel: string;
    requiredLabel: string;
    submitLabel: string;
}

const rowStyle: CSSProperties = {
    flexDirection: 'row',
};

export default function PasswordForgottenForm({
    onSubmit,
    emailLabel,
    requiredLabel,
    submitLabel,
}: Readonly<PasswordForgottenFormProps>): ReactElement {
    const { theme } = useFela<ThemeProps>();

    const email = useRef('');
    const submit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        onSubmit({
            passwordResetRequest: {
                tenant: getTenant(),
                email: email.current,
            },
        });
    };

    return (
        <Box style={{ display: 'block' }}>
            <Form name="password-reset-request-form" method="post" onSubmit={submit} style={rowStyle}>
                <Box>
                    <Box>
                        <ValidatedInputField
                            required
                            maxLength={50}
                            label={emailLabel}
                            dmId="email"
                            type="email"
                            onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                                email.current = event.target.value;
                            }}
                        />
                    </Box>
                    <Text>* {requiredLabel}</Text>
                    <Spacer size={theme.dimension.spacing.m.rem} />
                    <Box>
                        <Button type="submit">{submitLabel}</Button>
                    </Box>
                </Box>
            </Form>
        </Box>
    );
}
