import React, { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import getDateNow from '../../../DateUtil';

const initialTracking = {
    getTimeOnPageInSec: () => {
        return 0;
    },
};
type TrackingContextType = {
    getTimeOnPageInSec: () => number;
};

const TrackingContext = createContext<TrackingContextType>(initialTracking);

export function TrackingProvider({ children }): ReactElement {
    const [dateStarted, setDateStarted] = useState<number | undefined>();
    const location = useLocation();

    const getTimeOnPageInSec = useCallback(() => {
        if (dateStarted === undefined) {
            return 0;
        }

        const now = getDateNow();

        return (now - dateStarted) / 1000;
    }, [dateStarted]);

    useEffect(() => {
        setDateStarted(getDateNow());
    }, [location]);

    const trackingContext = useMemo(
        () => ({
            getTimeOnPageInSec,
        }),
        [getTimeOnPageInSec]
    );

    return <TrackingContext.Provider value={trackingContext}>{children}</TrackingContext.Provider>;
}

export const useTrackingContext = (): TrackingContextType => useContext(TrackingContext);
