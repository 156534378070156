import { theme, ThemeProps, useFela } from '@dm/design-system';
import * as React from 'react';
import {
    CSSProperties,
    FocusEventHandler,
    KeyboardEventHandler,
    ReactNode,
    useCallback,
    useRef,
    useState,
} from 'react';
import Flyout from './Flyout';

const containerStyle: CSSProperties = {
    height: '24px', // for having correct focus outline. 24px should be size of icon
};

const buttonStyle: CSSProperties = {
    padding: 0,
    border: 'none',
    height: '24px', // for having correct focus outline. 24px should be size of icon
    backgroundColor: theme.palette.background.hex,
    cursor: 'pointer',
    marginRight: '0.75rem',
};

const buttonFocusStyle = {
    outlineColor: theme.palette.color2.hex,
    outlineWidth: 'initial',
    outlineStyle: 'auto',
};

export interface WidgetWithFlyoutProps {
    icon: ReactNode;
    label: string;
    elements: string[] | JSX.Element[];
    dmId: string;
}

export default function FlyoutWidget({
    icon: Icon,
    label,
    elements,
    dmId,
}: Readonly<WidgetWithFlyoutProps>): JSX.Element | null {
    const { css } = useFela<ThemeProps>();

    const [open, setOpen] = useState(false);
    const handleClick = useCallback(() => setOpen((oldOpen) => !oldOpen), []);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const closeFlyout = () => setOpen(false);
    const handleEscapeKey: KeyboardEventHandler = ({ key }) => {
        if (key === 'Escape') {
            closeFlyout();
            buttonRef.current?.focus();
        }
    };

    const handleTabKey: KeyboardEventHandler = ({ key }) => {
        if (key === 'Tab') {
            handleClick();
        }
    };

    const containerRef = useRef<HTMLDivElement>(null);
    const handleBlur: FocusEventHandler = ({ relatedTarget }) => {
        if (!containerRef.current?.contains(relatedTarget)) {
            closeFlyout();
        }
    };

    return (
        // onKeyDown on container div enables escape to close dropdown menu
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={css(containerStyle)} onKeyDown={handleEscapeKey} onBlur={handleBlur} ref={containerRef}>
            {/* design system button includes styling not suited for widget spot */}
            <button
                data-dmid={dmId}
                ref={buttonRef}
                aria-label={label}
                aria-expanded={open}
                type="button"
                onClick={handleClick}
                onKeyUp={handleTabKey}
                className={open ? css(buttonStyle, buttonFocusStyle) : css(buttonStyle)}
            >
                {Icon}
            </button>
            <Flyout isOpen={open} elements={elements} onClick={closeFlyout} />
        </div>
    );
}
