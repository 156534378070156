/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Consent
 */
export interface Consent {
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    termsName: string;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    termsVersion: string;
    /**
     * 
     * @type {boolean}
     * @memberof Consent
     */
    accepted?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Consent
     */
    createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof Consent
     */
    id?: number;
}

export function ConsentFromJSON(json: any): Consent {
    return ConsentFromJSONTyped(json, false);
}

export function ConsentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Consent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'termsName': json['termsName'],
        'termsVersion': json['termsVersion'],
        'accepted': !exists(json, 'accepted') ? undefined : json['accepted'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ConsentToJSON(value?: Consent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'termsName': value.termsName,
        'termsVersion': value.termsVersion,
        'accepted': value.accepted,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'id': value.id,
    };
}


