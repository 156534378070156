import { Heading } from '@dm/design-system';
import React, { ReactElement } from 'react';
import BlokRenderer from '../BlokRenderer';
import { componentMap, linkMap } from '../richtextRenderer/config';
import { RichTextNode } from '../richtextRenderer/render';
import RichText from '../richtextRenderer/RichText';
import { Blok } from '../StoryblokFieldTypes';

export interface PageProps {
    Title: string;
    Description: RichTextNode;
    contentBloks?: Blok[];
}

export default function Page({ Description, Title, contentBloks }: Readonly<PageProps>): ReactElement {
    return (
        <div>
            <Heading kind="primary" style={{ paddingBottom: '11px', wordWrap: 'break-word' }}>
                {Title}
            </Heading>
            <RichText componentMap={componentMap} linkMap={linkMap} richText={Description} />
            {contentBloks && <BlokRenderer bloks={contentBloks} />}
        </div>
    );
}
