import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Loading } from '@dm/design-system';
import { datadogRum } from '@datadog/browser-rum';
import { useNotification } from '../../../../notification/NotificationProvider';
import Recaptcha from '../../../../app/components/captcha/Recaptcha';
import { RecaptchaAction } from '../../../../gen/ppapiclient';
import getTenant from '../../../getTenant';
import useRecaptchaConfig from '../../../../app/components/captcha/useRecaptchaConfig';

export interface SubmitterProps<T> {
    onSubmit: (dto: T) => void;
}

export interface SubmitCheckerUserProps {
    errorMessage: string;
}

export interface ErrorConfig {
    [errorName: string]: () => void;
}

interface BackendError {
    error: string;
    errorFields: { [fieldName: string]: string };
    id: string;
}

interface ErrorField {
    field: string;
    message: string;
}

export interface SubmitCheckerProps<T> extends SubmitCheckerUserProps {
    post: (dto: T, captchaToken: string) => Promise<void>;
    form: (props: SubmitterProps<T>) => ReactElement;
    successComponent: () => ReactElement;
    errorConfig?: ErrorConfig;
    setErrorFields?: (errorFields: ErrorField[]) => void;
    recaptchaAction?: RecaptchaAction;
    resetSubmitted?: boolean;
}

export default function SubmitChecker<T>({
    post,
    form,
    successComponent,
    errorMessage,
    errorConfig = {},
    setErrorFields,
    recaptchaAction,
    resetSubmitted,
}: Readonly<SubmitCheckerProps<T>>): ReactElement {
    const { enabled: captchaEnabled } = useRecaptchaConfig();
    const { notify } = useNotification();
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submittedFormDto, setSubmittedFormDto] = useState<T | undefined>(undefined);

    useEffect(() => {
        if (resetSubmitted) {
            setSubmitted(false);
        }
    }, [resetSubmitted]);

    const handleBackendError = useCallback(
        (e: Response) => {
            const showErrorNotification = () => {
                notify({ kind: 'error', message: errorMessage });
            };
            const handleSpecificError = (error: string) => {
                const errorSpecificHandler = errorConfig[error];
                if (errorSpecificHandler) {
                    errorSpecificHandler();
                }
            };
            const handleErrorFields = (backendErrorFields: { [p: string]: string }) => {
                const errorFields: ErrorField[] = [];
                Object.entries(backendErrorFields).forEach(([field, message]) => {
                    errorFields.push({ field, message });
                });
                if (setErrorFields && errorFields.length > 0) {
                    setErrorFields(errorFields);
                }
            };

            if (!e.json) {
                showErrorNotification();
                return;
            }

            e.json()
                .then((errorJson) => {
                    if (!errorJson.error || !errorJson.errorFields) {
                        return;
                    }
                    const { error, errorFields: backendErrorFields } = errorJson as BackendError;
                    handleSpecificError(error);
                    handleErrorFields(backendErrorFields);
                })
                .finally(showErrorNotification);
        },
        [errorConfig, setErrorFields, errorMessage, notify]
    );
    const postToBackend: (dto: T, captchaToken?: string) => void = useCallback(
        (dto, captchaToken) => {
            setLoading(true);

            post(dto, captchaToken ?? '')
                .then(() => setSubmitted(true))
                .catch(handleBackendError)
                .finally(() => setLoading(false));
        },
        [handleBackendError, post]
    );

    const onSubmit: (dto: T) => void = useCallback(
        (dto) => {
            setSubmitted(false);
            if (
                recaptchaAction &&
                captchaEnabled &&
                window.grecaptcha?.enterprise &&
                // eslint-disable-next-line no-underscore-dangle
                window._DATADOG_SYNTHETICS_BROWSER === undefined
            ) {
                setSubmittedFormDto(dto);
                window.grecaptcha.enterprise.ready(() => {
                    datadogRum.addAction('showCaptcha', {
                        action: recaptchaAction,
                        tenant: getTenant(),
                    });
                    window.grecaptcha.enterprise.execute();
                });
            } else {
                postToBackend(dto);
            }
        },
        [captchaEnabled, recaptchaAction, postToBackend]
    );

    const recaptchaCallback = useCallback(
        (token) => {
            datadogRum.addAction('captchaSolved', {
                action: recaptchaAction,
                tenant: getTenant(),
            });
            if (submittedFormDto && !submitted) {
                postToBackend(submittedFormDto, token);
            }
            window.grecaptcha?.enterprise.reset();
        },
        [recaptchaAction, postToBackend, submitted, submittedFormDto]
    );

    return (
        <>
            {loading && <Loading absolute />}
            {submitted ? (
                <> {successComponent()}</>
            ) : (
                <>
                    {form({ onSubmit })}
                    {captchaEnabled && recaptchaAction && (
                        <Recaptcha submitCallback={recaptchaCallback} action={recaptchaAction} />
                    )}
                </>
            )}
        </>
    );
}
