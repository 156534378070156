/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountConfirmation,
    AccountConfirmationFromJSON,
    AccountConfirmationToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    PasswordReset,
    PasswordResetFromJSON,
    PasswordResetToJSON,
    PasswordResetError,
    PasswordResetErrorFromJSON,
    PasswordResetErrorToJSON,
    PasswordResetRequest,
    PasswordResetRequestFromJSON,
    PasswordResetRequestToJSON,
    UserConfirmation,
    UserConfirmationFromJSON,
    UserConfirmationToJSON,
    UserMultiFactorRegistration,
    UserMultiFactorRegistrationFromJSON,
    UserMultiFactorRegistrationToJSON,
    UserRegistration,
    UserRegistrationFromJSON,
    UserRegistrationToJSON,
    ValidationErrorResponse,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models';

export interface ConfirmAccountRequest {
    accountConfirmation: AccountConfirmation;
}

export interface ConfirmUserRequest {
    userConfirmation: UserConfirmation;
}

export interface RegisterUserRequest {
    userMultiFactorRegistration: UserMultiFactorRegistration;
}

export interface RequestPasswordResetRequest {
    passwordResetRequest: PasswordResetRequest;
}

export interface ResetPasswordRequest {
    passwordReset: PasswordReset;
}

export interface ValidateUserRequest {
    userRegistration: UserRegistration;
}

/**
 * IdmApi - interface
 * 
 * @export
 * @interface IdmApiInterface
 */
export interface IdmApiInterface {
    /**
     * Can be used after 6 months when receiving a token via e-mail.
     * @summary confirm account to make sure it is still in use
     * @param {AccountConfirmation} accountConfirmation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdmApiInterface
     */
    confirmAccountRaw(requestParameters: ConfirmAccountRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Can be used after 6 months when receiving a token via e-mail.
     * confirm account to make sure it is still in use
     */
    confirmAccount(requestParameters: ConfirmAccountRequest): Promise<void>;

    /**
     * Can be used after receiving a token for a new user via e-mail.
     * @summary confirm user registration by setting a password
     * @param {UserConfirmation} userConfirmation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdmApiInterface
     */
    confirmUserRaw(requestParameters: ConfirmUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Can be used after receiving a token for a new user via e-mail.
     * confirm user registration by setting a password
     */
    confirmUser(requestParameters: ConfirmUserRequest): Promise<void>;

    /**
     * Will cause a mail to be sent to responsible persons.
     * @summary request registration of a new user
     * @param {UserMultiFactorRegistration} userMultiFactorRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdmApiInterface
     */
    registerUserRaw(requestParameters: RegisterUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Will cause a mail to be sent to responsible persons.
     * request registration of a new user
     */
    registerUser(requestParameters: RegisterUserRequest): Promise<void>;

    /**
     * Will cause a mail to be sent to responsible persons.
     * @summary request a password reset because a password has been forgotten
     * @param {PasswordResetRequest} passwordResetRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdmApiInterface
     */
    requestPasswordResetRaw(requestParameters: RequestPasswordResetRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Will cause a mail to be sent to responsible persons.
     * request a password reset because a password has been forgotten
     */
    requestPasswordReset(requestParameters: RequestPasswordResetRequest): Promise<void>;

    /**
     * Can be used after sending a reset request and having received an e-mail with reset information.
     * @summary reset a forgotten password with a token sent via mail
     * @param {PasswordReset} passwordReset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdmApiInterface
     */
    resetPasswordRaw(requestParameters: ResetPasswordRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Can be used after sending a reset request and having received an e-mail with reset information.
     * reset a forgotten password with a token sent via mail
     */
    resetPassword(requestParameters: ResetPasswordRequest): Promise<void>;

    /**
     * Will cause a mail to be sent to responsible persons.
     * @summary request registration of a new user
     * @param {UserRegistration} userRegistration 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdmApiInterface
     */
    validateUserRaw(requestParameters: ValidateUserRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Will cause a mail to be sent to responsible persons.
     * request registration of a new user
     */
    validateUser(requestParameters: ValidateUserRequest): Promise<void>;

}

/**
 * 
 */
export class IdmApi extends runtime.BaseAPI implements IdmApiInterface {

    /**
     * Can be used after 6 months when receiving a token via e-mail.
     * confirm account to make sure it is still in use
     */
    async confirmAccountRaw(requestParameters: ConfirmAccountRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountConfirmation === null || requestParameters.accountConfirmation === undefined) {
            throw new runtime.RequiredError('accountConfirmation','Required parameter requestParameters.accountConfirmation was null or undefined when calling confirmAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/idm/confirmAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountConfirmationToJSON(requestParameters.accountConfirmation),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Can be used after 6 months when receiving a token via e-mail.
     * confirm account to make sure it is still in use
     */
    async confirmAccount(requestParameters: ConfirmAccountRequest): Promise<void> {
        await this.confirmAccountRaw(requestParameters);
    }

    /**
     * Can be used after receiving a token for a new user via e-mail.
     * confirm user registration by setting a password
     */
    async confirmUserRaw(requestParameters: ConfirmUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userConfirmation === null || requestParameters.userConfirmation === undefined) {
            throw new runtime.RequiredError('userConfirmation','Required parameter requestParameters.userConfirmation was null or undefined when calling confirmUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/idm/confirmUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserConfirmationToJSON(requestParameters.userConfirmation),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Can be used after receiving a token for a new user via e-mail.
     * confirm user registration by setting a password
     */
    async confirmUser(requestParameters: ConfirmUserRequest): Promise<void> {
        await this.confirmUserRaw(requestParameters);
    }

    /**
     * Will cause a mail to be sent to responsible persons.
     * request registration of a new user
     */
    async registerUserRaw(requestParameters: RegisterUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userMultiFactorRegistration === null || requestParameters.userMultiFactorRegistration === undefined) {
            throw new runtime.RequiredError('userMultiFactorRegistration','Required parameter requestParameters.userMultiFactorRegistration was null or undefined when calling registerUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/idm/registerUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserMultiFactorRegistrationToJSON(requestParameters.userMultiFactorRegistration),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Will cause a mail to be sent to responsible persons.
     * request registration of a new user
     */
    async registerUser(requestParameters: RegisterUserRequest): Promise<void> {
        await this.registerUserRaw(requestParameters);
    }

    /**
     * Will cause a mail to be sent to responsible persons.
     * request a password reset because a password has been forgotten
     */
    async requestPasswordResetRaw(requestParameters: RequestPasswordResetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.passwordResetRequest === null || requestParameters.passwordResetRequest === undefined) {
            throw new runtime.RequiredError('passwordResetRequest','Required parameter requestParameters.passwordResetRequest was null or undefined when calling requestPasswordReset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/idm/requestReset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetRequestToJSON(requestParameters.passwordResetRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Will cause a mail to be sent to responsible persons.
     * request a password reset because a password has been forgotten
     */
    async requestPasswordReset(requestParameters: RequestPasswordResetRequest): Promise<void> {
        await this.requestPasswordResetRaw(requestParameters);
    }

    /**
     * Can be used after sending a reset request and having received an e-mail with reset information.
     * reset a forgotten password with a token sent via mail
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.passwordReset === null || requestParameters.passwordReset === undefined) {
            throw new runtime.RequiredError('passwordReset','Required parameter requestParameters.passwordReset was null or undefined when calling resetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/idm/doReset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordResetToJSON(requestParameters.passwordReset),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Can be used after sending a reset request and having received an e-mail with reset information.
     * reset a forgotten password with a token sent via mail
     */
    async resetPassword(requestParameters: ResetPasswordRequest): Promise<void> {
        await this.resetPasswordRaw(requestParameters);
    }

    /**
     * Will cause a mail to be sent to responsible persons.
     * request registration of a new user
     */
    async validateUserRaw(requestParameters: ValidateUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userRegistration === null || requestParameters.userRegistration === undefined) {
            throw new runtime.RequiredError('userRegistration','Required parameter requestParameters.userRegistration was null or undefined when calling validateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/idm/validateUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegistrationToJSON(requestParameters.userRegistration),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Will cause a mail to be sent to responsible persons.
     * request registration of a new user
     */
    async validateUser(requestParameters: ValidateUserRequest): Promise<void> {
        await this.validateUserRaw(requestParameters);
    }

}
