import React from 'react';
import { Button, CrossIcon, WithTheme } from '@dm/design-system';
import { useTranslation } from 'react-i18next';

const icon = ({ theme }: WithTheme) => ({
    position: 'absolute',
    top: theme.dimension.spacing.xs.rem,
    right: theme.dimension.spacing.xs.rem,
    padding: theme.dimension.spacing.xs.rem,
    transition: `box-shadow 0.3s, transform 0.3s`,
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    transform: 'scale3d(.9, .9, 1)',
    ':hover': {
        transform: 'scale3d(1, 1, 1)',
    },
    borderRadius: '0px',
});

export default function OffCanvasCloseButton({ hideOffCanvas }: Readonly<{ hideOffCanvas: () => void }>) {
    const { t } = useTranslation();

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <Button
            dmId="off-canvas-overlay-close-button"
            onClick={hideOffCanvas}
            title={t('offCanvas.closeButton.title')}
            kind="invisible"
            style={icon}
            icon={CrossIcon}
        />
    );
}
