import { Box, Button, Form, Separator, Spacer } from '@dm/design-system';
import React, { FormEventHandler, ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { newsletterApi } from '../../../apiConfig';
import { useNotification } from '../../../notification/NotificationProvider';
import Heading from '../../richtextRenderer/components/Heading';
import RichText from '../../richtextRenderer/RichText';
import { componentMap, linkMap } from '../../richtextRenderer/config';
import { RichTextNode } from '../../richtextRenderer/render';
import { NewslettersContext } from './NewsletterPage';

interface NewsletterProps {
    channel: string;
    headline: string;
    description: RichTextNode;
}

interface NewsletterFormPros {
    channel: string;
    reloadSubscriptions: () => void;
    type: string;
    submitAction: () => Promise<void>;
}

export default function Newsletter({ channel, headline, description }: Readonly<NewsletterProps>) {
    const { reloadSubscriptions, subscribedChannels } = useContext(NewslettersContext);

    return (
        <Box>
            <Heading>{headline}</Heading>
            <Box>
                <RichText componentMap={componentMap} linkMap={linkMap} richText={description} />
                <Spacer size={24} />
                {subscribedChannels.includes(channel) ? (
                    <NewsletterForm
                        type="unsubscribe"
                        submitAction={() =>
                            newsletterApi.unsubscribeToNewsletterChannel({ newsletterSubscribeDTO: { channel } })
                        }
                        channel={channel}
                        reloadSubscriptions={reloadSubscriptions}
                    />
                ) : (
                    <NewsletterForm
                        type="subscribe"
                        submitAction={() =>
                            newsletterApi.subscribeToNewsletterChannel({ newsletterSubscribeDTO: { channel } })
                        }
                        channel={channel}
                        reloadSubscriptions={reloadSubscriptions}
                    />
                )}
            </Box>
            <Separator />
        </Box>
    );
}

function NewsletterForm({
    type,
    submitAction,
    channel,
    reloadSubscriptions,
}: Readonly<NewsletterFormPros>): ReactElement {
    const { t } = useTranslation();
    const { notify } = useNotification();

    const submit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        submitAction()
            .then(() => {
                reloadSubscriptions();
                notify({ message: t(`newsletter.${type}.success`), kind: 'positive' });
            })
            .catch((e) => {
                notify({ message: t(`newsletter.${type}.error`), kind: 'error' });
            });
    };

    return (
        <Form id={`newsletter-${type}-form-${channel}`} method="post" onSubmit={submit} style={{ width: '100%' }}>
            <Button type="submit" dmId={`newsletter-${channel}-${type}-button`}>
                {t(`newsletter.${type}.button`)}
            </Button>
        </Form>
    );
}
