import React, { useCallback, useEffect, useState } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { FocusOn } from 'react-focus-on';
import { useLocation } from 'react-router-dom';
import OffCanvasBackdrop from './OffCanvasBackdrop';
import OffCanvasRootLevel from './OffCanvasRootLevel';
import OffCanvasSubLevel from './OffCanvasSubLevel';
import { OffCanvasProvider, useHideOffCanvas } from './OffCanvasContext';
import OffCanvasMenuButton from './OffCanvasMenuButton';
import OffCanvasCloseButton from './OffCanvasCloseButton';
import OffCanvasNavigation from '../offCanvasNavigation/OffCanvasNavigation';
import { determineNavigationBreadcrumbs } from '../../../../navigation/NavigationUtil';
import { useContentTree } from '../../../../content/StoryProvider';

const renderCloseButton = (hideOffCanvas: () => void) => <OffCanvasCloseButton hideOffCanvas={hideOffCanvas} />;

export const ROOT_ID = 'ROOT';

function calculateCurrentLevel(subLevelItems: Array<string>) {
    return subLevelItems.length + 1;
}

function Wrapper({
    top,
    children,
    isOffcanvasVisible,
}: Readonly<{
    top: boolean;
    children: JSX.Element;
    isOffcanvasVisible: boolean;
}>) {
    const hideOffCanvas = useHideOffCanvas();

    const onClick = useCallback(() => {
        hideOffCanvas();
    }, [hideOffCanvas]);
    return (
        <FocusOn autoFocus onClickOutside={onClick} enabled={top && isOffcanvasVisible}>
            {children}
        </FocusOn>
    );
}

function OffCanvas() {
    const [isOffCanvasVisible, setIsOffCanvasVisible] = useState(false);
    const [subLevelItems, setSubLevelItems] = useState<Array<string>>([]);
    const currentLevel = calculateCurrentLevel(subLevelItems);
    const { navigation } = useContentTree();
    const { pathname } = useLocation();

    useEffect(() => {
        setSubLevelItems(determineNavigationBreadcrumbs(navigation, pathname));
    }, [navigation, pathname]);

    const listener = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setIsOffCanvasVisible(false);
        }
    }, []);

    const addSelectedItemToStack = useCallback((idToAdd: string) => {
        setSubLevelItems((prevState) => [...prevState, idToAdd]);
    }, []);

    const removeItemFromStack = useCallback(() => {
        setSubLevelItems((prevState) => {
            const tempSubLevelItems = [...prevState];
            tempSubLevelItems.pop();
            return tempSubLevelItems;
        });
    }, []);

    const hideOffCanvas = useCallback(() => setIsOffCanvasVisible(false), []);
    const showOffCanvas = useCallback(() => setIsOffCanvasVisible(true), []);

    const getSubLevelComponent = () => {
        return subLevelItems.map((itemId, index) => (
            <Wrapper key={itemId} top={subLevelItems.length === index + 1} isOffcanvasVisible={isOffCanvasVisible}>
                <OffCanvasSubLevel id={itemId} key={itemId} renderCloseButton={renderCloseButton}>
                    <OffCanvasNavigation id={itemId} />
                </OffCanvasSubLevel>
            </Wrapper>
        ));
    };

    const renderCloseButtonRootLevel = useCallback(
        () =>
            renderCloseButton(() => {
                setIsOffCanvasVisible(false);
            }),
        []
    );

    useEffect(() => {
        if (isOffCanvasVisible) {
            document.addEventListener('keydown', listener, false);
        } else {
            clearAllBodyScrollLocks();
        }
        return () => document.removeEventListener('keydown', listener, false);
    }, [isOffCanvasVisible, listener]);
    return (
        <OffCanvasProvider
            hideOffCanvas={hideOffCanvas}
            addSelectedItemToStack={addSelectedItemToStack}
            removeItemFromStack={removeItemFromStack}
            isOffCanvasVisible={isOffCanvasVisible}
            offCanvasLevelDepth={currentLevel}
        >
            <OffCanvasMenuButton showOffCanvas={showOffCanvas} />
            <div data-dmid="off-canvas">
                {isOffCanvasVisible && <OffCanvasBackdrop />}
                <Wrapper top={subLevelItems.length === 0} isOffcanvasVisible={isOffCanvasVisible}>
                    <OffCanvasRootLevel renderCloseButton={renderCloseButtonRootLevel}>
                        <OffCanvasNavigation id={ROOT_ID} />
                    </OffCanvasRootLevel>
                </Wrapper>
                {getSubLevelComponent()}
            </div>
        </OffCanvasProvider>
    );
}

export default OffCanvas;
