import React, { ElementType, ReactNode } from 'react';
import { Button, IconType } from '@dm/design-system';
import { datadogRum } from '@datadog/browser-rum';
import getTenant from '../../../content/getTenant';
import { useSession } from '../../../session/SessionProvider';
import { useTrackingContext } from './TrackingProvider';
import replaceCurrentHostWithBackendUrlAndPrefix from '../../../BackendApiUtil';

type TrackedButtonProps = {
    kind?: 'primary' | 'secondary' | 'tertiary' | 'invisible';
    style?: object;
    target?: string;
    trackedType: string;
    icon?: IconType;
    as?: ElementType;
    href: string;
    children: ReactNode;
};

export default function TrackedButton({
    kind,
    style,
    target,
    trackedType,
    icon,
    as,
    href,
    children,
}: Readonly<TrackedButtonProps>) {
    const { userInfo } = useSession();
    const { getTimeOnPageInSec } = useTrackingContext();

    const clickHandler = () => {
        datadogRum.addAction('trackedLink', {
            dmId: trackedType,
            userHash: userInfo?.userHash,
            slug: href,
            tenant: getTenant(),
            timeOnPageInSec: getTimeOnPageInSec(),
        });
    };

    return (
        <Button
            kind={kind}
            style={style}
            target={target}
            icon={icon}
            as={as}
            href={replaceCurrentHostWithBackendUrlAndPrefix(href)}
            onClick={clickHandler}
        >
            {children}
        </Button>
    );
}
