/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * information needed to reset a password
 * @export
 * @interface PasswordReset
 */
export interface PasswordReset {
    /**
     * e-mail address
     * @type {string}
     * @memberof PasswordReset
     */
    email: string;
    /**
     * new password, must not be blank
     * @type {string}
     * @memberof PasswordReset
     */
    newPassword: string;
    /**
     * 
     * @type {Tenant}
     * @memberof PasswordReset
     */
    tenant: Tenant;
    /**
     * token received via e-mail, must not be blank
     * @type {string}
     * @memberof PasswordReset
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordReset
     */
    recaptchaToken?: string;
}

export function PasswordResetFromJSON(json: any): PasswordReset {
    return PasswordResetFromJSONTyped(json, false);
}

export function PasswordResetFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordReset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'newPassword': json['newPassword'],
        'tenant': TenantFromJSON(json['tenant']),
        'token': json['token'],
        'recaptchaToken': !exists(json, 'recaptchaToken') ? undefined : json['recaptchaToken'],
    };
}

export function PasswordResetToJSON(value?: PasswordReset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'newPassword': value.newPassword,
        'tenant': TenantToJSON(value.tenant),
        'token': value.token,
        'recaptchaToken': value.recaptchaToken,
    };
}


