import { BackendModule } from 'i18next';
import { contentApi } from '../apiConfig';

const TranslationLoader: BackendModule = {
    type: 'backend',
    init: (services, backendOptions, i18nextOptions) => {
        // noop
    },
    read(language, namespace, callback) {
        const locale = `${language}`;
        contentApi
            .getLabelsForLocale({ locale })
            .then((labels) => {
                callback(null, labels.translations);
            })
            .catch((error) => {
                callback(error, null);
            });
    },
};

export default TranslationLoader;
