import { FelaStyle, ThemeProps } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { TileProps } from './Tile';

const subtitleStyle: FelaStyle<ThemeProps, TileProps> = ({ theme }) => ({
    textAlign: 'center',
    width: '100%',
    fontWeight: 500,
    color: theme.palette.icon.hex,
    marginTop: theme.dimension.spacing.xxxs.rem,
    overflow: 'hidden',
    position: 'absolute',
    boxSizing: 'border-box',
    padding: '10px',
    top: '150px',
    [theme.breakpoints.horizontal.s.mediaQuery]: {
        top: '160px',
    },
    [theme.breakpoints.horizontal.m.mediaQuery]: {
        top: '170px',
    },
});

export default function TileSubtitle({
    css,
    children,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Readonly<Record<string, any>>): ReactElement {
    return <div className={css(subtitleStyle)}>{children}</div>;
}
