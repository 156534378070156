import { ThemeProps, useFela } from '@dm/design-system';
import * as React from 'react';
import FlyoutElement from './FlyoutElement';

const flyoutStyle = ({ theme }: { theme: ThemeProps }): Record<string, unknown> => {
    return {
        borderRadius: theme.radius.s.rem,
        boxShadow: 'rgb(0 0 0 / 10%) 1px 1px 3px 2px',
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.dimension.zIndex.tooltip, // stay on top of other elements (such as teaser, ...)
        backgroundColor: theme.palette.color5.hex, // for some reason background is transparent when removing this
        position: 'absolute', // this positioning should be relative to body. trying to use a parent with position:relative leads to blue outline of flyout
        width: 312, // this is a random magic width which fits all texts and all large monitors,
        [theme.breakpoints.horizontal.xl.mediaQuery]: {
            right: 'unset',
            marginLeft: -42,
        },
        right: 0,
        [theme.breakpoints.horizontal.xs.mediaQuery]: {
            right: 50, // this aligns it mostly to the middle in case of very small screens
        },
    };
};

export interface FlyoutProps {
    isOpen: boolean;
    elements: string[] | React.JSX.Element[];
    onClick: () => void;
}

export default function Flyout({ isOpen, elements, onClick }: Readonly<FlyoutProps>): React.JSX.Element | null {
    const { css } = useFela<ThemeProps>();

    if (!isOpen) return null;

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className={css(flyoutStyle)} onClick={onClick} onKeyDown={onClick}>
            {elements.map((element) => {
                return <FlyoutElement key={element.key} content={element} />;
            })}
        </div>
    );
}
