import React, { ReactElement } from 'react';
import { Box, Cell } from '@dm/design-system';
import { RichTextNode } from '../richtextRenderer/render';
import RichText from '../richtextRenderer/RichText';
import Tile, { TileProps } from './Tile';
import { componentMap, linkMap } from '../richtextRenderer/config';

export interface TileSectionProps {
    text: RichTextNode;
    tiles: TileProps[];
}

export default function TileSection({ text, tiles }: Readonly<TileSectionProps>): ReactElement {
    return (
        <Box>
            <RichText componentMap={componentMap} linkMap={linkMap} richText={text} />
            <Cell>
                {tiles.map((tile) => {
                    return (
                        <Tile
                            key={tile.title}
                            link={tile.link}
                            title={tile.title}
                            subtitle={tile.subtitle}
                            backgroundImage={tile.backgroundImage}
                            color={tile.color}
                            dwhRedirect={tile.dwhRedirect}
                        />
                    );
                })}
            </Cell>
        </Box>
    );
}
