import { ContentTreeItem } from '../gen/ppapiclient';

type NavigationItem = { parentItem?: ContentTreeItem; item: ContentTreeItem };
export const findNavigationItemInContentTreeById = (
    uuid: string,
    currentContentTreePart: ContentTreeItem[],
    parentItem?: ContentTreeItem
): NavigationItem | null => {
    let result: NavigationItem | null = null;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of currentContentTreePart) {
        if (item.uuid === uuid) {
            return { parentItem, item };
        }

        const children = item.children ?? [];
        result = findNavigationItemInContentTreeById(uuid, children, item);
        if (result != null) {
            return result;
        }
    }
    return result;
};

export const determineNavigationBreadcrumbs = (navigation: ContentTreeItem[], pathname: string) => {
    const result: string[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const currentItem of navigation) {
        if (pathname.includes(currentItem.fullSlug)) {
            result.push(currentItem.uuid);
            result.push(...determineNavigationBreadcrumbs(currentItem.children ?? [], pathname));
        }
    }
    return result;
};
