import { AngleRightIcon, Heading } from '@dm/design-system';
import React from 'react';

type Props = {
    heading: string;
};

const cardHeaderStyle = {
    color: '#002878',
    paddingBottom: '12px',
    flexDirection: 'row',
    justifyContent: 'space-between',
};

export default function UserProfilePageCardHeader({ heading }: Readonly<Props>) {
    return (
        <Heading dmId={heading} kind="quaternary" style={cardHeaderStyle}>
            <span>{heading}</span>
            <AngleRightIcon width={16} height={16} />
        </Heading>
    );
}
