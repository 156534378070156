import { Tenant } from '../gen/ppapiclient';
import { supportedLanguagesForTenants } from './getTenant';

export default function formatLocalDate(date: Date, tenant: Tenant): string {
    const localDateOptions: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };

    // use same date format for RS and HR (spaces between)
    if (tenant === Tenant.RS) {
        return Intl.DateTimeFormat('hr-HR', localDateOptions).format(date);
    }

    // use same date format for bs-BA like in Germany with point at the end
    if (tenant === Tenant.BA) {
        return `${Intl.DateTimeFormat('de-DE', localDateOptions).format(date)}.`;
    }

    const locale = supportedLanguagesForTenants[tenant];
    if ([Tenant.HR, Tenant.SK].includes(tenant)) {
        return Intl.DateTimeFormat(locale, localDateOptions).format(date).replace(/\s/g, '');
    }
    if (tenant === Tenant.BG) {
        return Intl.DateTimeFormat(locale, localDateOptions).format(date).replace(/\s/g, ' ');
    }
    if (tenant === Tenant.CZ) {
        return Intl.DateTimeFormat('de-DE', localDateOptions).format(date);
    }
    return Intl.DateTimeFormat(locale, localDateOptions).format(date);
}
