/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * configuration for recaptcha
 * @export
 * @interface RecaptchaConfigDTO
 */
export interface RecaptchaConfigDTO {
    /**
     * 
     * @type {string}
     * @memberof RecaptchaConfigDTO
     */
    siteKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof RecaptchaConfigDTO
     */
    enabled?: boolean;
}

export function RecaptchaConfigDTOFromJSON(json: any): RecaptchaConfigDTO {
    return RecaptchaConfigDTOFromJSONTyped(json, false);
}

export function RecaptchaConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecaptchaConfigDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'siteKey': json['siteKey'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
    };
}

export function RecaptchaConfigDTOToJSON(value?: RecaptchaConfigDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'siteKey': value.siteKey,
        'enabled': value.enabled,
    };
}


