import React, { ReactElement } from 'react';
import { idmApi } from '../../../../apiConfig';
import { RecaptchaAction, RequestPasswordResetRequest } from '../../../../gen/ppapiclient';
import SuccessRedirect from '../SuccessRedirect';
import SubmitChecker, { SubmitCheckerUserProps } from '../util/SubmitChecker';
import PasswordForgottenForm, { PasswordForgottenFormProps } from './PasswordForgottenForm';

export type PasswordForgottenProps = Omit<PasswordForgottenFormProps, 'onSubmit'> & SubmitCheckerUserProps;

const FormFn = ({ emailLabel, requiredLabel, submitLabel }) =>
    function Form({ onSubmit }): ReactElement {
        return (
            <PasswordForgottenForm
                emailLabel={emailLabel}
                requiredLabel={requiredLabel}
                submitLabel={submitLabel}
                onSubmit={onSubmit}
            />
        );
    };

export default function PasswordForgotten({
    emailLabel,
    requiredLabel,
    submitLabel,
    errorMessage,
}: PasswordForgottenProps): ReactElement {
    return (
        <SubmitChecker
            post={(requestPasswordResetRequest: RequestPasswordResetRequest, captchaToken): Promise<void> =>
                idmApi.requestPasswordReset({
                    passwordResetRequest: {
                        ...requestPasswordResetRequest.passwordResetRequest,
                        recaptchaToken: captchaToken,
                    },
                })
            }
            form={FormFn({ emailLabel, requiredLabel, submitLabel })}
            successComponent={SuccessRedirect}
            errorMessage={errorMessage}
            recaptchaAction={RecaptchaAction.PASSWORD_FORGOTTEN}
        />
    );
}
