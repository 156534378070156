import React, { ReactElement } from 'react';
import TrackedLink from '../../../app/components/tracking/TrackedLink';
import { RichtextMarkAttrs } from '../render';

type Props = {
    imageAsLinkProps: RichtextMarkAttrs;
    image: ReactElement;
};

function ImageAsLink({ imageAsLinkProps, image }: Readonly<Props>) {
    return (
        <TrackedLink
            to={imageAsLinkProps.href}
            trackedType="imageAsLink"
            openInNewTab={imageAsLinkProps.target !== '_self'}
            isExternalLink={imageAsLinkProps.linktype !== 'story'}
        >
            {image}
        </TrackedLink>
    );
}

export default ImageAsLink;
