import React, { ReactElement } from 'react';
import { useSession } from '../../../session/SessionProvider';
import AuthenticatedMessage from './AuthenticatedMessage';
import NotAuthenticatedMessage from './NotAuthenticatedMessage';

export default function ErrorMessage(): ReactElement {
    const { authentication } = useSession();

    if (authentication === 'success') {
        return <AuthenticatedMessage />;
    }

    return <NotAuthenticatedMessage />;
}
