import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
    Box,
    CheckCircleIcon,
    CrossCircleIcon,
    List,
    ListItem,
    Spacer,
    Text,
    ThemeProps,
    useFela,
} from '@dm/design-system';
import { useTranslation } from 'react-i18next';

export type RuleTypes =
    | 'minLength'
    | 'maxLength'
    | 'uppercaseLetter'
    | 'lowercaseLetter'
    | 'number'
    | 'notSpecialChar'
    | 'match'
    | 'specialChar';

interface PasswordPolicyProps {
    password: string;
    passwordAgain?: string;
    minLength?: number;
    maxLength?: number;
    rules: Array<RuleTypes>;
    onChange?: (value: boolean) => void;
}

export default function PasswordPolicyChecker({
    password,
    passwordAgain,
    minLength,
    maxLength,
    rules,
    onChange,
}: Readonly<PasswordPolicyProps>): ReactElement {
    const { theme } = useFela<ThemeProps>();
    const { t } = useTranslation();
    const [isValid, setIsValid] = useState(false);

    const ruleDefinitions: {
        [key in RuleTypes]: { valid: boolean; message: string };
    } = useMemo(
        () => ({
            minLength: {
                valid: password.length > 0 && password.length >= (minLength ?? 100),
                // `Password has at least ${minLength} characters.`,
                message: t('passwordPolicy.fallsShortOfMinimumLength', {
                    minLength,
                }),
            },
            maxLength: {
                valid: password.length > 0 && password.length <= (maxLength ?? 100),
                // `Password has no more than ${maxLength} characters.`
                message: t('passwordPolicy.exceedsTheMaximumLength', {
                    maxLength,
                }),
            },
            lowercaseLetter: {
                valid: /[a-z]/.test(password),
                // `Password need a lowercase letter.`
                message: t('passwordPolicy.needLowercaseLetter', { letters: 'a .. z' }),
            },
            uppercaseLetter: {
                valid: /[A-Z]/.test(password),
                // `Password need an uppercase letter.`
                message: t('passwordPolicy.needUppercaseLetter', { letters: 'A .. Z' }),
            },
            number: {
                valid: /\d/g.test(password),
                // `Password need numbers.`
                message: t('passwordPolicy.needNumbers'),
            },
            match: {
                valid: password.length > 0 && password === passwordAgain,
                // `Passwords must match.`
                message: t('passwordPolicy.mustMatched'),
            },
            notSpecialChar: {
                valid: password.length > 0 && !/[^\w\s\][^,]/gi.test(password),
                // `Password must not contain any special characters.`
                message: t('passwordPolicy.mustNotContainAnySpecialChars'),
            },
            specialChar: {
                valid:
                    password.length > 0 &&
                    /^[!@#$%^&*_\-+=|(){}[\]:;"<>,.?]+$/.test(password.replaceAll(/[0-9a-zA-Z ]/g, '')),
                message: t('passwordPolicy.requiredSpecialCharacters', { specialChars: '!@#$%^&*_-+=|(){}[]:;"<>,.?' }),
            },
        }),
        [maxLength, minLength, password, passwordAgain, t]
    );

    const enabledRules = rules.filter((rule) => Boolean(ruleDefinitions[rule]));
    useEffect(() => {
        setIsValid(enabledRules.every((rule) => ruleDefinitions[rule].valid));
        onChange?.(isValid);
    }, [enabledRules, isValid, onChange, password, passwordAgain, ruleDefinitions]);

    return (
        <Box>
            <Spacer size={theme.dimension.spacing.s.rem} />
            <List>
                {enabledRules.map((rule) => {
                    const { message, valid } = ruleDefinitions[rule];
                    return (
                        <ListItem dmId={rule} icon={valid ? CheckCircleIcon : CrossCircleIcon} key={rule}>
                            <Text>{message}</Text>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}
