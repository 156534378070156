import React, { ReactElement } from 'react';
import { Banner, Box, Separator } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import StoryRenderer from '../../StoryRenderer';
import Terms from './Terms';
import { useTerms } from './TermsProvider';

export default function TermsPage(): ReactElement {
    const { terms, error } = useTerms();
    const { t } = useTranslation();

    return (
        <Box>
            <StoryRenderer />
            <Separator />
            {terms.map((termsResponse) => (
                <Box key={termsResponse.application + termsResponse.version}>
                    <Terms terms={termsResponse} />
                    <Separator />
                </Box>
            ))}
            {error && <Banner kind="error">{t('errorPage.authenticated.description')}</Banner>}
        </Box>
    );
}
