import React, { ReactElement, useState } from 'react';
import { idmApi } from '../../../../apiConfig';
import { ConfirmAccountRequest, PasswordResetError, RecaptchaAction } from '../../../../gen/ppapiclient';
import SuccessRedirect from '../SuccessRedirect';
import SubmitChecker, { SubmitCheckerUserProps } from '../util/SubmitChecker';
import ConfirmAccountForm, { ConfirmAccountFormProps } from './ConfirmAccountForm';

export interface ConfirmAccountProps extends Omit<ConfirmAccountFormProps, 'onSubmit'>, SubmitCheckerUserProps {
    invalidTokenErrorMessage?: string;
}

const FormFn = ({ validationErrorMessage, submitLabel }) =>
    function Form({ onSubmit }): ReactElement {
        return (
            <ConfirmAccountForm
                validationErrorMessage={validationErrorMessage}
                submitLabel={submitLabel}
                onSubmit={onSubmit}
            />
        );
    };

export default function ConfirmAccount({
    submitLabel,
    errorMessage,
    invalidTokenErrorMessage,
}: Readonly<ConfirmAccountProps>): ReactElement {
    const [backendError, setBackendError] = useState<PasswordResetError | null>(null);
    const errorConfig = {
        [PasswordResetError.INVALID_TOKEN_OR_USER]: (): void =>
            setBackendError(PasswordResetError.INVALID_TOKEN_OR_USER),
    };

    function getValidationErrorMessage(error) {
        if (error === PasswordResetError.INVALID_TOKEN_OR_USER) return invalidTokenErrorMessage;
        return undefined;
    }

    return (
        <SubmitChecker
            post={async (dto: ConfirmAccountRequest, captchaToken: string): Promise<void> => {
                setBackendError(null);
                await idmApi.confirmAccount({
                    accountConfirmation: {
                        ...dto.accountConfirmation,
                        recaptchaToken: captchaToken,
                    },
                });
            }}
            form={FormFn({ validationErrorMessage: getValidationErrorMessage(backendError), submitLabel })}
            successComponent={SuccessRedirect}
            errorMessage={errorMessage}
            errorConfig={errorConfig}
            recaptchaAction={RecaptchaAction.CONFIRM_ACCOUNT}
        />
    );
}
