import { Box, DropdownField, WithTheme } from '@dm/design-system';
import React, { ChangeEvent, CSSProperties } from 'react';
import { UserRegistration, UserRegistrationCountryCodeEnum } from '../../../gen/ppapiclient';
import { RegistrationFormField, RegistrationFormLabels, UpdateRegistrationValue } from '../types';
import countryCodes from '../../../content/components/idm/countryCodes.json';

const dropdownStyle = ({ theme }: WithTheme): CSSProperties => ({
    marginBottom: 20,
    [theme.breakpoints.horizontal.s.mediaQuery]: {
        flex: 1,
    },
});

export default function CountrySelect({
    labels,
    userRegistration,
    setDtoItem,
}: Readonly<{
    labels: RegistrationFormLabels;
    userRegistration: UserRegistration;
    setDtoItem: UpdateRegistrationValue;
}>) {
    const countryCodeField: RegistrationFormField = 'countryCode';

    return (
        <Box style={dropdownStyle}>
            <DropdownField
                label={labels[countryCodeField]}
                required
                dmId="country"
                options={Object.entries(countryCodes).map(([value, label]) => ({
                    value,
                    label,
                }))}
                value={userRegistration[countryCodeField]}
                onChange={(event: ChangeEvent<HTMLSelectElement> | undefined): void => {
                    if (event !== undefined) {
                        setDtoItem(countryCodeField, event.target.value as UserRegistrationCountryCodeEnum);
                    }
                }}
            />
        </Box>
    );
}
