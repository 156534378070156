import { Box, Button, Cell } from '@dm/design-system';
import React, { ReactElement, useCallback, useState } from 'react';
import PasswordPolicyChecker from './PasswordPolicyChecker';
import ValidatedPasswordField from './ValidatedPasswordField';

export interface NewPasswordInputsProps {
    onChange?: (value: string) => void;
    newPasswordLabel: string;
    repeatPasswordLabel: string;
    submitLabel: string;
    validationErrorMessage?: string;
}

const minLength = 8;
const maxLength = 32;

export default function PasswordInputs({
    onChange,
    newPasswordLabel,
    repeatPasswordLabel,
    submitLabel,
    validationErrorMessage,
}: Readonly<NewPasswordInputsProps>): ReactElement {
    const [isNotAllowedToSubmit, setIsNotAllowedToSubmit] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [firstPasswordFieldInvalid, setFirstPasswordFieldInvalid] = useState<boolean>(false);
    const [secondPasswordFieldInvalid, setSecondPasswordFieldInvalid] = useState<boolean>(false);

    const pageStyle = { flex: 1 };

    const onChangeHandler = useCallback(() => {
        setFirstPasswordFieldInvalid(isNotAllowedToSubmit);
        setSecondPasswordFieldInvalid(isNotAllowedToSubmit);
    }, [isNotAllowedToSubmit]);

    return (
        <Box style={pageStyle}>
            <Cell style={{ display: 'block' }}>
                <Box style={{ marginRight: 10, flex: 1, display: 'block', flexDirection: 'row' }}>
                    <Cell style={{ flex: 1 }}>
                        <ValidatedPasswordField
                            required
                            minLength={minLength}
                            maxLength={maxLength}
                            label={newPasswordLabel}
                            dmId="password"
                            invalid={firstPasswordFieldInvalid}
                            onChange={({ target: { value } }): void => {
                                setPassword(value);
                                if (onChange !== undefined && passwordRepeat === value) {
                                    onChange(value);
                                }
                            }}
                            customErrorMessage={validationErrorMessage}
                        />
                    </Cell>
                    <Cell style={{ flex: 1 }}>
                        <ValidatedPasswordField
                            required
                            label={repeatPasswordLabel}
                            dmId="passwordRepeat"
                            invalid={secondPasswordFieldInvalid}
                            onChange={({ target: { value } }): void => {
                                setPasswordRepeat(value);
                                if (onChange !== undefined && password === value) {
                                    onChange(value);
                                }
                            }}
                        />
                    </Cell>
                    <Cell>
                        <Button disabled={isNotAllowedToSubmit} type="submit">
                            {submitLabel}
                        </Button>
                    </Cell>
                </Box>
                <Box style={{ marginLeft: 10, flex: 1 }}>
                    <PasswordPolicyChecker
                        password={password}
                        passwordAgain={passwordRepeat}
                        minLength={minLength}
                        maxLength={maxLength}
                        rules={[
                            'minLength',
                            'maxLength',
                            'uppercaseLetter',
                            'lowercaseLetter',
                            'number',
                            'specialChar',
                            'match',
                        ]}
                        onChange={(value: boolean): void => {
                            if (setIsNotAllowedToSubmit !== undefined) {
                                setIsNotAllowedToSubmit(!value);
                            }
                            onChangeHandler();
                        }}
                    />
                </Box>
            </Cell>
        </Box>
    );
}
