import { Box, Spacer, ThemeProps, useBreakpoint, useFela, View } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderTabList from './navigation/HeaderTabList';
import UserProfileMenu from '../../features/UserProfileMenu/UserProfileMenu';
import TenantSelect from './TenantSelect';
import { useSession } from '../../session/SessionProvider';
import { OffCanvasMenu } from './navigation/offCanvas';
import Breadcrumb from './navigation/Breadcrumb';
import previewpic from './preview.png';
import SearchBox from './SearchBox';
import ServiceNotifications from './serviceNotification/ServiceNotifications';
import { useMaintenanceState } from '../../content/StoryProvider';

const navStyle = {
    minHeight: '4rem',
};

const viewStyle = ({ theme }: { theme: ThemeProps }) => ({
    width: '98%',
    [theme.breakpoints.horizontal.m.mediaQuery]: {
        paddingLeft: theme.dimension.spacing.xxxs.rem,
        paddingRight: theme.dimension.spacing.xxxs.rem,
    },
});

export function PartnerportalIcon({ isDesktop }: Readonly<{ isDesktop: boolean }>) {
    return (
        <Link to="/">
            <img
                width={isDesktop ? '300px' : '150px'}
                height={isDesktop ? '61px' : '30px'} // IE11 needs the height
                src="/partnerportal.svg"
                alt="logo"
            />
        </Link>
    );
}

export function PreviewIcon(): ReactElement {
    const is375 = useBreakpoint('min-width: 375px');
    const is425 = useBreakpoint('min-width: 425px');
    const is1024 = useBreakpoint('min-width: 1024px');

    let width = '40px';

    if (is375) {
        width = '80px';
    }
    if (is425) {
        width = '100px';
    }
    if (is1024) {
        width = '180px';
    }
    return <img src={previewpic} width={width} alt="Vorschau" />;
}

export default function Header(): ReactElement {
    const { css, theme } = useFela<ThemeProps>();
    const { pathname } = useLocation();
    const isDesktop = useBreakpoint(theme.breakpoints.horizontal.m.query);
    const isPreview = /preview/.exec(window.location.host);

    const { authentication } = useSession();
    const showTenantSelect = authentication !== 'success' || isPreview;
    const showSearchBox = !isPreview && !/\w\w\/search/.exec(pathname);

    return (
        <Box>
            <ServiceNotifications />
            <Spacer size={theme.dimension.spacing.xxs.rem} />
            <View align="left" noPadding>
                <Box
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: theme.dimension.spacing.xs.rem,
                        paddingRight: theme.dimension.spacing.xs.rem,
                    }}
                >
                    <PartnerportalIcon isDesktop={isDesktop} />
                    {isPreview && <PreviewIcon />}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {showSearchBox && <SearchBox />}
                        {showTenantSelect && <TenantSelect />}
                        {!useMaintenanceState() && <UserProfileMenu />}
                        {!useMaintenanceState() && <OffCanvasMenu />}
                    </div>
                </Box>
            </View>

            <Spacer size={theme.dimension.spacing.xs.rem} />
            <Breadcrumb />
            {isDesktop && (
                <nav className={css(navStyle)}>
                    <View noPadding>
                        <div className={css(viewStyle)}>
                            <HeaderTabList />
                        </div>
                    </View>
                </nav>
            )}
        </Box>
    );
}
