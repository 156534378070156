import { FelaStyle, ListItem as DMListItem, ThemeProps } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { RichTextComponentProps } from '../render';

const listItemStyle: FelaStyle<ThemeProps, Record<string, unknown>> = {
    color: 'black',
    '> p': {
        lineHeight: '1',
    },
};

export default function ListItem({ children }: Readonly<RichTextComponentProps>): ReactElement<RichTextComponentProps> {
    return (
        <DMListItem style={listItemStyle} dmId="li">
            {
                children as JSX.Element // List declares a JSX.Element as children type, which is not necessary
            }
        </DMListItem>
    );
}
