import React, { ReactElement, useState } from 'react';
import { idmApi } from '../../../../apiConfig';
import { PasswordResetError, RecaptchaAction, ResetPasswordRequest } from '../../../../gen/ppapiclient';
import SuccessRedirect from '../SuccessRedirect';
import SubmitChecker, { SubmitCheckerUserProps } from '../util/SubmitChecker';
import ResetPasswordForm, { ResetPasswordFormProps } from './ResetPasswordForm';

export interface ResetPasswordProps extends Omit<ResetPasswordFormProps, 'onSubmit'>, SubmitCheckerUserProps {
    invalidTokenErrorMessage?: string;
}

const FormFn = ({ getValidationErrorMessage, submitLabel, repeatPasswordLabel, newPasswordLabel }) =>
    function Form({ onSubmit }): ReactElement {
        return (
            <ResetPasswordForm
                onSubmit={onSubmit}
                newPasswordLabel={newPasswordLabel}
                repeatPasswordLabel={repeatPasswordLabel}
                submitLabel={submitLabel}
                validationErrorMessage={getValidationErrorMessage()}
            />
        );
    };

export default function ResetPassword({
    submitLabel,
    repeatPasswordLabel,
    newPasswordLabel,
    errorMessage,
    validationErrorMessage,
    invalidTokenErrorMessage,
}: Readonly<ResetPasswordProps>): ReactElement {
    const [backendError, setBackendError] = useState<PasswordResetError | null>(null);

    const errorConfig = {
        [PasswordResetError.PASSWORD_POLICY_VIOLATION]: (): void =>
            setBackendError(PasswordResetError.PASSWORD_POLICY_VIOLATION),
        [PasswordResetError.INVALID_TOKEN_OR_USER]: (): void =>
            setBackendError(PasswordResetError.INVALID_TOKEN_OR_USER),
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function getValidationErrorMessage() {
        if (backendError === PasswordResetError.PASSWORD_POLICY_VIOLATION) return validationErrorMessage;
        if (backendError === PasswordResetError.INVALID_TOKEN_OR_USER) return invalidTokenErrorMessage;
        return undefined;
    }

    return (
        <SubmitChecker
            post={async (resetPasswordRequest: ResetPasswordRequest, captchaToken): Promise<void> => {
                setBackendError(null);
                await idmApi.resetPassword({
                    passwordReset: {
                        ...resetPasswordRequest.passwordReset,
                        recaptchaToken: captchaToken,
                    },
                });
            }}
            form={FormFn({ getValidationErrorMessage, submitLabel, repeatPasswordLabel, newPasswordLabel })}
            successComponent={SuccessRedirect}
            errorMessage={errorMessage}
            errorConfig={errorConfig}
            recaptchaAction={RecaptchaAction.RESET_PASSWORD}
        />
    );
}
