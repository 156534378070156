import { useTranslation } from 'react-i18next';
import React, { FormEventHandler } from 'react';
import { Box, Button, CartIcon, Cell, Form, Spacer, TrashIcon } from '@dm/design-system';
import RichText from '../../../content/richtextRenderer/RichText';
import { componentMap, linkMap } from '../../../content/richtextRenderer/config';
import TermsConfirmBlock from '../../../content/components/termsPage/TermsConfirmBlock';
import { RichTextNode } from '../../../content/richtextRenderer/render';
import { ApplicationStatus, AppplicationAdditionalInformationFieldProps } from '../types';
import AdditionalInformationInputFields from './AdditionalInformationInputFields';
import { TermsResponse } from '../../../gen/ppapiclient';

interface Props {
    status: ApplicationStatus;
    additionalDescription: RichTextNode;
    description: RichTextNode;
    additionalInformationFields: AppplicationAdditionalInformationFieldProps[] | undefined;
    applicationType: string;
    orderState: string;
    terms: TermsResponse | undefined;
    onOrderSubmit: FormEventHandler<HTMLFormElement>;
    onUnsubscribeApplicationSubmit: () => void;
    setAdditionalInformationFieldValue: (fieldName: string, value: string) => void;
    loading: boolean;
}

const actionAreaStyle = {
    alignItems: 'center',
    boxSizing: 'border-box',
};

function ApplicationOrderForm({
    description,
    additionalDescription,
    additionalInformationFields,
    applicationType,
    status,
    onOrderSubmit,
    onUnsubscribeApplicationSubmit,
    terms,
    setAdditionalInformationFieldValue,
    loading,
    orderState,
}: Readonly<Props>) {
    const { t } = useTranslation();

    return (
        <Box>
            <RichText richText={description} componentMap={componentMap} linkMap={linkMap} />
            <RichText richText={additionalDescription} componentMap={componentMap} linkMap={linkMap} />
            <Spacer size={12} />
            {terms && (
                <Box style={actionAreaStyle}>
                    <Form
                        id={`application-form-${applicationType}`}
                        method="post"
                        onSubmit={onOrderSubmit}
                        style={{ width: '100%' }}
                    >
                        <AdditionalInformationInputFields
                            additionalInformationFields={additionalInformationFields}
                            setAdditionalInformationFieldValue={setAdditionalInformationFieldValue}
                            disabled={status.orderButtonDisabled}
                        />
                        <TermsConfirmBlock
                            terms={terms}
                            introText={t('application.term.confirm.introText')}
                            showIntroText={!status.orderButtonDisabled}
                        />
                        <Spacer size={12} />
                        <Cell>
                            <Button
                                style={{ flexBasis: '50%' }}
                                type="submit"
                                dmId={`application-${applicationType}-order-button`}
                                disabled={loading || status.orderButtonDisabled}
                                icon={CartIcon}
                            >
                                {t('application.orderbutton')}
                            </Button>
                            <Spacer size={12} />
                            <Button
                                style={{ flexBasis: '50%' }}
                                dmId={`application-${applicationType}-unsubscribe-button`}
                                disabled={loading || orderState === 'ORDERED' || !status.orderButtonDisabled}
                                onClick={onUnsubscribeApplicationSubmit}
                                icon={TrashIcon}
                            >
                                {t('application.unsubscribeButton')}
                            </Button>
                        </Cell>
                    </Form>
                </Box>
            )}
        </Box>
    );
}

export default ApplicationOrderForm;
