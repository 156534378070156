import React from 'react';
import { Box, Spacer, theme } from '@dm/design-system';

type Props = {
    label: string;
    value: string | null;
};

export default function MyUserDataSectionEntry({ label, value }: Readonly<Props>) {
    const noDataPlaceholder = '---';

    return (
        <Box
            style={{
                flex: '50%',
                flexDirection: 'column',
            }}
        >
            <Box style={{ color: theme.palette.color10.hex }} size="small" dmId="myUserDataSectionEntryLabel-id">
                {label}
            </Box>
            <Spacer size={6} />
            <Box dmId="myUserDataSectionEntryValue-id">{value ?? noDataPlaceholder}</Box>
        </Box>
    );
}
