/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApplicationOrderDTO,
    ApplicationOrderDTOFromJSON,
    ApplicationOrderDTOToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
} from '../models';

export interface OrderApplicationRequest {
    applicationOrderDTO: ApplicationOrderDTO;
}

export interface UnsubscribeApplicationRequest {
    appKey: string;
}

/**
 * ApplicationsApi - interface
 * 
 * @export
 * @interface ApplicationsApiInterface
 */
export interface ApplicationsApiInterface {
    /**
     * 
     * @summary returns the application list the user is authorized to view
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApiInterface
     */
    getOrderStatesRaw(): Promise<runtime.ApiResponse<{ [key: string]: string | undefined; }>>;

    /**
     * returns the application list the user is authorized to view
     */
    getOrderStates(): Promise<{ [key: string]: string | undefined; }>;

    /**
     * Will trigger an iam order to be approved by a responsible person.
     * @summary request order of a new application
     * @param {ApplicationOrderDTO} applicationOrderDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApiInterface
     */
    orderApplicationRaw(requestParameters: OrderApplicationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Will trigger an iam order to be approved by a responsible person.
     * request order of a new application
     */
    orderApplication(requestParameters: OrderApplicationRequest): Promise<void>;

    /**
     * triggers an iam order
     * @summary request to unsubscribe an application
     * @param {string} appKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApiInterface
     */
    unsubscribeApplicationRaw(requestParameters: UnsubscribeApplicationRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * triggers an iam order
     * request to unsubscribe an application
     */
    unsubscribeApplication(requestParameters: UnsubscribeApplicationRequest): Promise<void>;

}

/**
 * 
 */
export class ApplicationsApi extends runtime.BaseAPI implements ApplicationsApiInterface {

    /**
     * returns the application list the user is authorized to view
     */
    async getOrderStatesRaw(): Promise<runtime.ApiResponse<{ [key: string]: string | undefined; }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/idm/application/orderStates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * returns the application list the user is authorized to view
     */
    async getOrderStates(): Promise<{ [key: string]: string | undefined; }> {
        const response = await this.getOrderStatesRaw();
        return await response.value();
    }

    /**
     * Will trigger an iam order to be approved by a responsible person.
     * request order of a new application
     */
    async orderApplicationRaw(requestParameters: OrderApplicationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.applicationOrderDTO === null || requestParameters.applicationOrderDTO === undefined) {
            throw new runtime.RequiredError('applicationOrderDTO','Required parameter requestParameters.applicationOrderDTO was null or undefined when calling orderApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/idm/application/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationOrderDTOToJSON(requestParameters.applicationOrderDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Will trigger an iam order to be approved by a responsible person.
     * request order of a new application
     */
    async orderApplication(requestParameters: OrderApplicationRequest): Promise<void> {
        await this.orderApplicationRaw(requestParameters);
    }

    /**
     * triggers an iam order
     * request to unsubscribe an application
     */
    async unsubscribeApplicationRaw(requestParameters: UnsubscribeApplicationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.appKey === null || requestParameters.appKey === undefined) {
            throw new runtime.RequiredError('appKey','Required parameter requestParameters.appKey was null or undefined when calling unsubscribeApplication.');
        }

        const queryParameters: any = {};

        if (requestParameters.appKey !== undefined) {
            queryParameters['appKey'] = requestParameters.appKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/idm/application/unsubscribe`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * triggers an iam order
     * request to unsubscribe an application
     */
    async unsubscribeApplication(requestParameters: UnsubscribeApplicationRequest): Promise<void> {
        await this.unsubscribeApplicationRaw(requestParameters);
    }

}
