import React from 'react';
import { AngleRightIcon, Box, Cell, Heading, Separator, Spacer, Text, ThemeProps, useFela } from '@dm/design-system';
import TrackedLink from '../../../app/components/tracking/TrackedLink';
import { SearchHitResponse } from '../../../gen/ppapiclient';

export interface SearchHitProps {
    hit: SearchHitResponse;
    isLastItem: boolean;
}

function SearchHit({ hit, isLastItem }: Readonly<SearchHitProps>) {
    const { css } = useFela<ThemeProps>();
    return (
        <Box key={hit.fullSlug}>
            <Spacer size={5} />
            <TrackedLink to={`/${hit.fullSlug}`} trackedType="searchResult" className={css({ textDecoration: 'none' })}>
                <Cell style={{ marginLeft: 20 }}>
                    <Heading kind="quaternary">
                        <AngleRightIcon
                            data-dmid="read-article"
                            className={css({ width: 10, marginBottom: -5, marginRight: 10 })}
                        />
                        {hit.name}
                    </Heading>
                    <Spacer size={3} />
                    <Cell>
                        <Text style={{ textOverflow: 'ellipsis' }}>{hit.excerpt}...</Text>
                        <Spacer size={3} />
                    </Cell>
                </Cell>
            </TrackedLink>
            <Spacer size={5} />
            {!isLastItem && <Separator />}
        </Box>
    );
}

export default SearchHit;
