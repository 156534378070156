

// generated code

export interface AppInfo {
   packageVersion: string,
   gitShortHash: string,
   gitHash: string,
   commitDateShort: string,
   commitDateTimeShort: string
   fullVersion: string
}

const appInfo : AppInfo = {
  packageVersion : '0.1.0',
  gitShortHash: '7a2f683',
  gitHash: '7a2f683909da2a00e779423630b3d86a93c569d8',
  commitDateShort: '2024-11-05',
  commitDateTimeShort: '2024-11-05T12:52:58',
  fullVersion: '0.1.0-2024-11-05.7a2f683'
};

export default appInfo;

