import React, { useEffect, useRef } from 'react';

type Props = {
    title: string;
    url: string;
};

const iframeStyle = {
    border: 0,
    width: '100%',
    height: '100%',
};

export default function EmbeddedApplication({ title, url }: Readonly<Props>) {
    const ref = useRef<HTMLIFrameElement | null>(null);

    useEffect(() => {
        const resize = (event) => {
            if (!url.startsWith(event.origin)) {
                return;
            }
            const { current: iframe } = ref;
            if (iframe) {
                const bufferToAvoidScrollbar = 12;
                iframe.style.height = `${event.data.height + bufferToAvoidScrollbar}px`;
            }
        };

        window.addEventListener('message', resize);
        return function cleanup() {
            window.removeEventListener('message', resize);
        };
    });

    return <iframe ref={ref} title={title} src={url} style={iframeStyle} allow="clipboard-read *; clipboard-write *" />;
}
