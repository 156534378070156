import { Banner, Box, Button, FelaStyle, Form, Spacer, theme, ThemeProps } from '@dm/design-system';
import React, { CSSProperties, FormEventHandler, ReactElement, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AccountConfirmation, ConfirmAccountRequest } from '../../../../gen/ppapiclient';
import getTenant from '../../../getTenant';
import { SubmitterProps } from '../util/SubmitChecker';

const rowStyle: CSSProperties = {
    flexDirection: 'row',
};

const buttonStyle: FelaStyle<ThemeProps, Record<string, unknown>> = () => ({
    minWidth: 150,
    width: 200,
    margin: theme.dimension.spacing.xxxs.rem,
});

export interface ConfirmAccountFormProps extends SubmitterProps<ConfirmAccountRequest> {
    validationErrorMessage?: string;
    submitLabel: string;
}

export default function ConfirmAccountForm({
    onSubmit,
    validationErrorMessage,
    submitLabel,
}: Readonly<ConfirmAccountFormProps>): ReactElement {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const accountConfirmation = useRef<AccountConfirmation>({
        tenant: getTenant(),
        email: queryParams.get('email') ?? '',
        token: queryParams.get('token') ?? '',
    });
    const submit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        onSubmit({ accountConfirmation: accountConfirmation.current });
    };
    return (
        <Form name="confirm-account-form" method="post" onSubmit={submit} style={rowStyle}>
            <Box>
                {validationErrorMessage && (
                    <>
                        <Banner dmId="banner-error" kind="error">
                            {validationErrorMessage}
                        </Banner>
                        <Spacer size={theme.dimension.spacing.xs.rem} />
                    </>
                )}
                <Button style={buttonStyle} type="submit">
                    {submitLabel}
                </Button>
            </Box>
        </Form>
    );
}
