import { Banner, Box, Spacer, ThemeProps, useFela } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { componentMap, linkMap } from '../richtextRenderer/config';
import { RichTextNode } from '../richtextRenderer/render';
import RichText from '../richtextRenderer/RichText';
import { useSession } from '../../session/SessionProvider';

interface NoticeBlockProps {
    kind: 'positive' | 'neutral' | 'warning' | 'error';
    content: RichTextNode;
    customerRole: string;
    customerRoleContent: RichTextNode;
}

export default function NoticeBlock({
    kind,
    content,
    customerRole,
    customerRoleContent,
}: Readonly<NoticeBlockProps>): ReactElement {
    const { theme } = useFela<ThemeProps>();
    const userRoles = useSession().userInfo?.appRoles ?? [];

    let richTextContent = content;
    if (userRoles.includes(customerRole?.toUpperCase())) {
        richTextContent = customerRoleContent;
    }

    return (
        <Box style={{ flex: 1 }}>
            <Banner kind={kind}>
                <RichText richText={richTextContent} componentMap={componentMap} linkMap={linkMap} />
            </Banner>
            <Spacer size={theme.dimension.spacing.s.rem} />
        </Box>
    );
}
