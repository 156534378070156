import React, { useContext, useMemo } from 'react';

type OffCanvasContextType = {
    hideOffCanvas: () => void;
    removeItemFromStack: () => void;
    addSelectedItemToStack: (selectedContentPath: string) => void;
    isOffCanvasVisible: boolean;
    offCanvasLevelDepth: number;
} & React.PropsWithChildren;

const OffCanvasContext = React.createContext<OffCanvasContextType | undefined>(undefined);

export function OffCanvasProvider({
    hideOffCanvas,
    addSelectedItemToStack,
    removeItemFromStack,
    isOffCanvasVisible,
    children,
    offCanvasLevelDepth,
}: OffCanvasContextType) {
    const providerValue = useMemo(
        () => ({
            hideOffCanvas,
            addSelectedItemToStack,
            isOffCanvasVisible,
            removeItemFromStack,
            offCanvasLevelDepth,
        }),
        [addSelectedItemToStack, hideOffCanvas, isOffCanvasVisible, removeItemFromStack, offCanvasLevelDepth]
    );
    return <OffCanvasContext.Provider value={providerValue}>{children}</OffCanvasContext.Provider>;
}

export const useHideOffCanvas = () => {
    const context = useContext(OffCanvasContext);
    if (context === undefined) {
        throw new Error('"useHideOffCanvas" can only be used inside OffCanVasProvider');
    }
    return context.hideOffCanvas;
};

export const useOffCanvasLevelDepth = () => {
    const context = useContext(OffCanvasContext);
    if (context === undefined) {
        throw new Error('"useOffCanvasLevelDepth" can only be used inside OffCanVasProvider');
    }
    return context.offCanvasLevelDepth;
};

export const useAddSelectedItemToStack = () => {
    const context = useContext(OffCanvasContext);
    if (context === undefined) {
        throw new Error('"useAddSelectedItemToStack" can only be used inside OffCanVasProvider');
    }
    return context.addSelectedItemToStack;
};

export const useRemoveItemFromStack = () => {
    const context = useContext(OffCanvasContext);
    if (context === undefined) {
        throw new Error('"useRemoveItemFromStack" can only be used inside OffCanVasProvider');
    }
    return context.removeItemFromStack;
};

export const useIsOffCanvasVisible = () => {
    const context = useContext(OffCanvasContext);
    if (context === undefined) {
        throw new Error('"useIsOffCanvasVisible" can only be used inside OffCanVasProvider');
    }
    return context.isOffCanvasVisible;
};
