import 'react-app-polyfill/ie11'; // IE 11 compatibility
import 'react-app-polyfill/stable'; // IE 11 compatibility
import 'fast-text-encoding/text.min'; // IE 11 compatibility
import 'core-js/features/array/find'; // IE 11 compatibility
import 'core-js/features/array/includes'; // IE 11 compatibility
import 'core-js/features/number/is-nan'; // IE 11 compatibility
import { datadogRum } from '@datadog/browser-rum';
import { StyleProvider, theme } from '@dm/design-system';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import appInfo from './gen/AppInfo';
import App from './app/App';
import NotificationProvider from './notification/NotificationProvider';
import { SessionProvider } from './session/SessionProvider';
import './i18n';
import { TermsProvider } from './content/components/termsPage/TermsProvider';
import { TrackingProvider } from './app/components/tracking/TrackingProvider';
import { StoryProvider } from './content/StoryProvider';
import { LoginSuccessProvider } from './session/LoginSuccessProvider';

datadogRum.init({
    applicationId: 'be5f78fb-1c68-4722-ad33-03e05d968ed7',
    clientToken: 'puba523c2a9ebb375aa7261bf033bc80a34',
    site: 'datadoghq.eu',
    service: 'partnerportal',
    env: window.location.hostname === 'dm-partnerportal.com' ? 'prod' : window.location.hostname,
    version: appInfo.fullVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    // allowedTracingUrls must match full url including potential query paths
    allowedTracingUrls: [new RegExp(`https://${window.location.hostname}/.*`)],
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event, context) => {
        const redactEmail = (url: string): string => {
            return url.replace(/email=[^&]*/, 'email=REDACTED');
        };

        // eslint-disable-next-line no-param-reassign
        event.view.url = redactEmail(event.view.url);
        if (event.view.referrer) {
            // eslint-disable-next-line no-param-reassign
            event.view.referrer = redactEmail(event.view.referrer);
        }

        return true;
    },
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const container: HTMLElement = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <StyleProvider theme={theme}>
                <SessionProvider>
                    <LoginSuccessProvider>
                        <TrackingProvider>
                            <StoryProvider>
                                <TermsProvider>
                                    <NotificationProvider>
                                        <App />
                                    </NotificationProvider>
                                </TermsProvider>
                            </StoryProvider>
                        </TrackingProvider>
                    </LoginSuccessProvider>
                </SessionProvider>
            </StyleProvider>
        </BrowserRouter>
    </React.StrictMode>
);
