import Collapsible from './components/Collapsible';
import Download from './components/Download';
import DwhLink from './components/DwhLink';
import ConfirmUser from './components/idm/confirmUser/ConfirmUser';
import ConfirmAccount from './components/idm/confirmAccount/ConfirmAccount';
import PasswordForgotten from './components/idm/passwordForgotten/PasswordForgotten';
import ResetPassword from './components/idm/passwordReset/ResetPassword';
import Tile from './components/Tile';
import WelcomePageButtons from './components/landingPage/WelcomePageButtons';
import Page from './components/Page';
import MaintenancePage from './components/maintenance/MaintenancePage';
import { ComponentMap } from './StoryRenderer';
import Application from '../features/Applications/Application';
import Newsletter from './components/newsletter/Newsletter';
import NoticeBlock from './components/NoticeBlock';
import YoutubeEmbeddedPlayer from './components/YoutubeEmbeddedPlayer';
import Video from './components/Video';
import TileSection from './components/TileSection';
import RichTextBlok from './components/RichTextBlok';
import UserProfilePageCard from '../features/UserProfile/components/UserProfilePageCard';
import MyColleaguesPage from '../features/UserProfile/MyColleaguesPage';
import MySupplierContactsPage from '../features/UserProfile/MySupplierContactsPage';
import MyUserDataPage from '../features/UserProfile/MyUserDataPage';
import UserProfilePage from '../features/UserProfile/UserProfilePage';
import EmbeddedApplication from './components/EmbeddedApplication';

const UserPageComponentList = {
    UserProfilePage,
    UserProfilePageCard,
    MyUserDataPage,
    MyColleaguesPage,
    MySupplierContactsPage,
};

export default {
    Page,
    MaintenancePage,
    PasswordForgotten,
    ResetPassword,
    ConfirmUser,
    ConfirmAccount,
    WelcomePageButtons,
    Download,
    Application,
    Newsletter,
    Collapsible,
    DwhLink,
    Tile,
    NoticeBlock,
    YoutubeEmbeddedPlayer,
    Video,
    TileSection,
    RichTextBlok,
    EmbeddedApplication,
    ...UserPageComponentList,
} as ComponentMap;
