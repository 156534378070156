import React, { createContext, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Banner } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import StoryRenderer from '../../content/StoryRenderer';
import { applicationApi, termsApi } from '../../apiConfig';
import getTenant from '../../content/getTenant';
import ApplicationsLoadingPage from './components/ApplicationsLoadingPage';
import { ApplicationStates } from './types';

export type ApplicationContext = {
    applicationStates: ApplicationStates;
    reloadApplicationContext: () => void;
    expandApplication: (type: string) => void;
    changeApplicationOrderState: (applicationType: string, orderState: string) => void;
};

export const ApplicationsContext = createContext<ApplicationContext>({
    applicationStates: {},
    expandApplication: () => {
        // implement empty initial function
    },
    reloadApplicationContext: () => {
        // implement empty initial function
    },
    changeApplicationOrderState: () => {
        // implement empty initial function
    },
});

export default function Applications(): ReactElement {
    const { t } = useTranslation();
    const [applicationStates, setApplicationStates] = useState<ApplicationStates>({});
    const [hasLoadingError, setHasLoadingError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const tenant = getTenant();
    const reloadApplicationContext = useCallback(() => {
        setIsLoading(true);
        const updatedApplicationStates: ApplicationStates = {};

        Promise.all([
            applicationApi.getOrderStates().then((orderStates) => {
                Object.keys(orderStates).forEach((applicationType) => {
                    updatedApplicationStates[applicationType] = updatedApplicationStates[applicationType] || {};
                    updatedApplicationStates[applicationType].orderState = orderStates[applicationType];
                    updatedApplicationStates[applicationType].expanded = false;
                });
            }),
            termsApi.getTerms({ tenant, showAllTerms: true }).then((termsResponses) => {
                termsResponses.forEach((termsResponse) => {
                    const applicationType = termsResponse.application;
                    updatedApplicationStates[applicationType] = updatedApplicationStates[applicationType] || {};
                    updatedApplicationStates[applicationType].terms = termsResponse;
                });
            }),
        ])
            .then(() => {
                setHasLoadingError(false);
            })
            .catch(() => {
                setHasLoadingError(true);
            })
            .finally(() => {
                setIsLoading(false);
                setApplicationStates(updatedApplicationStates);
            });
    }, [tenant]);

    useEffect(() => {
        reloadApplicationContext();
    }, [reloadApplicationContext]);

    const expandApplication = useCallback(
        (applicationType: string) => {
            const newApplicationStates: ApplicationStates = { ...applicationStates };

            Object.keys(applicationStates).forEach((key) => {
                newApplicationStates[key].expanded = false;
            });
            newApplicationStates[applicationType].expanded = true;

            setApplicationStates(newApplicationStates);
        },
        [applicationStates]
    );

    const changeApplicationOrderState = useCallback(
        (applicationType: string, orderState: string) => {
            const newApplicationStates: ApplicationStates = { ...applicationStates };

            Object.keys(applicationStates).forEach((key) => {
                if (key === applicationType) {
                    newApplicationStates[key].orderState = orderState;
                }
            });

            setApplicationStates(newApplicationStates);
        },
        [applicationStates]
    );

    const applicationContext = useMemo<ApplicationContext>(
        () => ({
            reloadApplicationContext,
            expandApplication,
            applicationStates,
            changeApplicationOrderState,
        }),
        [reloadApplicationContext, expandApplication, applicationStates, changeApplicationOrderState]
    );

    const isApplicationStatesEmpty = Object.keys(applicationContext.applicationStates).length === 0;
    const isInitiallyOpeningApplicationsManagement = isLoading && isApplicationStatesEmpty;

    if (isInitiallyOpeningApplicationsManagement) {
        return <ApplicationsLoadingPage />;
    }

    return (
        <>
            {hasLoadingError && (
                <Banner dmId="apps-loading-status" kind="error">
                    {t('error.application.loadingdata')}
                </Banner>
            )}
            <ApplicationsContext.Provider value={applicationContext}>
                <StoryRenderer />
            </ApplicationsContext.Provider>
        </>
    );
}
