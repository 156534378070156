/* eslint-disable no-underscore-dangle */
import React, { ReactElement } from 'react';
import BlokRenderer from '../BlokRenderer';
import { RichTextComponentProps } from './render';

export default function BlokNode({
    attrs: { body = [] } = { body: [] },
}: Readonly<RichTextComponentProps>): ReactElement<RichTextComponentProps> {
    return <BlokRenderer bloks={body} />;
}
