import { Banner, Spacer, ThemeProps, useFela } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TermsResponse } from '../../../gen/ppapiclient';
import { Asset } from '../../StoryblokFieldTypes';
import TrackedLink from '../../../app/components/tracking/TrackedLink';

export interface TermsConfirmBlockProps {
    terms?: TermsResponse;
    introText: string;
    showIntroText: boolean;
}

export default function TermsConfirmBlock({
    terms,
    introText,
    showIntroText,
}: Readonly<TermsConfirmBlockProps>): ReactElement {
    const { t } = useTranslation();
    const { css } = useFela<ThemeProps>();

    if (!terms) {
        return <></>;
    }

    return (
        <>
            <Banner kind="neutral" dmId="termBlock">
                {showIntroText && <>{introText}&nbsp;</>}
                <TrackedLink
                    to={(terms.asset as Asset).filename}
                    trackedType="download"
                    className={css({ fontWeight: 'bold' })}
                    key={0}
                    isExternalLink
                    openInNewTab
                >
                    <>
                        &quot;
                        {t('terms.confirm.link', {
                            title: terms.title,
                            version: terms.version,
                        })}
                        &quot;
                    </>
                </TrackedLink>
            </Banner>
            <Spacer size={12} />
        </>
    );
}
