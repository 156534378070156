import {
    AustriaFlagIcon,
    BosniaFlagIcon,
    Box,
    BulgariaFlagIcon,
    CroatiaFlagIcon,
    CzechFlagIcon,
    GermanyFlagIcon,
    HungaryFlagIcon,
    ItalyFlagIcon,
    Link,
    RomaniaFlagIcon,
    SerbiaFlagIcon,
    SlovakiaFlagIcon,
    SloveniaFlagIcon,
    theme,
} from '@dm/design-system';

import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import countryCodes from '../../content/components/idm/countryCodes.json';
import { Tenant } from '../../gen/ppapiclient';
import FlyoutWidget from './Flyout/FlyoutWidget';
import getTenant from '../../content/getTenant';

const tenantOptionStyle = {
    color: theme.palette.headline.hex,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
};
const tenantTextStyle = { paddingLeft: theme.dimension.spacing.xxs.rem };

function FlagIcon({ tenant }: Readonly<{ tenant: Tenant }>) {
    switch (tenant) {
        case Tenant.AT:
            return <AustriaFlagIcon />;
        case Tenant.BA:
            return <BosniaFlagIcon />;
        case Tenant.BG:
            return <BulgariaFlagIcon />;
        case Tenant.CZ:
            return <CzechFlagIcon />;
        case Tenant.DE:
            return <GermanyFlagIcon />;
        case Tenant.HR:
            return <CroatiaFlagIcon />;
        case Tenant.HU:
            return <HungaryFlagIcon />;
        case Tenant.IT:
            return <ItalyFlagIcon />;
        case Tenant.RO:
            return <RomaniaFlagIcon />;
        case Tenant.RS:
            return <SerbiaFlagIcon />;
        case Tenant.SI:
            return <SloveniaFlagIcon />;
        case Tenant.SK:
            return <SlovakiaFlagIcon />;
        default:
            // Fallback: just show tenant (e.g. "DE") instead of a flag
            return <div>{tenant}</div>;
    }
}

export default function TenantSelect(): ReactElement {
    const { pathname } = useLocation();

    const pathWithoutTenant = pathname.substring(3) || '/';

    const tenantOptions = Object.entries(Tenant)
        .map(([tenant]) => tenant)
        .map((tenant) => {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <Link
                    as="a"
                    href={`/${tenant.toLowerCase()}${pathWithoutTenant}`}
                    style={tenantOptionStyle}
                    key={tenant}
                    dmId={`tenant-option-${tenant}`}
                >
                    <span>
                        <FlagIcon tenant={Tenant[tenant]} />
                    </span>
                    <span style={tenantTextStyle}>{countryCodes[tenant]}</span>
                </Link>
            );
        });

    return (
        <Box dmId="tenant-select">
            <FlyoutWidget
                icon={<FlagIcon tenant={getTenant()} />}
                label="select tenant"
                elements={tenantOptions}
                dmId="tenant"
            />
        </Box>
    );
}
