import React, { createContext, ReactElement, ReactNode, useContext } from 'react';
import useAuth, { UseAuthProps } from './useAuth';

export interface SessionContext extends UseAuthProps {
    refreshSession: () => void;
}

export const Context = createContext<SessionContext>({
    authentication: 'pending',
    userInfo: null,
    refreshSession: () => {
        // do nothing
    },
});
Context.displayName = 'SessionContext';

export interface SessionProviderProps {
    children: ReactNode;
    initialSession?: UseAuthProps;
}

export function SessionProvider({
    children,
    initialSession = undefined,
}: Readonly<SessionProviderProps>): ReactElement {
    const session = useAuth(initialSession);
    return <Context.Provider value={session}>{children}</Context.Provider>;
}

export const useSession = (): SessionContext => useContext(Context);
