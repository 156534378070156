/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RecaptchaConfigDTO,
    RecaptchaConfigDTOFromJSON,
    RecaptchaConfigDTOToJSON,
    UserMenuConfigDTO,
    UserMenuConfigDTOFromJSON,
    UserMenuConfigDTOToJSON,
    ValidationErrorResponse,
    ValidationErrorResponseFromJSON,
    ValidationErrorResponseToJSON,
} from '../models';

export interface GetAvailableUserMenuStoriesRequest {
    listOfFullSlugs: Array<string>;
}

/**
 * ConfigApi - interface
 * 
 * @export
 * @interface ConfigApiInterface
 */
export interface ConfigApiInterface {
    /**
     * 
     * @summary filter transferred slugs for published stories
     * @param {Array<string>} listOfFullSlugs Full slug of the story starting with the tenant, e.g. de/edi-bei-dm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApiInterface
     */
    getAvailableUserMenuStoriesRaw(requestParameters: GetAvailableUserMenuStoriesRequest): Promise<runtime.ApiResponse<UserMenuConfigDTO>>;

    /**
     * filter transferred slugs for published stories
     */
    getAvailableUserMenuStories(requestParameters: GetAvailableUserMenuStoriesRequest): Promise<UserMenuConfigDTO>;

    /**
     * 
     * @summary get configuration for recaptcha
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApiInterface
     */
    getRecaptchaConfigRaw(): Promise<runtime.ApiResponse<RecaptchaConfigDTO>>;

    /**
     * get configuration for recaptcha
     */
    getRecaptchaConfig(): Promise<RecaptchaConfigDTO>;

}

/**
 * 
 */
export class ConfigApi extends runtime.BaseAPI implements ConfigApiInterface {

    /**
     * filter transferred slugs for published stories
     */
    async getAvailableUserMenuStoriesRaw(requestParameters: GetAvailableUserMenuStoriesRequest): Promise<runtime.ApiResponse<UserMenuConfigDTO>> {
        if (requestParameters.listOfFullSlugs === null || requestParameters.listOfFullSlugs === undefined) {
            throw new runtime.RequiredError('listOfFullSlugs','Required parameter requestParameters.listOfFullSlugs was null or undefined when calling getAvailableUserMenuStories.');
        }

        const queryParameters: any = {};

        if (requestParameters.listOfFullSlugs) {
            queryParameters['listOfFullSlugs'] = requestParameters.listOfFullSlugs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/config/usermenu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMenuConfigDTOFromJSON(jsonValue));
    }

    /**
     * filter transferred slugs for published stories
     */
    async getAvailableUserMenuStories(requestParameters: GetAvailableUserMenuStoriesRequest): Promise<UserMenuConfigDTO> {
        const response = await this.getAvailableUserMenuStoriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * get configuration for recaptcha
     */
    async getRecaptchaConfigRaw(): Promise<runtime.ApiResponse<RecaptchaConfigDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/config/recaptcha`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RecaptchaConfigDTOFromJSON(jsonValue));
    }

    /**
     * get configuration for recaptcha
     */
    async getRecaptchaConfig(): Promise<RecaptchaConfigDTO> {
        const response = await this.getRecaptchaConfigRaw();
        return await response.value();
    }

}
