import { Tenant } from '../gen/ppapiclient';

const getTenant = () => {
    const maybeTenant = window.location.pathname.substring(1, 3).toUpperCase();

    return maybeTenant in Tenant ? Tenant[maybeTenant as Tenant] : Tenant.DE;
};

document.documentElement.lang = getTenant().toLowerCase();

export const supportedLanguagesForTenants = {
    AT: 'de-AT',
    BA: 'bs-BA',
    BG: 'bg-BG',
    CZ: 'cs-CZ',
    DE: 'de-DE',
    HR: 'hr-HR',
    HU: 'hu-HU',
    IT: 'it-IT',
    RO: 'ro-RO',
    RS: 'sr-RS',
    SI: 'sl-SI',
    SK: 'sk-SK',
};

export default getTenant;
