import { Box, FelaStyle, Loading, ThemeProps } from '@dm/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TileProps } from '../../../content/components/Tile';

const loadingStateStyle: FelaStyle<ThemeProps, TileProps> = () => ({
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
});

export default function ApplicationsLoadingPage() {
    const { t } = useTranslation();
    const loadingTitle = t('application.loading-spinner');

    return (
        <Box style={loadingStateStyle}>
            <Loading dmId="loading applications" title={loadingTitle} />
        </Box>
    );
}
