import React, { ReactElement } from 'react';
import { Box, Spacer, theme, ThemeProps, useBreakpoint, useFela, View } from '@dm/design-system';
import { appStyle, mainStyle, rowStyle } from '../../../app/App';
import { PartnerportalIcon } from '../../../app/components/Header';
import StoryRenderer from '../../StoryRenderer';
import TenantSelect from '../../../app/components/TenantSelect';

export default function MaintenanceContainer(): ReactElement {
    const { css } = useFela<ThemeProps>();
    const isDesktop = useBreakpoint(theme.breakpoints.horizontal.m.query);

    const header = (
        <Box>
            <Spacer size={theme.dimension.spacing.xxs.rem} />
            <View align="left" noPadding>
                <Box
                    style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: theme.dimension.spacing.xs.rem,
                        paddingRight: theme.dimension.spacing.xs.rem,
                    }}
                >
                    <PartnerportalIcon isDesktop={isDesktop} />

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <TenantSelect />
                    </div>
                </Box>
            </View>

            <Spacer size={theme.dimension.spacing.xs.rem} />
        </Box>
    );

    return (
        <div className={css(appStyle)}>
            <header>{header}</header>
            <Box
                style={{
                    flex: '1',
                    flexBasis: 'auto', // IE11 needs flexBasis
                    minHeight: '50vh',
                }}
            >
                <View noPadding>
                    <Box style={rowStyle}>
                        <main className={css(mainStyle)}>
                            <StoryRenderer />
                        </main>
                    </Box>
                </View>
            </Box>
        </div>
    );
}
