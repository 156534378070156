/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    NewsletterSubscribeDTO,
    NewsletterSubscribeDTOFromJSON,
    NewsletterSubscribeDTOToJSON,
} from '../models';

export interface ConfirmSubscribeToNewsletterChannelRequest {
    token: string;
    channel: string;
}

export interface SubscribeToNewsletterChannelRequest {
    newsletterSubscribeDTO: NewsletterSubscribeDTO;
}

export interface UnsubscribeToNewsletterChannelRequest {
    newsletterSubscribeDTO: NewsletterSubscribeDTO;
}

export interface UnsubscribeToNewsletterChannelAnonymousRequest {
    token: string;
    channel: string;
}

/**
 * NewsletterApi - interface
 * 
 * @export
 * @interface NewsletterApiInterface
 */
export interface NewsletterApiInterface {
    /**
     * 
     * @summary subscribe a new newsletter channel
     * @param {string} token 
     * @param {string} channel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApiInterface
     */
    confirmSubscribeToNewsletterChannelRaw(requestParameters: ConfirmSubscribeToNewsletterChannelRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * subscribe a new newsletter channel
     */
    confirmSubscribeToNewsletterChannel(requestParameters: ConfirmSubscribeToNewsletterChannelRequest): Promise<void>;

    /**
     * 
     * @summary returns the subscribe newsletter channels the user is authorized
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApiInterface
     */
    getSubscribesNewsletterRaw(): Promise<runtime.ApiResponse<Array<string>>>;

    /**
     * returns the subscribe newsletter channels the user is authorized
     */
    getSubscribesNewsletter(): Promise<Array<string>>;

    /**
     * 
     * @summary subscribe a new newsletter channel
     * @param {NewsletterSubscribeDTO} newsletterSubscribeDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApiInterface
     */
    subscribeToNewsletterChannelRaw(requestParameters: SubscribeToNewsletterChannelRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * subscribe a new newsletter channel
     */
    subscribeToNewsletterChannel(requestParameters: SubscribeToNewsletterChannelRequest): Promise<void>;

    /**
     * 
     * @summary unsubscribe a new newsletter channel
     * @param {NewsletterSubscribeDTO} newsletterSubscribeDTO 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApiInterface
     */
    unsubscribeToNewsletterChannelRaw(requestParameters: UnsubscribeToNewsletterChannelRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * unsubscribe a new newsletter channel
     */
    unsubscribeToNewsletterChannel(requestParameters: UnsubscribeToNewsletterChannelRequest): Promise<void>;

    /**
     * 
     * @summary unsubscribe a new newsletter channel
     * @param {string} token 
     * @param {string} channel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApiInterface
     */
    unsubscribeToNewsletterChannelAnonymousRaw(requestParameters: UnsubscribeToNewsletterChannelAnonymousRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * unsubscribe a new newsletter channel
     */
    unsubscribeToNewsletterChannelAnonymous(requestParameters: UnsubscribeToNewsletterChannelAnonymousRequest): Promise<void>;

}

/**
 * 
 */
export class NewsletterApi extends runtime.BaseAPI implements NewsletterApiInterface {

    /**
     * subscribe a new newsletter channel
     */
    async confirmSubscribeToNewsletterChannelRaw(requestParameters: ConfirmSubscribeToNewsletterChannelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling confirmSubscribeToNewsletterChannel.');
        }

        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling confirmSubscribeToNewsletterChannel.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.channel !== undefined) {
            queryParameters['channel'] = requestParameters.channel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/idm/newsletter/confirm-subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * subscribe a new newsletter channel
     */
    async confirmSubscribeToNewsletterChannel(requestParameters: ConfirmSubscribeToNewsletterChannelRequest): Promise<void> {
        await this.confirmSubscribeToNewsletterChannelRaw(requestParameters);
    }

    /**
     * returns the subscribe newsletter channels the user is authorized
     */
    async getSubscribesNewsletterRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/idm/newsletter/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * returns the subscribe newsletter channels the user is authorized
     */
    async getSubscribesNewsletter(): Promise<Array<string>> {
        const response = await this.getSubscribesNewsletterRaw();
        return await response.value();
    }

    /**
     * subscribe a new newsletter channel
     */
    async subscribeToNewsletterChannelRaw(requestParameters: SubscribeToNewsletterChannelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.newsletterSubscribeDTO === null || requestParameters.newsletterSubscribeDTO === undefined) {
            throw new runtime.RequiredError('newsletterSubscribeDTO','Required parameter requestParameters.newsletterSubscribeDTO was null or undefined when calling subscribeToNewsletterChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/idm/newsletter/subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewsletterSubscribeDTOToJSON(requestParameters.newsletterSubscribeDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * subscribe a new newsletter channel
     */
    async subscribeToNewsletterChannel(requestParameters: SubscribeToNewsletterChannelRequest): Promise<void> {
        await this.subscribeToNewsletterChannelRaw(requestParameters);
    }

    /**
     * unsubscribe a new newsletter channel
     */
    async unsubscribeToNewsletterChannelRaw(requestParameters: UnsubscribeToNewsletterChannelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.newsletterSubscribeDTO === null || requestParameters.newsletterSubscribeDTO === undefined) {
            throw new runtime.RequiredError('newsletterSubscribeDTO','Required parameter requestParameters.newsletterSubscribeDTO was null or undefined when calling unsubscribeToNewsletterChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/idm/newsletter/unsubscribe`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NewsletterSubscribeDTOToJSON(requestParameters.newsletterSubscribeDTO),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * unsubscribe a new newsletter channel
     */
    async unsubscribeToNewsletterChannel(requestParameters: UnsubscribeToNewsletterChannelRequest): Promise<void> {
        await this.unsubscribeToNewsletterChannelRaw(requestParameters);
    }

    /**
     * unsubscribe a new newsletter channel
     */
    async unsubscribeToNewsletterChannelAnonymousRaw(requestParameters: UnsubscribeToNewsletterChannelAnonymousRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling unsubscribeToNewsletterChannelAnonymous.');
        }

        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling unsubscribeToNewsletterChannelAnonymous.');
        }

        const queryParameters: any = {};

        if (requestParameters.token !== undefined) {
            queryParameters['token'] = requestParameters.token;
        }

        if (requestParameters.channel !== undefined) {
            queryParameters['channel'] = requestParameters.channel;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/idm/newsletter/unsubscribe-anonymous`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * unsubscribe a new newsletter channel
     */
    async unsubscribeToNewsletterChannelAnonymous(requestParameters: UnsubscribeToNewsletterChannelAnonymousRequest): Promise<void> {
        await this.unsubscribeToNewsletterChannelAnonymousRaw(requestParameters);
    }

}
