/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Content,
    ContentFromJSON,
    ContentFromJSONTyped,
    ContentToJSON,
} from './';

/**
 * Contains general information about a story and where to find it
 * @export
 * @interface ContentTreeItem
 */
export interface ContentTreeItem {
    /**
     * 
     * @type {Content}
     * @memberof ContentTreeItem
     */
    content: Content;
    /**
     * full slug
     * @type {string}
     * @memberof ContentTreeItem
     */
    fullSlug: string;
    /**
     * name (for navigation)
     * @type {string}
     * @memberof ContentTreeItem
     */
    name: string;
    /**
     * the universal unique id of the page - can be used to uniquely identify the story
     * @type {string}
     * @memberof ContentTreeItem
     */
    uuid: string;
    /**
     * 
     * @type {Array<ContentTreeItem>}
     * @memberof ContentTreeItem
     */
    children?: Array<ContentTreeItem>;
}

export function ContentTreeItemFromJSON(json: any): ContentTreeItem {
    return ContentTreeItemFromJSONTyped(json, false);
}

export function ContentTreeItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentTreeItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': ContentFromJSON(json['content']),
        'fullSlug': json['full_slug'],
        'name': json['name'],
        'uuid': json['uuid'],
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(ContentTreeItemFromJSON)),
    };
}

export function ContentTreeItemToJSON(value?: ContentTreeItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': ContentToJSON(value.content),
        'full_slug': value.fullSlug,
        'name': value.name,
        'uuid': value.uuid,
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(ContentTreeItemToJSON)),
    };
}


