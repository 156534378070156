import React, { createContext, ReactElement, useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginSuccessApi } from '../apiConfig';
import { useSession } from './SessionProvider';
import getTenant from '../content/getTenant';

export interface LoginSuccessContext {
    isLoginSuccessRequest: boolean;
}

export const Context = createContext<LoginSuccessContext>({
    isLoginSuccessRequest: true,
});
Context.displayName = 'LoginSuccessContext';

export function LoginSuccessProvider({ children }): ReactElement {
    const { authentication } = useSession();
    const location = useLocation();
    const navigate = useNavigate();

    const context: LoginSuccessContext = useMemo(
        () => ({
            isLoginSuccessRequest: location.pathname.includes('/login-success'),
        }),
        [location.pathname]
    );

    useEffect(() => {
        const loginErrorPage = `${getTenant().toLowerCase()}/login-error`;
        if (!context.isLoginSuccessRequest) {
            // do nothing
        } else if (authentication === 'failed' || authentication === 'unauthorized') {
            navigate(loginErrorPage);
        } else if (authentication === 'success') {
            loginSuccessApi
                .loginSuccess()
                .then((response) => {
                    if (response.redirectPath) {
                        navigate(response.redirectPath);
                    }
                })
                .catch(() => {
                    navigate(loginErrorPage);
                });
        }
    }, [authentication, context.isLoginSuccessRequest, navigate]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
}

export const useLoginSuccess = (): LoginSuccessContext => useContext(Context);
