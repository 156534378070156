import { CrossCircleIcon, Picture, Text, theme, ThemeProps, useFela } from '@dm/design-system';
import React, { ReactElement, useCallback, useState } from 'react';
import { buildPreviewImageUrl } from '../../StoryblokPreviewProvider';
import { RichTextComponentProps, RichtextMarkAttrs } from '../render';
import ImageAsLink from './ImageAsLink';

const errorContainerStyle = {
    display: 'flex',
};

const verticalCenterStyle = {
    margin: 'auto 0',
    paddingLeft: '5px',
};

const imageStyle = {
    image: {
        maxWidth: '100%',
        display: 'block',
        borderRadius: '8px',
    },
};

// FIX THAT we should test the onerror = true case FIX WHEN we know how to test it
export default function Image({
    attrs: { alt = '', src = '' } = {},
    marks,
}: Readonly<RichTextComponentProps>): ReactElement<RichTextComponentProps> {
    const [hasError, setHasError] = useState(false);
    const { css } = useFela<ThemeProps>();

    const imageUrlWithPreviewParams = buildPreviewImageUrl(src);
    const sources = [
        {
            src: appendWidth(imageUrlWithPreviewParams, 1024),
            media: theme.breakpoints.horizontal.m.query,
        },
        {
            src: appendWidth(imageUrlWithPreviewParams, 550),
            media: theme.breakpoints.horizontal.xs.query,
        },
        {
            src: appendWidth(imageUrlWithPreviewParams, 250),
            media: '(max-width: 480px)',
        },
    ];

    const findImageLink = useCallback((): RichtextMarkAttrs | undefined => {
        const linkMark = marks?.find((mark) => mark.type === 'link');

        if (!linkMark?.attrs?.href) {
            return undefined;
        }

        return {
            ...linkMark.attrs,
        };
    }, [marks]);

    const imageLinkMark = findImageLink();

    const PictureComponent: JSX.Element = (
        <>
            <Picture
                data-dmid="render-image"
                styles={imageStyle}
                src={imageUrlWithPreviewParams.toString()}
                alt={alt}
                sources={sources}
                {...{
                    onError: () => {
                        setHasError(true);
                    },
                }}
            />
            {hasError && (
                <span className={css(errorContainerStyle)}>
                    <CrossCircleIcon color="red" style={{ height: 32 }} />
                    <Text className={css(verticalCenterStyle)}>{`missing image ${alt}`}</Text>
                </span>
            )}
        </>
    );

    function appendWidth(imageUrl: URL, width: number) {
        const imageUrlWithWidth = new URL(imageUrl);
        imageUrlWithWidth.searchParams.append('w', String(width));
        return imageUrlWithWidth.toString();
    }

    return imageLinkMark ? <ImageAsLink imageAsLinkProps={imageLinkMark} image={PictureComponent} /> : PictureComponent;
}
