import BlokNode from './BlokNode';
import Blockquote from './components/Blockquote';
import BulletList from './components/BulletList';
import CodeBlock from './components/CodeBlock';
import HardBreak from './components/HardBreak';
import Heading from './components/Heading';
import HorizontalRule from './components/HorizontalRule';
import Image from './components/Image';
import { ExternalLink, InternalLink } from './components/Link';
import ListItem from './components/ListItem';
import OrderedList from './components/OrderedList';
import Paragraph from './components/Paragraph';
import { ComponentMap } from './render';
import { LinkMap } from './TextNode';

export const linkMap: LinkMap = {
    internal: InternalLink,
    external: ExternalLink,
};

export const componentMap: ComponentMap = {
    blok: BlokNode,
    code_block: CodeBlock,
    heading: Heading,
    image: Image,
    blockquote: Blockquote,
    hard_break: HardBreak,
    horizontal_rule: HorizontalRule,
    bullet_list: BulletList,
    list_item: ListItem,
    ordered_list: OrderedList,
    paragraph: Paragraph,
};
