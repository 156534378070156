/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {Array<string>}
     * @memberof Supplier
     */
    categories?: Array<SupplierCategoriesEnum>;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    supplierNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    tenantId?: string;
}

/**
* @export
* @enum {string}
*/
export enum SupplierCategoriesEnum {
    TEST = 'TEST',
    PERFUME = 'PERFUME',
    MAWI = 'MAWI',
    BABY = 'BABY',
    COSMETICS = 'COSMETICS',
    BARBER = 'BARBER'
}

export function SupplierFromJSON(json: any): Supplier {
    return SupplierFromJSONTyped(json, false);
}

export function SupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): Supplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'supplierNumber': !exists(json, 'supplierNumber') ? undefined : json['supplierNumber'],
        'tenantId': !exists(json, 'tenantId') ? undefined : json['tenantId'],
    };
}

export function SupplierToJSON(value?: Supplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': value.categories,
        'name': value.name,
        'supplierNumber': value.supplierNumber,
        'tenantId': value.tenantId,
    };
}


