import React, { Fragment, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AngleRightIcon, Box, HouseIcon, theme as themes, ThemeProps, useBreakpoint, useFela } from '@dm/design-system';
import {
    determineNavigationBreadcrumbs,
    findNavigationItemInContentTreeById,
} from '../../../navigation/NavigationUtil';
import { ContentTreeItem } from '../../../gen/ppapiclient';
import TrackedLink from '../tracking/TrackedLink';
import { useContentTree } from '../../../content/StoryProvider';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const breadcrumbLinkStyle = ({ theme }: { theme: ThemeProps }) => ({
    color: theme.palette.text.hex,
    ':hover': {
        color: theme.palette.color2.hex,
    },
    ':first-child': {
        marginLeft: 0,
    },
    ':last-child': {
        marginRight: 0,
    },
});

export default function Breadcrumb(): ReactElement {
    const { css } = useFela<ThemeProps>();
    const [breadcrumbs, setBreadcrumbs] = useState<Array<ContentTreeItem>>([]);
    const { navigation } = useContentTree();
    const { pathname } = useLocation();
    const isDesktop = useBreakpoint(themes.breakpoints.horizontal.m.query);

    useEffect(() => {
        const result: ContentTreeItem[] = [];
        determineNavigationBreadcrumbs(navigation, pathname)?.forEach((itemId, index) => {
            const contentTreeItem = findNavigationItemInContentTreeById(itemId, navigation)?.item;
            if (contentTreeItem) {
                result.push(contentTreeItem);
            }
        });
        setBreadcrumbs(result);
    }, [navigation, pathname]);

    if (isDesktop || breadcrumbs.length === 0) {
        return <></>;
    }

    const breadcrumbElement = (item: ContentTreeItem, lastElement: boolean) => {
        return lastElement ? (
            <Fragment key={item.uuid}>
                &nbsp;
                <AngleRightIcon width={9} height="100%" color={themes.palette.color3.hex} />
                &nbsp;
                <span key={item.uuid}>{item.name}</span>
            </Fragment>
        ) : (
            <Fragment key={item.uuid}>
                &nbsp;
                <AngleRightIcon width={9} height="100%" color={themes.palette.color3.hex} />
                &nbsp;
                <TrackedLink
                    to={`/${item.fullSlug}`}
                    key={item.uuid}
                    className={css(breadcrumbLinkStyle)}
                    trackedType="breadcrumb"
                >
                    <>{item.name}</>
                </TrackedLink>
            </Fragment>
        );
    };

    return (
        <Box dmId="breadcrumb-container" className={css({ border: 'flex', padding: '0px 1.25rem', color: '#525260' })}>
            <TrackedLink to="/" key="0" className={css(breadcrumbLinkStyle)} trackedType="breadcrumb">
                <HouseIcon width={14} height="100%" color={themes.palette.color2.hex} />
            </TrackedLink>
            {breadcrumbs.map((item, index) => breadcrumbElement(item, index === breadcrumbs.length - 1))}
        </Box>
    );
}
