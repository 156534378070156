/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OptionalUserInfo,
    OptionalUserInfoFromJSON,
    OptionalUserInfoToJSON,
} from '../models';

/**
 * AuthenticationApi - interface
 * 
 * @export
 * @interface AuthenticationApiInterface
 */
export interface AuthenticationApiInterface {
    /**
     * will return null if no user is logged in
     * @summary get details about the logged in user (if a user is logged in)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiInterface
     */
    whoamiRaw(): Promise<runtime.ApiResponse<OptionalUserInfo>>;

    /**
     * will return null if no user is logged in
     * get details about the logged in user (if a user is logged in)
     */
    whoami(): Promise<OptionalUserInfo>;

}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI implements AuthenticationApiInterface {

    /**
     * will return null if no user is logged in
     * get details about the logged in user (if a user is logged in)
     */
    async whoamiRaw(): Promise<runtime.ApiResponse<OptionalUserInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/auth/whoami`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionalUserInfoFromJSON(jsonValue));
    }

    /**
     * will return null if no user is logged in
     * get details about the logged in user (if a user is logged in)
     */
    async whoami(): Promise<OptionalUserInfo> {
        const response = await this.whoamiRaw();
        return await response.value();
    }

}
