/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContentTrees,
    ContentTreesFromJSON,
    ContentTreesToJSON,
    Labels,
    LabelsFromJSON,
    LabelsToJSON,
    SearchResponse,
    SearchResponseFromJSON,
    SearchResponseToJSON,
    StoryResponse,
    StoryResponseFromJSON,
    StoryResponseToJSON,
    Tenant,
    TenantFromJSON,
    TenantToJSON,
} from '../models';

export interface GetLabelsForLocaleRequest {
    locale: string;
}

export interface GetStoryRequest {
    fullSlug: string;
    tenant?: Tenant;
}

export interface GetTreeRequest {
    tenant: Tenant;
}

export interface SearchFulltextRequest {
    searchTerm: string;
    tenant: Tenant;
}

/**
 * ContentApi - interface
 * 
 * @export
 * @interface ContentApiInterface
 */
export interface ContentApiInterface {
    /**
     * 
     * @summary returns translated labels
     * @param {string} locale 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApiInterface
     */
    getLabelsForLocaleRaw(requestParameters: GetLabelsForLocaleRequest): Promise<runtime.ApiResponse<Labels>>;

    /**
     * returns translated labels
     */
    getLabelsForLocale(requestParameters: GetLabelsForLocaleRequest): Promise<Labels>;

    /**
     * 
     * @summary returns a single story from storyblok
     * @param {string} fullSlug Full slug of the story starting with the tenant, e.g. de/edi-bei-dm
     * @param {Tenant} [tenant] optional tenant for a localized story
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApiInterface
     */
    getStoryRaw(requestParameters: GetStoryRequest): Promise<runtime.ApiResponse<StoryResponse>>;

    /**
     * returns a single story from storyblok
     */
    getStory(requestParameters: GetStoryRequest): Promise<StoryResponse>;

    /**
     * 
     * @summary returns the content tree containing the items the user is authorized to view
     * @param {Tenant} tenant the tenant to get the content tree for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApiInterface
     */
    getTreeRaw(requestParameters: GetTreeRequest): Promise<runtime.ApiResponse<ContentTrees>>;

    /**
     * returns the content tree containing the items the user is authorized to view
     */
    getTree(requestParameters: GetTreeRequest): Promise<ContentTrees>;

    /**
     * 
     * @summary returns a list of stories matching the criteria
     * @param {string} searchTerm 
     * @param {Tenant} tenant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApiInterface
     */
    searchFulltextRaw(requestParameters: SearchFulltextRequest): Promise<runtime.ApiResponse<SearchResponse>>;

    /**
     * returns a list of stories matching the criteria
     */
    searchFulltext(requestParameters: SearchFulltextRequest): Promise<SearchResponse>;

}

/**
 * 
 */
export class ContentApi extends runtime.BaseAPI implements ContentApiInterface {

    /**
     * returns translated labels
     */
    async getLabelsForLocaleRaw(requestParameters: GetLabelsForLocaleRequest): Promise<runtime.ApiResponse<Labels>> {
        if (requestParameters.locale === null || requestParameters.locale === undefined) {
            throw new runtime.RequiredError('locale','Required parameter requestParameters.locale was null or undefined when calling getLabelsForLocale.');
        }

        const queryParameters: any = {};

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/i18n/labels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LabelsFromJSON(jsonValue));
    }

    /**
     * returns translated labels
     */
    async getLabelsForLocale(requestParameters: GetLabelsForLocaleRequest): Promise<Labels> {
        const response = await this.getLabelsForLocaleRaw(requestParameters);
        return await response.value();
    }

    /**
     * returns a single story from storyblok
     */
    async getStoryRaw(requestParameters: GetStoryRequest): Promise<runtime.ApiResponse<StoryResponse>> {
        if (requestParameters.fullSlug === null || requestParameters.fullSlug === undefined) {
            throw new runtime.RequiredError('fullSlug','Required parameter requestParameters.fullSlug was null or undefined when calling getStory.');
        }

        const queryParameters: any = {};

        if (requestParameters.fullSlug !== undefined) {
            queryParameters['fullSlug'] = requestParameters.fullSlug;
        }

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/story`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StoryResponseFromJSON(jsonValue));
    }

    /**
     * returns a single story from storyblok
     */
    async getStory(requestParameters: GetStoryRequest): Promise<StoryResponse> {
        const response = await this.getStoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * returns the content tree containing the items the user is authorized to view
     */
    async getTreeRaw(requestParameters: GetTreeRequest): Promise<runtime.ApiResponse<ContentTrees>> {
        if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
            throw new runtime.RequiredError('tenant','Required parameter requestParameters.tenant was null or undefined when calling getTree.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/tree`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentTreesFromJSON(jsonValue));
    }

    /**
     * returns the content tree containing the items the user is authorized to view
     */
    async getTree(requestParameters: GetTreeRequest): Promise<ContentTrees> {
        const response = await this.getTreeRaw(requestParameters);
        return await response.value();
    }

    /**
     * returns a list of stories matching the criteria
     */
    async searchFulltextRaw(requestParameters: SearchFulltextRequest): Promise<runtime.ApiResponse<SearchResponse>> {
        if (requestParameters.searchTerm === null || requestParameters.searchTerm === undefined) {
            throw new runtime.RequiredError('searchTerm','Required parameter requestParameters.searchTerm was null or undefined when calling searchFulltext.');
        }

        if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
            throw new runtime.RequiredError('tenant','Required parameter requestParameters.tenant was null or undefined when calling searchFulltext.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchResponseFromJSON(jsonValue));
    }

    /**
     * returns a list of stories matching the criteria
     */
    async searchFulltext(requestParameters: SearchFulltextRequest): Promise<SearchResponse> {
        const response = await this.searchFulltextRaw(requestParameters);
        return await response.value();
    }

}
