import React, { useEffect, useRef } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import useRecaptchaConfig from './useRecaptchaConfig';
import getTenant, { supportedLanguagesForTenants } from '../../../content/getTenant';
import { RecaptchaAction } from '../../../gen/ppapiclient';

declare global {
    interface Window {
        grecaptcha: {
            enterprise: {
                execute: (siteKey?: string, params?: object) => void;
                ready: (arg0: () => void) => void;
                reset: () => void;
            };
        };
        _DATADOG_SYNTHETICS_BROWSER?: boolean;
        recaptchaSubmitCallback: (captchaToken: string) => void;
        recaptchaReset: () => void;
    }
}

interface CaptchaProps {
    submitCallback: (recaptchaToken: string) => void;
    action: RecaptchaAction;
}

export default function Recaptcha({ submitCallback, action }: Readonly<CaptchaProps>): React.ReactElement {
    const { siteKey, enabled } = useRecaptchaConfig();

    const script = useRef(document.createElement('script'));

    useEffect(() => {
        if (!enabled) {
            return () => {
                // do nothing
            };
        }
        const language = supportedLanguagesForTenants[getTenant()];
        const currentScript = script.current;
        currentScript.id = 'recaptcha';
        currentScript.src = `https://www.recaptcha.net/recaptcha/enterprise.js?hl=${language}`;
        currentScript.type = 'text/javascript';
        currentScript.async = true;
        currentScript.defer = true;
        document.body.appendChild(currentScript);
        script.current = currentScript;

        return () => {
            // remove all script-nodes on unmount
            const captchaNodes = Array.from(document.querySelectorAll('.grecaptcha-badge'));
            captchaNodes.forEach((node) => {
                node?.parentNode?.removeChild(node);
            });
            document.body.removeChild(currentScript);
        };
    }, [siteKey, enabled]);

    useEffect(() => {
        window.recaptchaSubmitCallback = submitCallback;
        window.recaptchaReset = () => {
            datadogRum.addAction('recaptchaReset', {
                action,
                tenant: getTenant(),
            });
            window.grecaptcha.enterprise.reset();
        };
    }, [action, submitCallback]);

    if (!enabled) {
        return <></>;
    }
    return (
        <div
            id="recaptcha-element"
            className="g-recaptcha"
            data-callback="recaptchaSubmitCallback"
            data-error-callback="recaptchaReset"
            data-expired-callback="recaptchaReset"
            data-sitekey={siteKey}
            data-action={action}
            data-size="invisible"
            data-theme="light"
        />
    );
}
