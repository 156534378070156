import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { AngleLeftIcon, ThemeProps, useFela, WithTheme } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useOffCanvasStyle } from './offcanvasStyle';
import { useHideOffCanvas, useIsOffCanvasVisible, useRemoveItemFromStack } from './OffCanvasContext';
import { findNavigationItemInContentTreeById } from '../../../../navigation/NavigationUtil';
import { useContentTree } from '../../../../content/StoryProvider';

const backButton = ({ theme }: WithTheme) => ({
    color: theme.palette.color2.rgba,
    fontSize: theme.typography.fontSize.l.rem,
    fontFamily: theme.typography.fontFamily.primary.main,
    background: 'transparent',
    fontWeight: 400,
    appearance: 'none',
    border: 0,
    cursor: 'pointer',
    padding: `${theme.dimension.spacing.s.rem} 0`,
    margin: `${theme.dimension.spacing.xxs.rem} ${theme.dimension.spacing.l.rem}`,
});

const subMenuTitle = ({ theme }: WithTheme) => ({
    textDecoration: 'none',
    '& h2': {
        color: theme.palette.color2.rgba,
        fontFamily: theme.typography.fontFamily.primary.main,
        fontWeight: 400,
        margin: `0 ${theme.dimension.spacing.xl.rem} ${theme.dimension.spacing.s.rem}`,
        padding: 0,
        fontSize: theme.typography.fontSize.xxxl.rem,
    },
});

type OffCanvasSubLevelProps = {
    id: string;
    renderCloseButton: (callback: () => void) => React.ReactNode;
    children: ReactNode;
    defaultRender?: boolean;
};

function OffCanvasSubLevel({
    renderCloseButton,
    id,
    children,
    defaultRender = false,
}: Readonly<OffCanvasSubLevelProps>) {
    const { navigation } = useContentTree();

    const selectedItem = findNavigationItemInContentTreeById(id, navigation);
    if (!selectedItem) {
        throw new Error(`cannot find item with id: ${id}`);
    }

    const { name, fullSlug } = selectedItem.item;
    const [render, setRender] = useState(defaultRender);
    const [removeItem, setRemoveItem] = useState(false);
    const { css } = useFela<ThemeProps>();
    const { t } = useTranslation();
    const hideOffCanvas = useHideOffCanvas();
    const isOffCanvasVisible = useIsOffCanvasVisible();
    const removeItemFromStack = useRemoveItemFromStack();
    const backButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (isOffCanvasVisible) {
            setRender(true);
        }
    }, [isOffCanvasVisible]);

    const onAnimationEnd = () => {
        if (!isOffCanvasVisible) {
            setRender(false);
        }
        if (removeItem) {
            removeItemFromStack();
        }
    };

    const shouldSlideIn = () => isOffCanvasVisible && !removeItem;
    const offCanvasStyle = useOffCanvasStyle(shouldSlideIn);
    const clickHandlerButton = useCallback(() => {
        setRemoveItem(true);
    }, []);

    const clickHandlerLinkWrapper = useCallback(() => {
        hideOffCanvas();
    }, [hideOffCanvas]);

    const closeButtonCallback = useCallback(() => {
        hideOffCanvas();
    }, [hideOffCanvas]);

    const parentTitle = selectedItem.parentItem?.name ?? `${t('offCanvas.item.parent.linktext')}`;
    return (
        <>
            {render && (
                <div data-dmid="off-canvas-sub-level" className={css(offCanvasStyle)} onAnimationEnd={onAnimationEnd}>
                    <button
                        id={name}
                        type="button"
                        ref={backButtonRef}
                        className={css(backButton)}
                        title={t('offCanvas.item.parent.title', {
                            parentTitle,
                        })}
                        onClick={clickHandlerButton}
                    >
                        <AngleLeftIcon width={11} height="100%" aria-hidden /> {parentTitle}
                    </button>
                    <Link
                        data-menuitemid={id}
                        className={css(subMenuTitle)}
                        to={fullSlug}
                        title={name}
                        onClick={clickHandlerLinkWrapper}
                    >
                        <h2 data-dmid="sub-menu-title">{name}</h2>
                    </Link>
                    {children}
                    {renderCloseButton(closeButtonCallback)}
                </div>
            )}
        </>
    );
}

export default OffCanvasSubLevel;
