import { useMemo } from 'react';
import { IRenderer, ThemeProps, useFela, WithTheme } from '@dm/design-system';

const slideConfig = ({ start, stop }: { start: string; stop: string }) => ({
    '0%': {
        right: start,
    },
    '100%': {
        right: stop,
    },
});

const useSlideAnimation = ({ renderer, offCanvasMaxWidth }: { renderer: IRenderer; offCanvasMaxWidth: string }) => {
    return useMemo(() => {
        const slideIn = renderer.renderKeyframe(slideConfig, {
            start: `-${offCanvasMaxWidth}`,
            stop: '0',
        });
        const slideOut = renderer.renderKeyframe(slideConfig, {
            start: '0',
            stop: `-${offCanvasMaxWidth}`,
        });
        return { slideIn, slideOut };
    }, [renderer, offCanvasMaxWidth]);
};

const offCanvasStyle =
    ({
        animation,
        offCanvasMaxWidth,
        direction,
    }: {
        animation: string;
        offCanvasMaxWidth: string;
        direction: string;
    }) =>
    ({ theme }: WithTheme) => ({
        position: 'fixed',
        width: '100%',
        maxWidth: offCanvasMaxWidth,
        height: '100%',
        top: 0,
        right: direction === 'in' ? 0 : `-${offCanvasMaxWidth}`,
        bottom: 0,
        zIndex: theme.dimension.zIndex.layer,
        background: theme.palette.color5.hex,
        overflowX: 'hidden',
        overflowY: 'scroll',
        boxShadow: '0 0 25px 0 rgba(0, 0, 0, 0.2)',
        animationName: animation,
        animationDuration: '400ms',
        animationTimingFunction: 'cubic-bezier(.29, .28, 0,1)',
        '-webkit-overflow-scrolling': 'touch',
    });

export function useOffCanvasStyle(shouldSlideIn: () => boolean) {
    const { renderer } = useFela<ThemeProps>();
    const offCanvasMaxWidth = '500px';
    const { slideIn, slideOut } = useSlideAnimation({ renderer, offCanvasMaxWidth });
    const animation = shouldSlideIn() ? slideIn : slideOut;
    const direction = shouldSlideIn() ? 'in' : 'out';
    return offCanvasStyle({ animation, offCanvasMaxWidth, direction });
}

export default useOffCanvasStyle;
