import { ChangeEvent, EventHandler, FocusEvent, InvalidEvent, useCallback, useEffect, useRef } from 'react';

export default function useValidation({
    required,
    checkValidity,
    onCheckValidity,
    customErrorMessage = '',
    onChange,
    setInvalid,
    ref,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Record<string, any>): Record<string, any> {
    const invalidHandler: EventHandler<InvalidEvent<HTMLInputElement>> = (event) => {
        setInvalid({
            invalid: true,
            errorMsg: event.target.validationMessage,
        });
    };

    const checkInputValidity = useCallback(
        (input: HTMLInputElement): void => {
            input.setCustomValidity('');
            setInvalid({ invalid: false, errorMsg: '' });
            input.checkValidity();
        },
        [setInvalid]
    );

    useEffect(() => {
        if (checkValidity && ref.current) {
            checkInputValidity(ref.current);
            if (onCheckValidity !== undefined) {
                onCheckValidity();
            }
        }
    }, [checkInputValidity, checkValidity, onCheckValidity, ref]);

    useEffect(() => {
        if (customErrorMessage && ref.current) {
            setInvalid({ invalid: true, errorMsg: customErrorMessage });
            ref.current.setCustomValidity(customErrorMessage);
        }
    }, [customErrorMessage, setInvalid, ref]);

    // FIX THAT required gets set via ref FIX WHEN design-system component allows setting required attribute on html input
    const shouldAddAttributes = useRef(true);
    useEffect(() => {
        if (shouldAddAttributes.current && ref.current) {
            if (required) {
                ref.current.setAttribute('required', required.toString());
            }
            shouldAddAttributes.current = false;
        }
    });

    const onChangeInput = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.validity.valid) {
            setInvalid({ invalid: false, errorMsg: '' });
            event.target.checkValidity();
        }
        if (onChange !== undefined) {
            onChange(event);
        }
    };

    const onBlurInput = ({ target }: FocusEvent<HTMLInputElement>): void => checkInputValidity(target);

    return { invalidHandler, onChangeInput, ref, onBlurInput };
}
