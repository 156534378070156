/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoFromJSONTyped,
    UserInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface OptionalUserInfo
 */
export interface OptionalUserInfo {
    /**
     * 
     * @type {UserInfo}
     * @memberof OptionalUserInfo
     */
    userInfo: UserInfo | null;
}

export function OptionalUserInfoFromJSON(json: any): OptionalUserInfo {
    return OptionalUserInfoFromJSONTyped(json, false);
}

export function OptionalUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalUserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userInfo': UserInfoFromJSON(json['userInfo']),
    };
}

export function OptionalUserInfoToJSON(value?: OptionalUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userInfo': UserInfoToJSON(value.userInfo),
    };
}


