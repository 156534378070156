import React from 'react';
import { Shadow } from '@dm/design-system';

type Props = {
    children: React.ReactNode;
};

const myUserDataPageContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    wordWrap: 'break-word',
    padding: '12px',
    marginBottom: '24px',
};

export default function MyUserDataProfilePageContainer({ children }: Readonly<Props>) {
    return <Shadow style={myUserDataPageContainerStyle}>{children}</Shadow>;
}
