import { Heading } from '@dm/design-system';
import React from 'react';

type Props = {
    heading: string;
};

export default function MyUserDataSectionHeader({ heading }: Readonly<Props>) {
    return (
        <Heading
            kind="tertiary"
            style={{
                paddingBottom: '12px',
            }}
        >
            {heading}
        </Heading>
    );
}
