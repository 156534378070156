/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserRegistration
 */
export interface UserRegistration {
    /**
     * city
     * @type {string}
     * @memberof UserRegistration
     */
    city: string;
    /**
     * company, must not be blank
     * @type {string}
     * @memberof UserRegistration
     */
    company: string;
    /**
     * ISO country code
     * @type {string}
     * @memberof UserRegistration
     */
    countryCode: UserRegistrationCountryCodeEnum;
    /**
     * dmContact, must not be blank
     * @type {string}
     * @memberof UserRegistration
     */
    dmContact: string;
    /**
     * e-mail address, must be a valid e-mail address
     * @type {string}
     * @memberof UserRegistration
     */
    email: string;
    /**
     * first name, must not be blank
     * @type {string}
     * @memberof UserRegistration
     */
    firstName: string;
    /**
     * last name, must not be blank
     * @type {string}
     * @memberof UserRegistration
     */
    lastName: string;
    /**
     * street, must not be blank
     * @type {string}
     * @memberof UserRegistration
     */
    street: string;
    /**
     * 
     * @type {Tenant}
     * @memberof UserRegistration
     */
    tenant: Tenant;
    /**
     * identification number of the vendor, must not be blank
     * @type {string}
     * @memberof UserRegistration
     */
    vendorNumber: string;
    /**
     * zip code, must not be blank
     * @type {string}
     * @memberof UserRegistration
     */
    zip: string;
    /**
     * category/product group, must not be blank
     * @type {string}
     * @memberof UserRegistration
     */
    category?: string;
    /**
     * phone number, must not be blank
     * @type {string}
     * @memberof UserRegistration
     */
    phone?: string;
}

/**
* @export
* @enum {string}
*/
export enum UserRegistrationCountryCodeEnum {
    AT = 'AT',
    BA = 'BA',
    BE = 'BE',
    BG = 'BG',
    CH = 'CH',
    CZ = 'CZ',
    DE = 'DE',
    DK = 'DK',
    EE = 'EE',
    ES = 'ES',
    FR = 'FR',
    GB = 'GB',
    GR = 'GR',
    HR = 'HR',
    HU = 'HU',
    IE = 'IE',
    IT = 'IT',
    LU = 'LU',
    MT = 'MT',
    NL = 'NL',
    PL = 'PL',
    RO = 'RO',
    RS = 'RS',
    SE = 'SE',
    SI = 'SI',
    SK = 'SK',
    TR = 'TR',
    US = 'US'
}

export function UserRegistrationFromJSON(json: any): UserRegistration {
    return UserRegistrationFromJSONTyped(json, false);
}

export function UserRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': json['city'],
        'company': json['company'],
        'countryCode': json['countryCode'],
        'dmContact': json['dmContact'],
        'email': json['email'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'street': json['street'],
        'tenant': TenantFromJSON(json['tenant']),
        'vendorNumber': json['vendorNumber'],
        'zip': json['zip'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function UserRegistrationToJSON(value?: UserRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': value.city,
        'company': value.company,
        'countryCode': value.countryCode,
        'dmContact': value.dmContact,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'street': value.street,
        'tenant': TenantToJSON(value.tenant),
        'vendorNumber': value.vendorNumber,
        'zip': value.zip,
        'category': value.category,
        'phone': value.phone,
    };
}


