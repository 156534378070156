/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RecaptchaAction {
    REGISTRATION_MFA = 'registrationMFA',
    CONFIRM_USER = 'confirmUser',
    CONFIRM_ACCOUNT = 'confirmAccount',
    PASSWORD_FORGOTTEN = 'passwordForgotten',
    RESET_PASSWORD = 'resetPassword'
}

export function RecaptchaActionFromJSON(json: any): RecaptchaAction {
    return RecaptchaActionFromJSONTyped(json, false);
}

export function RecaptchaActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecaptchaAction {
    return json as RecaptchaAction;
}

export function RecaptchaActionToJSON(value?: RecaptchaAction | null): any {
    return value as any;
}

