import React from 'react';
import TextInput from '../../../content/components/idm/util/fields/TextInput';
import { AppplicationAdditionalInformationFieldProps } from '../types';

type Props = {
    additionalInformationFields: AppplicationAdditionalInformationFieldProps[] | undefined;
    setAdditionalInformationFieldValue: (fieldName: string, value: string) => void;
    disabled: boolean;
};

export default function AdditionalInformationInputFields({
    additionalInformationFields,
    setAdditionalInformationFieldValue,
    disabled,
}: Readonly<Props>) {
    if (disabled || !additionalInformationFields) {
        return <></>;
    }
    return (
        <>
            {additionalInformationFields.map((additionalInformationField) => {
                return (
                    <TextInput
                        key={additionalInformationField.name}
                        label={additionalInformationField.label}
                        required={additionalInformationField.required}
                        maxLength={200}
                        dmId={additionalInformationField.name}
                        onChange={(value) => setAdditionalInformationFieldValue(additionalInformationField.name, value)}
                    />
                );
            })}
        </>
    );
}
