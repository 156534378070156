/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PasswordResetError {
    INVALID_TOKEN_OR_USER = 'INVALID_TOKEN_OR_USER',
    PASSWORD_POLICY_VIOLATION = 'PASSWORD_POLICY_VIOLATION'
}

export function PasswordResetErrorFromJSON(json: any): PasswordResetError {
    return PasswordResetErrorFromJSONTyped(json, false);
}

export function PasswordResetErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordResetError {
    return json as PasswordResetError;
}

export function PasswordResetErrorToJSON(value?: PasswordResetError | null): any {
    return value as any;
}

