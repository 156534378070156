import React, { createContext, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { newsletterApi } from '../../../apiConfig';
import StoryRenderer from '../../StoryRenderer';

export type NewsletterContext = {
    subscribedChannels: string[];
    reloadSubscriptions: () => void;
};

export const NewslettersContext = createContext<NewsletterContext>({
    subscribedChannels: [],
    reloadSubscriptions: () => {
        // empty initial method
    },
});

export default function NewsletterPage(): ReactElement {
    const [subscribedChannels, setSubscribedChannels] = useState<string[]>([]);

    const reloadSubscriptions = useCallback(() => {
        newsletterApi.getSubscribesNewsletter().then(setSubscribedChannels);
    }, []);

    useEffect(() => {
        reloadSubscriptions();
    }, [reloadSubscriptions]);

    const subscribeContext = useMemo<NewsletterContext>(
        () => ({
            reloadSubscriptions,
            subscribedChannels,
        }),
        [reloadSubscriptions, subscribedChannels]
    );

    return (
        <NewslettersContext.Provider value={subscribeContext}>
            <StoryRenderer />
        </NewslettersContext.Provider>
    );
}
