import { UserCheckIcon, UserIcon } from '@dm/design-system';
import React from 'react';

type Props = {
    isLoggedIn: boolean;
};

function UserManagementWidgetIcon({ isLoggedIn }: Props) {
    return isLoggedIn ? <UserCheckIcon /> : <UserIcon />;
}

export default UserManagementWidgetIcon;
