import { Link as RouterLink } from 'react-router-dom';
import { Link, theme } from '@dm/design-system';
import React from 'react';

type Props = Readonly<{
    id: string;
    targetSlug: string;
    label: string;
}>;

const tenantOptionStyle: object = {
    color: theme.palette.headline.hex,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
};

function UserProfileMenuEntry({ id, targetSlug, label }: Props) {
    return (
        <Link as={RouterLink} key={id} style={tenantOptionStyle} to={targetSlug} dmId={id}>
            {label}
        </Link>
    );
}

export default UserProfileMenuEntry;
