/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * TODO
 * @export
 * @interface TermsResponse
 */
export interface TermsResponse {
    /**
     * application identifier
     * @type {string}
     * @memberof TermsResponse
     */
    application: string;
    /**
     * terms pdf file
     * @type {object}
     * @memberof TermsResponse
     */
    asset: object;
    /**
     * terms pdf file
     * @type {object}
     * @memberof TermsResponse
     */
    changes: object;
    /**
     * has the user already confirmed the terms
     * @type {boolean}
     * @memberof TermsResponse
     */
    confirmed: boolean;
    /**
     * grace period for confirming the terms
     * @type {number}
     * @memberof TermsResponse
     */
    gracePeriod: number;
    /**
     * title
     * @type {string}
     * @memberof TermsResponse
     */
    title: string;
    /**
     * valid from date
     * @type {Date}
     * @memberof TermsResponse
     */
    validFrom: Date;
    /**
     * version
     * @type {string}
     * @memberof TermsResponse
     */
    version: string;
}

export function TermsResponseFromJSON(json: any): TermsResponse {
    return TermsResponseFromJSONTyped(json, false);
}

export function TermsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TermsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'application': json['application'],
        'asset': json['asset'],
        'changes': json['changes'],
        'confirmed': json['confirmed'],
        'gracePeriod': json['gracePeriod'],
        'title': json['title'],
        'validFrom': (new Date(json['validFrom'])),
        'version': json['version'],
    };
}

export function TermsResponseToJSON(value?: TermsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'application': value.application,
        'asset': value.asset,
        'changes': value.changes,
        'confirmed': value.confirmed,
        'gracePeriod': value.gracePeriod,
        'title': value.title,
        'validFrom': (value.validFrom.toISOString()),
        'version': value.version,
    };
}


