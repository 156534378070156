import { FelaStyle, Heading as DmHeading, ThemeProps } from '@dm/design-system';
import { HeadingKind } from '@dm/design-system/es/Atoms/Heading/Heading';
import React, { ReactElement } from 'react';
import { RichTextComponentProps } from '../render';

const levels: HeadingKind[] = ['secondary', 'tertiary', 'quaternary'];

const headingStyle: FelaStyle<ThemeProps, Record<string, unknown>> = {
    marginTop: '40px !important', // because the Design System also uses !important margins
    wordWrap: 'break-word',
};

export default function Heading({
    children,
    attrs: { level = 1 } = {},
}: Readonly<RichTextComponentProps>): ReactElement<RichTextComponentProps> {
    return (
        <DmHeading data-dmid="heading" style={headingStyle} kind={levels[level - 1] ?? 'quaternary'}>
            {children as string}
        </DmHeading>
    );
}
