/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * data for confirming a user registration
 * @export
 * @interface UserConfirmation
 */
export interface UserConfirmation {
    /**
     * e-mail address
     * @type {string}
     * @memberof UserConfirmation
     */
    email: string;
    /**
     * new password, must not be blank
     * @type {string}
     * @memberof UserConfirmation
     */
    newPassword: string;
    /**
     * 
     * @type {Tenant}
     * @memberof UserConfirmation
     */
    tenant: Tenant;
    /**
     * token received via mail, must not be blank
     * @type {string}
     * @memberof UserConfirmation
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof UserConfirmation
     */
    recaptchaToken?: string;
}

export function UserConfirmationFromJSON(json: any): UserConfirmation {
    return UserConfirmationFromJSONTyped(json, false);
}

export function UserConfirmationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserConfirmation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'newPassword': json['newPassword'],
        'tenant': TenantFromJSON(json['tenant']),
        'token': json['token'],
        'recaptchaToken': !exists(json, 'recaptchaToken') ? undefined : json['recaptchaToken'],
    };
}

export function UserConfirmationToJSON(value?: UserConfirmation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'newPassword': value.newPassword,
        'tenant': TenantToJSON(value.tenant),
        'token': value.token,
        'recaptchaToken': value.recaptchaToken,
    };
}


