/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NotificationResponse,
    NotificationResponseFromJSON,
    NotificationResponseToJSON,
    Tenant,
    TenantFromJSON,
    TenantToJSON,
} from '../models';

export interface GetNotificationsRequest {
    tenant: Tenant;
}

/**
 * NotificationsApi - interface
 * 
 * @export
 * @interface NotificationsApiInterface
 */
export interface NotificationsApiInterface {
    /**
     * 
     * @summary returns the notifications that are active and relevant for the current user
     * @param {Tenant} tenant the tenant to get the terms for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApiInterface
     */
    getNotificationsRaw(requestParameters: GetNotificationsRequest): Promise<runtime.ApiResponse<Array<NotificationResponse>>>;

    /**
     * returns the notifications that are active and relevant for the current user
     */
    getNotifications(requestParameters: GetNotificationsRequest): Promise<Array<NotificationResponse>>;

}

/**
 * 
 */
export class NotificationsApi extends runtime.BaseAPI implements NotificationsApiInterface {

    /**
     * returns the notifications that are active and relevant for the current user
     */
    async getNotificationsRaw(requestParameters: GetNotificationsRequest): Promise<runtime.ApiResponse<Array<NotificationResponse>>> {
        if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
            throw new runtime.RequiredError('tenant','Required parameter requestParameters.tenant was null or undefined when calling getNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.tenant !== undefined) {
            queryParameters['tenant'] = requestParameters.tenant;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/cms/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationResponseFromJSON));
    }

    /**
     * returns the notifications that are active and relevant for the current user
     */
    async getNotifications(requestParameters: GetNotificationsRequest): Promise<Array<NotificationResponse>> {
        const response = await this.getNotificationsRaw(requestParameters);
        return await response.value();
    }

}
