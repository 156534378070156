/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * subscribe newsletter from channel
 * @export
 * @interface NewsletterSubscribeDTO
 */
export interface NewsletterSubscribeDTO {
    /**
     * channel
     * @type {string}
     * @memberof NewsletterSubscribeDTO
     */
    channel: string;
}

export function NewsletterSubscribeDTOFromJSON(json: any): NewsletterSubscribeDTO {
    return NewsletterSubscribeDTOFromJSONTyped(json, false);
}

export function NewsletterSubscribeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterSubscribeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channel': json['channel'],
    };
}

export function NewsletterSubscribeDTOToJSON(value?: NewsletterSubscribeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channel': value.channel,
    };
}


