import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { useSession } from '../../../session/SessionProvider';
import getTenant from '../../../content/getTenant';
import { useTrackingContext } from './TrackingProvider';
import { preserveStoryblokPreviewParams } from '../../../content/StoryblokPreviewProvider';
import replaceCurrentHostWithBackendUrlAndPrefix from '../../../BackendApiUtil';

export interface LinkProps {
    to: string;
    className?: string;
    tabIndex?: number;
    trackedType: string;
    openInNewTab?: boolean;
    isExternalLink?: boolean;
    title?: string;

    onClick?(): void;

    children: ReactElement;
}

export default function TrackedLink({
    children,
    to,
    className,
    tabIndex,
    trackedType,
    openInNewTab,
    isExternalLink,
    title,
    onClick,
}: Readonly<LinkProps>): ReactElement {
    const { userInfo } = useSession();
    const { getTimeOnPageInSec } = useTrackingContext();

    const clickHandler = () => {
        datadogRum.addAction('trackedLink', {
            dmId: trackedType,
            userHash: userInfo?.userHash,
            slug: to,
            tenant: getTenant(),
            timeOnPageInSec: getTimeOnPageInSec(),
        });

        onClick?.();
    };

    if (isExternalLink) {
        return (
            <a
                href={replaceCurrentHostWithBackendUrlAndPrefix(to)}
                target={openInNewTab ? '_blank' : '_self'}
                rel={openInNewTab ? 'noopener noreferrer' : undefined}
                className={className}
                title={title}
                onClick={clickHandler}
            >
                {children}
            </a>
        );
    }

    return (
        <Link
            to={preserveStoryblokPreviewParams(replaceCurrentHostWithBackendUrlAndPrefix(to))}
            className={className}
            tabIndex={tabIndex}
            title={title}
            onClick={clickHandler}
            target={openInNewTab ? '_blank' : '_self'}
        >
            {children}
        </Link>
    );
}
