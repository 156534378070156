import { Banner, Cell, Loading, Spacer, Table, TableColumn, Text, theme, View } from '@dm/design-system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserProfileNavigation from './components/UserProfileNavigation';
import { userProfileApi } from '../../apiConfig';
import { Colleague } from '../../gen/ppapiclient';

export default function MyColleaguesPage() {
    const { t } = useTranslation();

    const [colleagues, setColleagues] = useState<Colleague[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        userProfileApi
            .myColleagues()
            .then((response) => {
                setColleagues(response);
                setIsLoading(false);
            })
            .catch(() => {
                setHasError(true);
                setIsLoading(false);
            });
    }, []);

    const columns: TableColumn<Colleague>[] = [
        {
            key: 'firstname',
            heading: t('userProfile.myColleagues.tableColumn.firstName'),
            renderCell: ({ firstName }) => <Text>{firstName}</Text>,
        },
        {
            key: 'lastname',
            heading: t('userProfile.myColleagues.tableColumn.lastName'),
            renderCell: ({ lastName }) => <Text>{lastName}</Text>,
        },
        {
            key: 'phone',
            heading: t('userProfile.myColleagues.tableColumn.phone'),
            renderCell: ({ phone }) => <Text>{phone}</Text>,
        },
        {
            key: 'email',
            heading: t('userProfile.myColleagues.tableColumn.eMail'),
            renderCell: ({ email }) => <Text>{email}</Text>,
        },
        {
            key: 'roles',
            heading: t('userProfile.myColleagues.tableColumn.activeApplications'),
            renderCell: ({ roles = [] }) => (
                <Cell>
                    {roles.length > 0 ? (
                        roles.map((value) => (
                            <Text key={`userProfile.myColleagues.tableColumn.activeApplication-${value}`}>
                                {t(`application.name.${value}`)}
                            </Text>
                        ))
                    ) : (
                        <Text key="userProfile.myColleagues.tableColumn.noActiveApplications">
                            {t('userProfile.myColleagues.tableColumn.noActiveApplications')}
                        </Text>
                    )}
                </Cell>
            ),
        },
    ];

    const fetchingSuccessful = !hasError && !isLoading && colleagues.length > 0;
    const fetchingSuccessfulButEmpty = !hasError && !isLoading && colleagues.length === 0;

    return (
        <View>
            <UserProfileNavigation />

            {isLoading && <Loading seasonal />}

            {hasError && (
                <Banner dmId="colleagues-page-error" kind="error">
                    {t('userProfile.myColleagues.errorText')}
                </Banner>
            )}

            {fetchingSuccessfulButEmpty && (
                <Banner dmId="colleagues-page-no-data" kind="neutral">
                    {t(`userProfile.myColleagues.noDataText`)}
                </Banner>
            )}
            {fetchingSuccessful && <Table rows={colleagues} columns={columns} stickyHeader />}

            <Spacer size={theme.dimension.spacing.s.rem} style={{ marginBottom: 18 }} />
        </View>
    );
}
