import { Button, theme } from '@dm/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

const anonymousButtonStyle = {
    margin: theme.dimension.spacing.xs.rem,
    width: 'auto !important',
};

type Props = Readonly<{
    id: string;
    kind: 'primary' | 'secondary' | 'tertiary' | 'invisible';
    targetSlug: string;
    label: string;
}>;

function AnonymousUserProfileMenuEntry({ id, kind, targetSlug, label }: Props) {
    const { t } = useTranslation();
    return (
        <Button style={anonymousButtonStyle} key={id} kind={kind} dmId={id} as="a" href={targetSlug}>
            {t(label)}
        </Button>
    );
}

export default AnonymousUserProfileMenuEntry;
