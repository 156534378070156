import React, { ComponentType, createElement, ReactElement, useEffect, useMemo, useState } from 'react';
import componentMap from './componentMap';
import ErrorMessage from './components/errorPage/ErrorMessage';
import Transitioning from './components/empty/Transitioning';
import { useStoryProvider } from './StoryProvider';
import { useSession } from '../session/SessionProvider';

export type ComponentMap = Record<string, ComponentType<never>>;

export default function StoryRenderer(): ReactElement {
    const { storyData, refreshStory } = useStoryProvider();
    const { refreshSession, authentication } = useSession();
    const [triedToRefreshSession, setTriedToRefreshSession] = useState(false);

    const { pending, error, story } = storyData;

    useEffect(() => {
        if (error && !pending && authentication === 'success' && !triedToRefreshSession) {
            setTriedToRefreshSession(true);
            refreshSession();
            refreshStory();
        } else if (!error) {
            setTriedToRefreshSession(false);
        }
    }, [authentication, error, pending, refreshSession, refreshStory, triedToRefreshSession]);

    const isRetryingAfterError = useMemo(() => {
        return triedToRefreshSession && error && authentication !== 'unauthorized';
    }, [triedToRefreshSession, error, authentication]);

    if (pending || isRetryingAfterError) {
        return <Transitioning />;
    }

    if (error) {
        return <ErrorMessage />;
    }

    return createElement(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        componentMap[story.content.component] ?? ErrorMessage,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        story.content
    );
}
