import { Notification, NotificationContainer, Spacer } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { CustomNotificationProps, useNotification } from './NotificationProvider';

// FIX THAT it should use design system animations FIX WHEN they are available
export function AnimatedNotification({ dmId, kind, message }: Readonly<CustomNotificationProps>): ReactElement {
    const { closeNotification } = useNotification();
    return (
        <>
            <Notification dmId={dmId} kind={kind} message={message} onClose={(): void => closeNotification(dmId)} />
            <Spacer size={12} />
        </>
    );
}

export interface NotificationsProps {
    notifications: CustomNotificationProps[];
}

export default function Notifications({ notifications }: Readonly<NotificationsProps>): ReactElement {
    return (
        <NotificationContainer dmId="notification-container">
            {notifications.map(({ dmId, kind, message }) => (
                <AnimatedNotification key={dmId} dmId={dmId} message={message} kind={kind} />
            ))}
        </NotificationContainer>
    );
}
