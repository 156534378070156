import { ThemeProps, useFela } from '@dm/design-system';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ContentTreeItem } from '../../gen/ppapiclient';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const footerLinkStyle = ({ theme }: { theme: ThemeProps }) => ({
    color: theme.palette.text.hex,
    ':hover': {
        color: theme.palette.color2.hex,
    },
    ':first-child': {
        marginLeft: 0,
    },
    ':last-child': {
        marginRight: 0,
    },
});

export default function FooterLink({ items }: Readonly<{ items: ContentTreeItem[] }>) {
    const { css } = useFela<ThemeProps>();
    return (
        <>
            {items.map((item, index) => (
                <Fragment key={item.uuid}>
                    <Link to={`/${item.fullSlug}`} className={css(footerLinkStyle)}>
                        {item.name}
                    </Link>
                    {index < items.length - 1 ? <>&nbsp;| </> : <></>}
                </Fragment>
            ))}
        </>
    );
}
