import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Banner, Box, Button, Cell, Loading, SearchForm, SearchIcon, Spacer, Text } from '@dm/design-system';
import { contentApi } from '../../../apiConfig';
import getTenant from '../../getTenant';
import { SearchResponse } from '../../../gen/ppapiclient';
import SearchHit from './SearchHit';

const pageStyle = { maxWidth: 700, flex: 1 };

function SearchPage(): ReactElement {
    const { t } = useTranslation();

    const [searchParams, setSearchParams] = useSearchParams();
    const query = searchParams.get('query') ?? '';
    const [result, setResult] = useState<SearchResponse>();
    const [searchTerm, setSearchTerm] = useState<string>(query);
    const [isError, setIsError] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const searchFormInputRef = useRef<HTMLInputElement>(null);
    const [searchInputValue, setSearchInputValue] = useState(query);

    const tenant = getTenant();

    useEffect(() => {
        setIsError(false);
        setResult(undefined);
        if (!searchTerm || searchTerm.length < 2) {
            return;
        }

        setSearching(true);
        contentApi
            .searchFulltext({ searchTerm, tenant })
            .then((searchResponse) => {
                setResult(searchResponse);
            })
            .catch((error) => {
                setIsError(true);
            })
            .finally(() => {
                setSearching(false);
            });
    }, [tenant, searchTerm]);

    const handleClear = () => {
        setSearchInputValue('');
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target?.value?.length > 0) {
            const queryValue = event.target.value;
            setSearchInputValue(queryValue);
        } else {
            setSearchInputValue('');
        }
    };

    const handleSubmit = (): void => {
        setSearchParams({ query: searchInputValue });
        setSearchTerm(searchInputValue);
    };

    const searchLabel = t('search.header.hint');
    const loadingLabel = t('search.loading-spinner');
    const searchButtonLabel = t('search.button.text');
    const noSearchResultsLabel = t('search.empty-results');
    const searchHeadline = t('search.headline');

    return (
        <Box>
            {searchHeadline}
            <Spacer size={10} />
            {searching && <Loading title={loadingLabel} />}
            <Cell style={pageStyle}>
                <SearchForm
                    key="searchbox"
                    dmId="search-box"
                    inputRef={searchFormInputRef}
                    // @ts-expect-error onchange expect form event, but here we get a change event
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    onClear={handleClear}
                    onClick={handleSubmit}
                    placeholder={searchLabel}
                    value={searchInputValue}
                    size={400}
                    styles={{ buttonContainer: { display: 'none' } }}
                />
                <Spacer size={10} />
                <Button
                    title={searchButtonLabel}
                    kind="primary"
                    icon={SearchIcon}
                    onClick={handleSubmit}
                    dmId="search-button"
                >
                    {searchButtonLabel}
                </Button>
            </Cell>
            <Spacer size="20" />
            <Box dmId="searchresults">
                {isError && (
                    <Banner dmId="search-status" kind="error">
                        {t('search.servererror')}
                    </Banner>
                )}
                {result?.total === 0 && <div>{noSearchResultsLabel}</div>}
                {result && result?.total !== 0 && (
                    <Box>
                        <Cell style={{ paddingLeft: 10 }}>
                            <Spacer size={10} />
                            <Text>{t('search.results-line', { numberOfHits: result.total })}</Text>
                        </Cell>
                        <Spacer size={10} />
                        {result?.searchResults?.map((hit, index, row) =>
                            SearchHit({ hit, isLastItem: row.length - 1 === index })
                        )}
                        <Spacer size={40} />
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default SearchPage;
