import { FelaStyle, ThemeProps } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { Asset } from '../StoryblokFieldTypes';
import { TileProps } from './Tile';

export const oldLightColors = new Set(['color4', 'color5', 'color7', 'color9', 'color11', 'color12', 'color13']);
export const lightColors = new Set([
    '#FDECCC', // light orange
    '#FCD998', // orange
    '#FAC765', // dark orange
    '#F8E3E8', // light red
    '#F1C7D1', // red
    '#EAABB9', // dark red
    '#ECE6F2', // light violet
    '#D9CDE6', // violet
    '#C6B4D9', // dark violet
    '#DDF1F6', // light blue
    '#BBE2EE', // blue
    '#99D4E5', // dark blue
    '#DFF2EF', // light green
    '#BEE5DF', // dark green
]);

function isOldColor(color: string): boolean {
    return color.startsWith('color');
}

function isLightColor(color: string) {
    return lightColors.has(color) || oldLightColors.has(color);
}

function hasBackgroundImage(backgroundImage: Asset | undefined): boolean {
    return !!backgroundImage?.id;
}

function getShadowColor(color: string): string {
    return isLightColor(color) ? 'white' : 'black'; // white shadow on light background color because light background color means dark text
}

function determineColorCode(theme: ThemeProps, color: string): string {
    if (isOldColor(color)) {
        return theme.palette[color].hex;
    }
    return color;
}

const tileStyle: FelaStyle<ThemeProps, TileProps> = ({ theme, backgroundImage, color = 'color5' }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    width: '150px',
    height: '150px',
    [theme.breakpoints.horizontal.s.mediaQuery]: {
        width: '160px',
        height: '160px',
    },
    [theme.breakpoints.horizontal.m.mediaQuery]: {
        width: '170px',
        height: '170px',
    },
    top: '0px',
    left: '0px',
    padding: '10px',
    borderRadius: theme.radius.l.rem,
    backgroundColor: determineColorCode(theme, color),
    color: theme.palette.color5.hex,
    fontWeight: 500,
    textDecoration: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'all .4s',
    position: 'absolute',
    fontSize: theme.typography.fontSize.m.rem,
    zIndex: 0,
    ':hover': {
        position: 'absolute',
        top: '-10px',
        left: '-10px',
        width: '190px',
        height: '190px',
        fontSize: theme.typography.fontSize.l.rem,
        zIndex: 1,
    },
    extend: [
        {
            condition: hasBackgroundImage(backgroundImage),
            style: {
                backgroundImage: `url(${backgroundImage?.filename})`,
                backgroundSize: 'cover',
                textShadow: `0px 0px 10px ${getShadowColor(color)}`,
            },
        },
        {
            condition: isLightColor(color),
            style: {
                color: theme.palette.color2.hex,
            },
        },
        {
            condition: color === 'color5',
            style: {
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: theme.palette.border.hex,
            },
        },
    ],
});

export default function TileTitle({
    css,
    children,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Readonly<Record<string, any>>): ReactElement {
    return (
        <div className={css(tileStyle)}>
            <div>{children}</div>
        </div>
    );
}
