/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentTreeItem,
    ContentTreeItemFromJSON,
    ContentTreeItemFromJSONTyped,
    ContentTreeItemToJSON,
} from './';

/**
 * Contains the navigation and footer items
 * @export
 * @interface ContentTrees
 */
export interface ContentTrees {
    /**
     * The content tree items intended for the footer
     * @type {Array<ContentTreeItem>}
     * @memberof ContentTrees
     */
    footer: Array<ContentTreeItem>;
    /**
     * The content tree items intended for the standard navigation
     * @type {Array<ContentTreeItem>}
     * @memberof ContentTrees
     */
    navigation: Array<ContentTreeItem>;
}

export function ContentTreesFromJSON(json: any): ContentTrees {
    return ContentTreesFromJSONTyped(json, false);
}

export function ContentTreesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentTrees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'footer': ((json['footer'] as Array<any>).map(ContentTreeItemFromJSON)),
        'navigation': ((json['navigation'] as Array<any>).map(ContentTreeItemFromJSON)),
    };
}

export function ContentTreesToJSON(value?: ContentTrees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'footer': ((value.footer as Array<any>).map(ContentTreeItemToJSON)),
        'navigation': ((value.navigation as Array<any>).map(ContentTreeItemToJSON)),
    };
}


