import React, { ReactElement, ReactEventHandler, useMemo, useState } from 'react';
import { Banner, Box, Button, Cell, Heading, Spacer, Text } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import { TermsResponse } from '../../../gen/ppapiclient';
import { componentMap, linkMap } from '../../richtextRenderer/config';
import RichText from '../../richtextRenderer/RichText';
import { RichTextNode } from '../../richtextRenderer/render';
import Download from '../Download';
import { Asset } from '../../StoryblokFieldTypes';
import getTenant from '../../getTenant';
import formatLocalDate from '../../DateUtil';
import { useSession } from '../../../session/SessionProvider';
import { termsApi } from '../../../apiConfig';
import { useNotification } from '../../../notification/NotificationProvider';
import { useTerms } from './TermsProvider';

export interface TermsProps {
    terms: TermsResponse;
}

export function TermsConfirmationButton({
    confirmed,
    userIsLoggedIn,
    confirmHandler,
}: Readonly<{
    confirmed: boolean;
    userIsLoggedIn: boolean;
    confirmHandler: ReactEventHandler;
}>) {
    const { t } = useTranslation();

    if (!userIsLoggedIn) {
        return null;
    }
    if (confirmed) {
        return <Button disabled>{t('terms.confirmationButton.alreadyConfirmed')}</Button>;
    }
    return <Button onClick={confirmHandler}>{t('terms.confirmationButton.confirm')}</Button>;
}

export function TermsChangelog({
    changes,
    confirmed,
    userIsLoggedIn,
}: Readonly<{
    confirmed: boolean;
    userIsLoggedIn: boolean;
    changes: RichTextNode;
}>) {
    const { t } = useTranslation();
    if (!userIsLoggedIn || confirmed) {
        return null;
    }

    return (
        <>
            <Heading kind="tertiary">{t('terms.changelog.headline')}</Heading>
            <RichText richText={changes} componentMap={componentMap} linkMap={linkMap} />
        </>
    );
}

export default function Terms({ terms }: Readonly<TermsProps>): ReactElement {
    const { notify } = useNotification();
    const { t } = useTranslation();
    const { authentication } = useSession();
    const tenant = getTenant();
    const { updateTerms } = useTerms();
    const formattedDate = useMemo(() => formatLocalDate(terms.validFrom, tenant), [terms.validFrom, tenant]);
    const [confirmed, setConfirmed] = useState(terms.confirmed);
    const userIsLoggedIn = authentication === 'success';

    const confirmHandler = (e) => {
        e.preventDefault();

        termsApi
            .createConsent({
                consent: { accepted: true, termsName: terms.application, termsVersion: terms.version },
            })
            .then(() => {
                setConfirmed(true);
                updateTerms();
            })
            .catch(() => {
                notify({ message: t('terms.consent.create-error'), kind: 'error' });
            });
    };

    return (
        <Box>
            <Heading kind="secondary">{terms.title}</Heading>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box>
                    <Text size="small">
                        {t('terms.version.validFrom', { version: terms.version, validFrom: formattedDate })}
                    </Text>
                    <Spacer size={12} />
                    <TermsChangelog
                        userIsLoggedIn={userIsLoggedIn}
                        confirmed={confirmed}
                        changes={terms.changes as RichTextNode}
                    />
                    {!confirmed && userIsLoggedIn && (
                        <Banner kind="warning" dmId="needTermsConsent">
                            {t('terms.needConsent')}
                        </Banner>
                    )}
                    <Cell style={{ alignItems: 'center' }}>
                        <Download
                            asset={terms.asset as Asset}
                            text={t('terms.downloadButton', {
                                application: terms.application,
                                version: terms.version,
                            })}
                        />
                        <TermsConfirmationButton
                            userIsLoggedIn={userIsLoggedIn}
                            confirmed={confirmed}
                            confirmHandler={confirmHandler}
                        />
                    </Cell>
                </Box>
            </div>
        </Box>
    );
}
