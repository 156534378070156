import React, { ReactElement } from 'react';
import { componentMap, linkMap } from '../richtextRenderer/config';
import { RichTextNode } from '../richtextRenderer/render';
import RichText from '../richtextRenderer/RichText';

interface RichTextBlokProps {
    richText: RichTextNode;
}

export default function RichTextBlok({ richText }: Readonly<RichTextBlokProps>): ReactElement {
    return (
        <div>
            <RichText componentMap={componentMap} linkMap={linkMap} richText={richText} />
        </div>
    );
}
