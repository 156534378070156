/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchHitResponse,
    SearchHitResponseFromJSON,
    SearchHitResponseFromJSONTyped,
    SearchHitResponseToJSON,
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    pageSize?: number;
    /**
     * 
     * @type {Array<SearchHitResponse>}
     * @memberof SearchResponse
     */
    searchResults?: Array<SearchHitResponse>;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    searchTerm?: string;
    /**
     * 
     * @type {Tenant}
     * @memberof SearchResponse
     */
    tenant?: Tenant;
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    total?: number;
}

export function SearchResponseFromJSON(json: any): SearchResponse {
    return SearchResponseFromJSONTyped(json, false);
}

export function SearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'searchResults': !exists(json, 'searchResults') ? undefined : ((json['searchResults'] as Array<any>).map(SearchHitResponseFromJSON)),
        'searchTerm': !exists(json, 'searchTerm') ? undefined : json['searchTerm'],
        'tenant': !exists(json, 'tenant') ? undefined : TenantFromJSON(json['tenant']),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function SearchResponseToJSON(value?: SearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'pageSize': value.pageSize,
        'searchResults': value.searchResults === undefined ? undefined : ((value.searchResults as Array<any>).map(SearchHitResponseToJSON)),
        'searchTerm': value.searchTerm,
        'tenant': TenantToJSON(value.tenant),
        'total': value.total,
    };
}


