import { Box } from '@dm/design-system';
import React from 'react';
import Image from '../../../content/richtextRenderer/components/Image';
import { Asset } from '../../../content/StoryblokFieldTypes';

type Props = {
    icon: Asset;
};

const imageStyle = {
    marginBottom: '12px',
};

const leftColumnStyle = {
    paddingRight: '24px',
    flexGrow: 1,
    flex: 1,
};

export default function ApplicationLogo({ icon }: Readonly<Props>) {
    return (
        <Box style={[leftColumnStyle, imageStyle]}>
            <Image
                attrs={{
                    alt: icon.alt as string | undefined,
                    src: `${icon.filename}`,
                }}
            />
        </Box>
    );
}
