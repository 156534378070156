import React, { ReactElement } from 'react';
import { Box, useFela } from '@dm/design-system';

interface YoutubeProps {
    videoId: string;
}

export default function YoutubeEmbeddedPlayer({ videoId }: Readonly<YoutubeProps>): ReactElement<YoutubeProps> {
    const { css } = useFela();

    const fullWidthVideoStyle = {
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,
        height: 0,
        width: '100%',
        '> iframe': {
            position: 'absolute',
            width: '100%',
            height: '100%',
        },
    };
    return (
        <Box className={css(fullWidthVideoStyle)}>
            <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="youtube-player"
            />
        </Box>
    );
}
