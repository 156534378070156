import { useCallback, useEffect, useState } from 'react';
import { SessionContext } from './SessionProvider';
import { authenticationApi } from '../apiConfig';
import { UserInfo } from '../gen/ppapiclient';

export interface UseAuthProps {
    authentication: 'pending' | 'success' | 'unauthorized' | 'failed';
    userInfo: UserInfo | null;
}

export default function useAuth(sessionContext?: UseAuthProps): SessionContext {
    const [session, setSession] = useState<UseAuthProps>({
        authentication: 'pending',
        userInfo: null,
    });

    const refreshSession = useCallback(() => {
        authenticationApi
            .whoami()
            .then((optionalUserInfo) =>
                setSession({
                    userInfo: optionalUserInfo.userInfo,
                    authentication: optionalUserInfo.userInfo !== null ? 'success' : 'unauthorized',
                })
            )
            // FIX THAT should show error notification FIX WHEN we have global notification container
            .catch(() =>
                setSession({
                    authentication: 'failed',
                    userInfo: null,
                })
            );
    }, []);

    useEffect(() => {
        if (!sessionContext) {
            refreshSession();
        } else {
            setSession(sessionContext);
        }
    }, [refreshSession, sessionContext]);

    return {
        ...session,
        refreshSession,
    };
}
