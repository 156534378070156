import { useEffect, useRef, useState } from 'react';
import { contentApi } from '../apiConfig';
import { StoryResponse, Tenant } from '../gen/ppapiclient';
import { PageProps } from './components/Page';
import { RichTextNode } from './richtextRenderer/render';
import { getPreviewState } from './StoryblokPreviewProvider';
import { StoryData } from './StoryProvider';
import { useLoginSuccess } from '../session/LoginSuccessProvider';

export interface StoryblokInputEvent {
    story: StoryResponse;
}

export type StoryblokInputEventHandler = (event: StoryblokInputEvent) => void;

declare global {
    interface Window {
        // https://www.storyblok.com/docs/guide/essentials/visual-editor
        storyblok?: {
            init(): void;
            on(eventName: 'input', eventHandler: StoryblokInputEventHandler): void;
        };
    }
}

export default function useStoryData(fullSlug: string, tenant?: Tenant, refreshFlag?: boolean): StoryData {
    const [storyData, setStoryData] = useState<StoryData>({
        story: null,
        error: false,
        pending: true,
        isMaintenanceActive: false,
    });
    const { isLoginSuccessRequest } = useLoginSuccess();

    const previewModeContext = getPreviewState();

    useEffect(() => {
        if (previewModeContext.isPreviewMode) {
            const script = document.createElement('script');
            script.src = `//app.storyblok.com/f/storyblok-latest.js`;
            script.type = 'text/javascript';
            script.async = true;
            document.body.appendChild(script);
            return (): void => {
                document.body.removeChild(script);
            };
        }
        return undefined;
    }, [previewModeContext.isPreviewMode]);

    const needsInitialize = useRef(true);
    // This implementation contains a theoretical bug. It is not guaranteed that
    // storyblok bridge gets initialized as it is not guaranteed that this effect
    // will run _after_ storyblok script is loaded. This bug was never observed
    // during development implementation of fix will be delayed until the bug
    // actually occurred.
    useEffect(() => {
        if (window.storyblok && needsInitialize.current) {
            window.storyblok.init();
            window.storyblok.on('input', (event: { story: StoryResponse }): void => {
                setStoryData({
                    ...storyData,
                    story: event.story,
                    error: false,
                    pending: false,
                    isMaintenanceActive: false,
                });
            });
            needsInitialize.current = false;
        }
    });

    useEffect(() => {
        if (!isLoginSuccessRequest) {
            contentApi
                .getStory({ fullSlug, tenant })
                .then((storyResponse) =>
                    setStoryData({
                        story: storyResponse,
                        error: false,
                        pending: false,
                        isMaintenanceActive: storyResponse.maintenanceModeEnabled ?? false,
                    })
                )
                .catch(() => {
                    setStoryData((old) => ({
                        ...old,
                        error: true,
                        pending: false,
                    }));
                });
        }
    }, [fullSlug, tenant, refreshFlag, isLoginSuccessRequest]);

    return storyData;
}

export async function fetchStoryName(fullSlug: string) {
    return contentApi.getStory({ fullSlug }).then((story) => story.name);
}

export interface LabelBlok {
    component: string;

    [key: string]: string | RichTextNode;
}

export async function fetchLabelBlock(fullSlug: string, blokComponent: string): Promise<LabelBlok> {
    const story = await contentApi.getStory({ fullSlug });
    const content: PageProps = story?.content as PageProps;
    const allBlocks = content?.Description?.content?.find((contentNode) => contentNode.type === 'blok')?.attrs?.body;
    const labels = allBlocks?.find((blok) => blok.component === blokComponent);

    if (labels) {
        return labels as LabelBlok;
    }
    return Promise.reject(new Error('blokComponent not found'));
}
