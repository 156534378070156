import React from 'react';
import { theme } from '@dm/design-system';
import { useTranslation } from 'react-i18next';

type Props = Readonly<{
    id: string;
    label: string;
}>;

const tenantOptionStyle: object = {
    color: theme.palette.headline.hex,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
};

function UserProfileMenuHeader({ id, label }: Readonly<Props>) {
    const { t } = useTranslation();

    return (
        <div data-dmid={id} className="noninteractive" data-dd-privacy="mask" key={id} style={tenantOptionStyle}>
            {t('myaccount.header.greeting')} {label}
        </div>
    );
}

export default UserProfileMenuHeader;
