import { Separator, Spacer, View } from '@dm/design-system';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSession } from '../../session/SessionProvider';
import { MyUserDataSection, PersonalUserDetails, SupplierUserDetails } from './types';
import UserProfileNavigation from './components/UserProfileNavigation';
import MyUserDataProfileSection from './components/MyUserDataProfileSection';
import MyUserDataProfilePageContainer from './components/MyUserDataProfilePageContainer';

export default function MyUserDataPage() {
    const { userInfo } = useSession();
    const { t } = useTranslation();

    const personalDetails = userInfo?.details;
    const supplierDetails = userInfo?.details?.supplier;

    const personalUserDetails: PersonalUserDetails = useMemo(
        () => ({
            email: personalDetails?.email ?? null,
            firstName: personalDetails?.firstname ?? null,
            lastName: personalDetails?.lastname ?? null,
            phone: personalDetails?.phone ?? null,
            userId: personalDetails?.userId ?? null,
            supplierNumber: personalDetails?.supplierNumber ?? null,
        }),
        [personalDetails]
    );

    const supplierUserDetails: SupplierUserDetails = useMemo(
        () => ({
            name: supplierDetails?.name ?? null,
            category: supplierDetails?.categories?.toString() ?? null,
        }),
        [supplierDetails]
    );

    const personalDataSection: MyUserDataSection = {
        heading: t('userProfile.myData.personalData'),
        entries: [
            { label: t('userProfile.myData.personalData.userId'), value: personalUserDetails.userId },
            {
                label: t('userProfile.myData.personalData.name'),
                value:
                    personalUserDetails.firstName && personalUserDetails.lastName
                        ? `${personalUserDetails.firstName} ${personalUserDetails.lastName}`
                        : null,
            },
            {
                label: t('userProfile.myData.personalData.phone'),
                value: personalUserDetails.phone,
            },
            {
                label: t('userProfile.myData.personalData.eMail'),
                value: personalUserDetails.email,
            },
        ],
    };

    const personalSupplierSection: MyUserDataSection = {
        heading: t('userProfile.myData.personalSupplierData'),
        entries: [
            {
                label: `${t('userProfile.myData.personalData.SupplierNumber')}`,
                value: personalUserDetails.supplierNumber,
            },
            {
                label: `${t('userProfile.myData.personalData.SupplierName')}`,
                value: supplierUserDetails.name,
            },
        ],
    };

    return (
        <View>
            <UserProfileNavigation />
            <MyUserDataProfilePageContainer>
                <MyUserDataProfileSection heading={personalDataSection.heading} entries={personalDataSection.entries} />
                <Spacer size={24} />
                <Separator />
                <MyUserDataProfileSection
                    heading={personalSupplierSection.heading}
                    entries={personalSupplierSection.entries}
                />
                <Spacer size={12} />
            </MyUserDataProfilePageContainer>
        </View>
    );
}
