import React, { FormEventHandler, ReactElement, useContext, useEffect, useState } from 'react';
import { Box, Cell, Heading, Separator, Spacer } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import { RichTextNode } from '../../content/richtextRenderer/render';
import { Asset } from '../../content/StoryblokFieldTypes';
import { ApplicationsContext } from './Applications';
import ApplicationOrderForm from './components/ApplicationOrderForm';
import { ApplicationStatus, AppplicationAdditionalInformationFieldProps, statusConfig } from './types';
import { getApplicationType, getOrderState } from './utils';
import ApplicationLogo from './components/ApplicationLogo';
import ApplicationTerms from './components/ApplicationTerms';
import ApplicationExpandButton from './components/ApplicationExpandButton';
import { useNotification } from '../../notification/NotificationProvider';
import { applicationApi } from '../../apiConfig';

interface Props {
    additionalDescription: RichTextNode;
    headline: string;
    description: RichTextNode;
    additionalInformationFields?: AppplicationAdditionalInformationFieldProps[];
    icon: Asset;
    type: string;
    subtype?: string;
}

const applicationBoxStyle = {
    width: '100%',
    paddingBottom: '24px',
};

const rightColumnStyle = {
    flex: 4,
};

export default function Application({
    additionalDescription,
    headline,
    description,
    icon,
    additionalInformationFields,
    type,
    subtype,
}: Readonly<Props>): ReactElement {
    const { t } = useTranslation();
    const { notify } = useNotification();
    const { applicationStates, reloadApplicationContext, changeApplicationOrderState } =
        useContext(ApplicationsContext);

    const applicationType: string = getApplicationType(type, subtype);
    const applicationState = applicationStates[applicationType];
    const orderState = getOrderState(applicationStates, type, subtype);

    const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus>(statusConfig[orderState]);
    const [additionalInformationState, setAdditionalInformationState] = useState({});
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setApplicationStatus(statusConfig[orderState]);
    }, [applicationStates, orderState]);

    if (!applicationStatus.showSelection) {
        return <></>;
    }

    const onOrderSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        setLoading(true);
        const fallbackOrderState: string = orderState;
        changeApplicationOrderState(applicationType, 'ORDERED');

        applicationApi
            .orderApplication({
                applicationOrderDTO: {
                    applicationKey: applicationType,
                    additionalInformation: additionalInformationState,
                },
            })
            .then(() => {
                reloadApplicationContext();
                notify({ message: t('application.order.success'), kind: 'positive' });
            })
            .catch(() => {
                changeApplicationOrderState(applicationType, fallbackOrderState);
                notify({ message: t('application.order.error'), kind: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const setAdditionalInformationFieldValue = (fieldName: string, value: string) => {
        setAdditionalInformationState({ ...additionalInformationState, [fieldName]: value });
    };

    const unsubscribeApplication = () => {
        setLoading(true);

        applicationApi
            .unsubscribeApplication({
                appKey: applicationType,
            })
            .then(() => {
                reloadApplicationContext();
                notify({ message: t('application.unsubscribe.success'), kind: 'positive' });
            })
            .catch(() => {
                notify({ message: t('application.unsubscribe.error'), kind: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Box style={applicationBoxStyle}>
            <Cell>
                <ApplicationLogo icon={icon} />
                <Box style={rightColumnStyle}>
                    <Heading>{headline}</Heading>
                    <ApplicationTerms terms={applicationState.terms} type={type} status={applicationStatus} />
                    <Spacer size={24} />
                    {applicationState.expanded ? (
                        <ApplicationOrderForm
                            applicationType={applicationType}
                            status={applicationStatus}
                            description={description}
                            additionalDescription={additionalDescription}
                            additionalInformationFields={additionalInformationFields}
                            orderState={orderState}
                            terms={applicationStates[applicationType].terms}
                            loading={loading}
                            setAdditionalInformationFieldValue={setAdditionalInformationFieldValue}
                            onOrderSubmit={onOrderSubmit}
                            onUnsubscribeApplicationSubmit={unsubscribeApplication}
                        />
                    ) : (
                        <ApplicationExpandButton applicationType={applicationType} />
                    )}
                </Box>
            </Cell>
            <Separator />
        </Box>
    );
}
