/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Content tree item content details
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * storyblok slug component
     * @type {string}
     * @memberof Content
     */
    component: string;
    /**
     * determines if an element is visible in the navigation
     * @type {string}
     * @memberof Content
     */
    showInNavigation: string;
    /**
     * visibility
     * @type {string}
     * @memberof Content
     */
    visibility: string;
    /**
     * custom roles for applications
     * @type {string}
     * @memberof Content
     */
    customerroles?: string;
}

export function ContentFromJSON(json: any): Content {
    return ContentFromJSONTyped(json, false);
}

export function ContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Content {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'component': json['component'],
        'showInNavigation': json['show_in_navigation'],
        'visibility': json['visibility'],
        'customerroles': !exists(json, 'customerroles') ? undefined : json['customerroles'],
    };
}

export function ContentToJSON(value?: Content | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'component': value.component,
        'show_in_navigation': value.showInNavigation,
        'visibility': value.visibility,
        'customerroles': value.customerroles,
    };
}


