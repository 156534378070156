/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Tenant,
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './';

/**
 * request to reset password
 * @export
 * @interface PasswordResetRequest
 */
export interface PasswordResetRequest {
    /**
     * e-mail address, must be a valid e-mail address
     * @type {string}
     * @memberof PasswordResetRequest
     */
    email: string;
    /**
     * 
     * @type {Tenant}
     * @memberof PasswordResetRequest
     */
    tenant: Tenant;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequest
     */
    recaptchaToken?: string;
}

export function PasswordResetRequestFromJSON(json: any): PasswordResetRequest {
    return PasswordResetRequestFromJSONTyped(json, false);
}

export function PasswordResetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordResetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'tenant': TenantFromJSON(json['tenant']),
        'recaptchaToken': !exists(json, 'recaptchaToken') ? undefined : json['recaptchaToken'],
    };
}

export function PasswordResetRequestToJSON(value?: PasswordResetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'tenant': TenantToJSON(value.tenant),
        'recaptchaToken': value.recaptchaToken,
    };
}


