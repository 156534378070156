import React, { FormEventHandler, ReactElement, useEffect, useState } from 'react';
import { Box, Button, Cell, Form, Heading, Spacer, Text, WithTheme } from '@dm/design-system';
import { useTranslation } from 'react-i18next';
import { UserRegistration, UserRegistrationCountryCodeEnum } from '../../../gen/ppapiclient';
import getTenant from '../../../content/getTenant';
import TermsConfirmBlock from '../../../content/components/termsPage/TermsConfirmBlock';
import { RegistrationFormLabels, RegistrationFormProps, UpdateRegistrationValue } from '../types';
import RegistrationInput from './RegistrationInput';
import CountrySelect from './CountrySelect';

const initialDTO: Omit<UserRegistration, 'countryCode' | 'tenant'> = {
    category: undefined,
    city: '',
    company: '',
    vendorNumber: '',
    dmContact: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    street: '',
    zip: '',
};

const pageStyle = { flex: 1 };
const spacerSizes = { s: 24 };
const emptyBoxStyle = { flex: 1 };
const submitStyle = ({ theme }: WithTheme) => ({
    alignItems: 'unset',

    [theme.breakpoints.horizontal.s.mediaQuery]: {
        alignItems: 'flex-end',
    },
});

export default function RegistrationForm({
    formValues,
    onSubmit,
    firstNameLabel,
    lastNameLabel,
    companyLabel,
    vendorNumberLabel,
    dmContactLabel,
    categoryLabel,
    streetLabel,
    zipCodeLabel,
    cityLabel,
    countryCodeLabel,
    emailLabel,
    requiredLabel,
    submitLabel,
    userFieldSetLabel,
    companyFieldSetLabel,
    errorFieldMessages,
    partnerPortalTerms,
}: Readonly<RegistrationFormProps>): ReactElement {
    const { t } = useTranslation();
    const [userRegistration, setUserRegistration] = useState<UserRegistration>({
        ...initialDTO,
        countryCode: UserRegistrationCountryCodeEnum[getTenant()],
        tenant: getTenant(),
    });

    const labels: RegistrationFormLabels = {
        city: cityLabel,
        company: companyLabel,
        countryCode: countryCodeLabel,
        dmContact: dmContactLabel,
        email: emailLabel,
        firstName: firstNameLabel,
        lastName: lastNameLabel,
        street: streetLabel,
        vendorNumber: vendorNumberLabel,
        zip: zipCodeLabel,
        category: categoryLabel,
    };

    const setDtoItem: UpdateRegistrationValue = (item, value) =>
        setUserRegistration({
            ...userRegistration,
            [item]: value,
        });

    useEffect(() => {
        if (formValues?.userRegistration) {
            setUserRegistration(formValues.userRegistration);
        }
    }, [formValues]);

    const submit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        onSubmit({ userRegistration });
    };

    return (
        <Form id="registration-form" method="post" onSubmit={submit}>
            <Box style={pageStyle}>
                <Spacer size={15} />
                <Heading kind="tertiary">{companyFieldSetLabel}</Heading>
                <Spacer size={15} />
                <Cell>
                    <RegistrationInput
                        field="company"
                        labels={labels}
                        setDtoItem={setDtoItem}
                        errors={errorFieldMessages}
                        values={userRegistration}
                    />
                    <Spacer size={6} sizes={spacerSizes} />
                    <RegistrationInput
                        field="street"
                        labels={labels}
                        setDtoItem={setDtoItem}
                        errors={errorFieldMessages}
                        values={userRegistration}
                    />
                </Cell>
                <Cell>
                    <RegistrationInput
                        field="zip"
                        maxLength={10}
                        labels={labels}
                        setDtoItem={setDtoItem}
                        errors={errorFieldMessages}
                        values={userRegistration}
                    />
                    <Spacer size={6} sizes={spacerSizes} />
                    <RegistrationInput
                        field="city"
                        maxLength={100}
                        labels={labels}
                        setDtoItem={setDtoItem}
                        errors={errorFieldMessages}
                        values={userRegistration}
                    />
                </Cell>
                <Cell style={{ marginBottom: '10', width: '100%' }}>
                    <CountrySelect labels={labels} userRegistration={userRegistration} setDtoItem={setDtoItem} />
                    <Spacer size={6} sizes={spacerSizes} />
                    <Box style={emptyBoxStyle}>
                        <></>
                    </Box>
                </Cell>
                <Cell>
                    <RegistrationInput
                        field="vendorNumber"
                        pattern={/[A-Za-z0-9]{1,6}/}
                        maxLength={6}
                        minLength={1}
                        labels={labels}
                        setDtoItem={setDtoItem}
                        errors={errorFieldMessages}
                        values={userRegistration}
                    />
                    <Spacer size={6} sizes={spacerSizes} />
                    <RegistrationInput
                        field="dmContact"
                        maxLength={64}
                        labels={labels}
                        setDtoItem={setDtoItem}
                        errors={errorFieldMessages}
                        values={userRegistration}
                    />
                </Cell>
                <Spacer size={15} />
                <Heading kind="tertiary">{userFieldSetLabel}</Heading>
                <Spacer size={15} />

                <Cell>
                    <RegistrationInput
                        field="firstName"
                        labels={labels}
                        setDtoItem={setDtoItem}
                        errors={errorFieldMessages}
                        values={userRegistration}
                    />
                    <Spacer size={6} sizes={spacerSizes} />
                    <RegistrationInput
                        field="lastName"
                        labels={labels}
                        setDtoItem={setDtoItem}
                        errors={errorFieldMessages}
                        values={userRegistration}
                    />
                </Cell>
                <Cell>
                    <RegistrationInput
                        field="category"
                        labels={labels}
                        maxLength={128}
                        errors={errorFieldMessages}
                        values={userRegistration}
                        setDtoItem={setDtoItem}
                        optional
                    />
                    <Spacer size={6} sizes={spacerSizes} />
                    <RegistrationInput
                        field="email"
                        type="email"
                        setDtoItem={setDtoItem}
                        errors={errorFieldMessages}
                        values={userRegistration}
                        labels={labels}
                    />
                </Cell>
                <Spacer size={25} />
                <TermsConfirmBlock
                    terms={partnerPortalTerms}
                    introText={t('registration.term.confirm.introText')}
                    showIntroText
                />
                <Spacer size={25} />
                <Text>* {requiredLabel}</Text>
                <Spacer size={20} />
                <Box style={submitStyle}>
                    <Button type="submit">{submitLabel}</Button>
                </Box>

                <Spacer size={50} />
            </Box>
            <Spacer size={20} />
        </Form>
    );
}
