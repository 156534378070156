import React from 'react';
import { Box, Spacer } from '@dm/design-system';
import { MyUserDataSection } from '../types';
import MyUserDataSectionHeader from './MyUserDataSectionHeader';
import MyUserDataSectionEntry from './MyUserDataSectionEntry';

export default function MyUserDataProfileSection({ heading, entries }: Readonly<MyUserDataSection>) {
    return (
        <Box>
            <MyUserDataSectionHeader heading={heading} />
            <Spacer size={12} />
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    rowGap: '12px',
                }}
            >
                {entries.map((entry) => (
                    <MyUserDataSectionEntry key={`${entry.label}`} label={entry.label} value={entry.value} />
                ))}
            </Box>
        </Box>
    );
}
