import React, { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ContentTreeItem, FetchParams, RequestContext, StoryResponse } from '../gen/ppapiclient';
import { translateToSlug } from '../navigation/slug';
import useStoryData from './useStoryData';
import getTenant from './getTenant';
import { contentApi } from '../apiConfig';

export interface StoryData {
    story: StoryResponse | null;
    error: boolean;
    pending: boolean;
    isMaintenanceActive: boolean;
}

export type StoryProviderType = {
    storyData: StoryData;
    contentTree: ContentTree;
    isMaintenancePageActive: boolean;
    refreshStory: () => void;
};

export interface ContentTree {
    navigation: ContentTreeItem[];
    footer: ContentTreeItem[];
}

const StoryContext = createContext<StoryProviderType>({
    storyData: {
        error: false,
        isMaintenanceActive: true,
        pending: false,
        story: null,
    },
    contentTree: {
        navigation: [],
        footer: [],
    } as ContentTree,
    isMaintenancePageActive: false,
    refreshStory: () => {
        // initial state
    },
});

export const checkMaintenanceState = async (
    context: RequestContext,
    storyContext?: StoryProviderType
): Promise<void | FetchParams> => {
    if (storyContext?.storyData.isMaintenanceActive && !context.url.includes('maintenance')) {
        return undefined; // Return undefined to indicate maintenance mode is active
    }

    return { url: context.url, init: context.init };
};

export function StoryProvider({ children }): ReactElement {
    const [contentTree, setContentTree] = useState<ContentTree>({
        navigation: [],
        footer: [],
    } as ContentTree);

    const { pathname } = useLocation();
    const slug = translateToSlug(pathname);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const story = useStoryData(slug, getTenant(), refreshFlag);

    const fetchContentTree = () => {
        // Once the story data is loaded, continue with fetching the content tree
        contentApi
            .getTree({ tenant: getTenant() })
            .then((contentTreesResponse) => {
                const footerItems = contentTreesResponse.footer;
                const navigationItems = contentTreesResponse.navigation;

                setContentTree({
                    navigation: navigationItems,
                    footer: footerItems,
                });
            })
            .catch(() => {
                // do nothing
            });
    };

    useEffect(() => {
        if (!story.isMaintenanceActive && !story.pending) {
            fetchContentTree();
        }
    }, [story.isMaintenanceActive, story.pending, refreshFlag]);

    const refreshStory = useCallback(() => {
        setRefreshFlag((prevFlag) => !prevFlag);
    }, []);

    const storyContext = useMemo(() => {
        return {
            storyData: story,
            contentTree,
            isMaintenancePageActive: story.isMaintenanceActive,
            refreshStory,
        };
    }, [story, contentTree, refreshStory]);

    return <StoryContext.Provider value={storyContext}>{children}</StoryContext.Provider>;
}

export const useStoryProvider = (): StoryProviderType => useContext(StoryContext);

export const useContentTree = () => useStoryProvider().contentTree;
export const useMaintenanceState = () => useStoryProvider().isMaintenancePageActive;
export const useStoryContext = () => useStoryProvider().storyData;
