/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoryResponse
 */
export interface StoryResponse {
    /**
     * content
     * @type {object}
     * @memberof StoryResponse
     */
    content: object;
    /**
     * Slug of the story
     * @type {string}
     * @memberof StoryResponse
     */
    fullSlug: string;
    /**
     * Name of the story as listed in Storyblok content tree
     * @type {string}
     * @memberof StoryResponse
     */
    name: string;
    /**
     * publishing date
     * @type {Date}
     * @memberof StoryResponse
     */
    publishedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof StoryResponse
     */
    maintenanceModeEnabled?: boolean;
}

export function StoryResponseFromJSON(json: any): StoryResponse {
    return StoryResponseFromJSONTyped(json, false);
}

export function StoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': json['content'],
        'fullSlug': json['fullSlug'],
        'name': json['name'],
        'publishedAt': (new Date(json['published_at'])),
        'maintenanceModeEnabled': !exists(json, 'maintenanceModeEnabled') ? undefined : json['maintenanceModeEnabled'],
    };
}

export function StoryResponseToJSON(value?: StoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content,
        'fullSlug': value.fullSlug,
        'name': value.name,
        'published_at': (value.publishedAt.toISOString()),
        'maintenanceModeEnabled': value.maintenanceModeEnabled,
    };
}


