import { Heading } from '@dm/design-system';
import React, { ReactElement } from 'react';
import { PageProps } from '../Page';
import RichText from '../../richtextRenderer/RichText';
import { componentMap, linkMap } from '../../richtextRenderer/config';

export default function MaintenancePage({ Description, Title }: Readonly<PageProps>): ReactElement {
    return (
        <div>
            <Heading kind="primary" style={{ paddingBottom: '11px', wordWrap: 'break-word' }}>
                {Title}
            </Heading>
            <RichText componentMap={componentMap} linkMap={linkMap} richText={Description} />
        </div>
    );
}
