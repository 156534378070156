import { Box, Button, Cell, Form, Spacer } from '@dm/design-system';
import React, { FormEventHandler, ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../content/components/idm/util/fields/TextInput';
import { findErrorMessageByField } from '../utils';
import RichText from '../../../content/richtextRenderer/RichText';
import { componentMap, linkMap } from '../../../content/richtextRenderer/config';
import { SetMfaFormProps } from '../types';

export default function RegistrationMfaForm({
    onSubmit,
    submitLabel,
    phoneLabel,
    errorFieldMessages,
    mfaDescription,
    onPreviousStepButtonClick,
    backLabel,
}: Readonly<SetMfaFormProps>): ReactElement {
    const phoneNumber = useRef('');
    const { t } = useTranslation();

    const submit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        onSubmit({
            phone: phoneNumber.current,
        });
    };

    return (
        <Box>
            <RichText componentMap={componentMap} linkMap={linkMap} richText={mfaDescription} />
            <Form name="set-mfa" method="post" onSubmit={submit}>
                <TextInput
                    label={phoneLabel}
                    type="tel"
                    required
                    maxLength={50}
                    dmId="phone"
                    error={findErrorMessageByField('phone', errorFieldMessages, t)}
                    onChange={(value: string): void => {
                        phoneNumber.current = value;
                    }}
                />
                <Spacer size={20} />
                <Cell style={{ justifyContent: 'space-between' }}>
                    <Button style={{ flex: 2 }} type="button" kind="secondary" onClick={onPreviousStepButtonClick}>
                        {backLabel}
                    </Button>
                    <Spacer style={{ flex: '3 0 12px' }} size={12} />
                    <Button style={{ flex: 5 }} type="submit">
                        {submitLabel}
                    </Button>
                </Cell>
            </Form>
        </Box>
    );
}
