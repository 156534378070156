import React, { ReactElement, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import StoryRenderer from '../../content/StoryRenderer';
import { newsletterApi } from '../../apiConfig';
import AuthenticatedMessage from '../../content/components/errorPage/AuthenticatedMessage';

export default function NewsletterOptOutConfirmation(): ReactElement {
    const [searchParams] = useSearchParams();
    const tokenQueryParam = searchParams.get('token');
    const channelQueryParam = searchParams.get('channel');

    useEffect(() => {
        if (tokenQueryParam && channelQueryParam) {
            newsletterApi
                .unsubscribeToNewsletterChannelAnonymous({
                    token: tokenQueryParam,
                    channel: channelQueryParam,
                })
                .then(() => {
                    // do nothing
                });
        }
    }, [channelQueryParam, tokenQueryParam]);

    if (!tokenQueryParam || !channelQueryParam) {
        return <AuthenticatedMessage />;
    }

    return <StoryRenderer />;
}
