import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Link } from '@dm/design-system';
import { useLocation } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import getTenant from '../../getTenant';
import Login from '../../../session/components/Login';

export default function NotAuthenticatedMessage(): ReactElement {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    const tenant = `/${getTenant().toLocaleLowerCase()}/`;
    const redirectSlugToCurrentPath = pathname.replace(tenant, '');

    function translateOrFallback(key: string, fallback: string) {
        const translation = t(key);
        return translation === key ? fallback : translation;
    }

    const buttons = (
        <Cell>
            <Login label={translateOrFallback('loginButtonLabel', 'Log in')} redirectSlug={redirectSlugToCurrentPath} />
            <Link kind="secondary" as={Link} href={tenant} style={{ marginLeft: '10px', marginTop: '20px' }}>
                {translateOrFallback('homeButtonLabel', 'Home')}
            </Link>
        </Cell>
    );

    return (
        <ErrorPage
            title={translateOrFallback('errorPage.notAuthenticated.title', 'Session expired')}
            description={translateOrFallback(
                'errorPage.notAuthenticated.description',
                'Your session has expired. Please log in again.'
            )}
            additionalButtons={buttons}
        />
    );
}
