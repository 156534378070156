import { FelaStyle, ThemeProps, useFela } from '@dm/design-system';

import React, { Fragment, ReactElement } from 'react';
import RenderTree from './RenderTree';
import { ContentTreeItem } from '../../../gen/ppapiclient';

interface SublinksProps {
    tree: ContentTreeItem;
    currentSlug: string;
}

const listStyle: FelaStyle<ThemeProps, Record<string, unknown>> = ({ theme }) => ({
    paddingLeft: theme.dimension.spacing.s.rem,
});

export default function Sublinks({ tree, currentSlug }: Readonly<SublinksProps>): ReactElement {
    const { css } = useFela<ThemeProps>();
    const shouldRenderSublinks = tree.children?.length !== 0 && currentSlug.startsWith(tree.fullSlug);

    if (shouldRenderSublinks) {
        return (
            <div className={css(listStyle)}>
                {tree.children?.map((subTree) => (
                    <Fragment key={subTree.fullSlug}>
                        <RenderTree currentSlug={currentSlug} tree={subTree} />
                    </Fragment>
                ))}
            </div>
        );
    }
    return <></>;
}
