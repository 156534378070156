import React from 'react';
import { Separator } from '@dm/design-system';
import OffCanvasNavigationItems from './OffCanvasNavigationItems';
import OffCanvasNavigationItem from './OffCanvasNavigationItem';
import { ContentTreeItem } from '../../../../gen/ppapiclient';

const separatorStyle = { marginTop: 0, marginBottom: 0 };

type OffCanvasNavigationProps = {
    id: string;
};

function ItemRenderer({ item, customTitle }: Readonly<{ item: ContentTreeItem; customTitle?: string }>) {
    return <OffCanvasNavigationItem key={`item-${item.uuid}`} item={item} customTitle={customTitle} />;
}

function OffCanvasNavigation({ id }: Readonly<OffCanvasNavigationProps>) {
    return (
        <>
            <Separator style={separatorStyle} />
            <OffCanvasNavigationItems id={id} renderItem={ItemRenderer} />
        </>
    );
}

export default OffCanvasNavigation;
