import React, { ReactElement, useCallback } from 'react';
import { ComponentMap, renderContent, RichTextComponentProps, RichTextNode } from './render';
import TextNode, { LinkMap } from './TextNode';

export interface RichTextProps {
    componentMap: ComponentMap;
    linkMap: LinkMap;
    richText: RichTextNode;
}

export default function RichText({ richText, componentMap, linkMap }: Readonly<RichTextProps>): ReactElement {
    return (
        <>
            {renderContent(richText.content, {
                ...componentMap,
                text: useCallback(
                    ({ text, marks }: RichTextComponentProps) => {
                        return <TextNode linkMap={linkMap} text={text} marks={marks} />;
                    },
                    [linkMap]
                ),
            })}
        </>
    );
}
