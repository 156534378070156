import { FelaStyle, ThemeProps, useFela } from '@dm/design-system';
import { ComponentType, createElement, CSSProperties, Fragment, ReactElement } from 'react';
import { LinkType, RichTextComponentProps, RichtextMark, RichTextNode } from './render';

export interface LinkProps {
    text: string;
    href: string;
    target: '_self' | '_blank';
    className: string;
}

export type LinkMap = Record<'internal' | 'external', ComponentType<LinkProps>>;

const marksToStyle: Record<'underline' | 'strike' | 'bold' | 'italic', CSSProperties> = {
    underline: {
        textDecoration: 'underline',
    },
    strike: {
        textDecoration: 'line-through',
    },
    bold: {
        fontWeight: 'bold',
    },
    italic: {
        fontStyle: 'italic',
    },
};

function isStyleType(type: RichtextMark['type']): type is keyof typeof marksToStyle {
    return type in marksToStyle;
}

function addStyle(oldStyle: CSSProperties, { type }: RichtextMark): CSSProperties {
    if (isStyleType(type)) {
        const stylingObject = marksToStyle[type];
        const key = Object.keys(stylingObject)[0] as keyof CSSProperties;
        return {
            ...oldStyle,
            [key]: oldStyle[key] ? `${oldStyle[key]} ${stylingObject[key]}` : stylingObject[key],
        };
    }
    return oldStyle;
}

export const textStyle: FelaStyle<ThemeProps, RichTextNode> = ({ marks = [] }) => marks.reduce(addStyle, {});

function formatHref(type: LinkType | undefined, href: string | undefined, anchor: string | null | undefined): string {
    const prefix = type === 'email' ? 'mailto:' : '';
    const postfix = anchor ? `#${anchor}` : '';
    return `${prefix}${href ?? ''}${postfix}`;
}

export interface TextNodeProps extends RichTextComponentProps {
    linkMap: LinkMap;
}

export default function TextNode({ text, marks, linkMap }: TextNodeProps): ReactElement {
    const { css } = useFela<ThemeProps>({ text, marks });
    const className = css(textStyle);
    const modifierMark = marks?.find(({ type }) => type === 'link' || type === 'code');
    if (modifierMark?.type === 'link') {
        const isInternal = modifierMark.attrs?.linktype === 'story';
        const formattedHref = formatHref(
            modifierMark.attrs?.linktype,
            modifierMark.attrs?.href,
            modifierMark.attrs?.anchor
        );
        return createElement(linkMap[isInternal ? 'internal' : 'external'] ?? Fragment, {
            href: formattedHref,
            target: modifierMark.attrs?.target || '_self', // NOSONAR
            className,
            text: text ?? '',
            key: text?.trim(),
        });
    }

    if (modifierMark?.type === 'code') {
        return createElement('code', { className }, text);
    }

    return createElement('span', { className }, text);
}
