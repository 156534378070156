/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * entries as full slugs for the user menu
 * @export
 * @interface UserMenuConfigDTO
 */
export interface UserMenuConfigDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserMenuConfigDTO
     */
    userMenuFullSlugs?: Array<string>;
}

export function UserMenuConfigDTOFromJSON(json: any): UserMenuConfigDTO {
    return UserMenuConfigDTOFromJSONTyped(json, false);
}

export function UserMenuConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMenuConfigDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userMenuFullSlugs': !exists(json, 'userMenuFullSlugs') ? undefined : json['userMenuFullSlugs'],
    };
}

export function UserMenuConfigDTOToJSON(value?: UserMenuConfigDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userMenuFullSlugs': value.userMenuFullSlugs,
    };
}


