/* tslint:disable */
/* eslint-disable */
/**
 * Partnerportal Service
 * The partnerportal we\'ve all been waiting for!
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplierContact
 */
export interface SupplierContact {
    /**
     * 
     * @type {string}
     * @memberof SupplierContact
     */
    department?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierContact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierContact
     */
    fax?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierContact
     */
    personName?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierContact
     */
    phone?: string;
}

export function SupplierContactFromJSON(json: any): SupplierContact {
    return SupplierContactFromJSONTyped(json, false);
}

export function SupplierContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'department': !exists(json, 'department') ? undefined : json['department'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'fax': !exists(json, 'fax') ? undefined : json['fax'],
        'personName': !exists(json, 'personName') ? undefined : json['personName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function SupplierContactToJSON(value?: SupplierContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'department': value.department,
        'email': value.email,
        'fax': value.fax,
        'personName': value.personName,
        'phone': value.phone,
    };
}


