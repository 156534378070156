import { FetchParams, RequestContext } from '../gen/ppapiclient';

let csrfToken: string | undefined;

export const clearCsrfToken = async () => {
    csrfToken = undefined;
};

const CSRF_TOKEN_HEADER = 'X-XSRF-TOKEN';

const updateCsrfToken = async () => {
    try {
        const response = await fetch(`${backendUrl}/security/csrf`, {
            method: 'POST',
            credentials: 'include',
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            throw new Error(`Network response was not ok: ${response.status} - ${errorMessage}`);
        }

        const data = await response.json();
        csrfToken = data.token;

        if (!csrfToken) {
            throw new Error('CSRF token not found in the response.');
        }
    } catch (error) {
        csrfToken = undefined;
    }
};

const requiresCsrf = (method: string | undefined): boolean => {
    return method === 'POST' || method === 'PUT' || method === 'DELETE';
};

const csrfMiddleware = async (context: RequestContext): Promise<FetchParams> => {
    const { method } = context.init;

    const csrfProtectionRequired = requiresCsrf(method);

    if (csrfProtectionRequired) {
        if (!csrfToken) {
            await updateCsrfToken();
        }
        if (csrfToken) {
            return {
                ...context,
                init: {
                    ...context.init,
                    headers: { ...context.init.headers, [CSRF_TOKEN_HEADER]: csrfToken },
                },
            };
        }
    }
    return context;
};

export default csrfMiddleware;
