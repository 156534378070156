import { Button, theme, ThemeProps, useFela } from '@dm/design-system';
import React from 'react';
import { getLogoutUrl } from '../../../content/components/idm/routes';

type Props = Readonly<{
    id: string;
    label: string;
}>;

const logoutButtonStyle = {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    ':hover': {
        transform: 'scale(1.05)',
    },
    color: theme.palette.icon.hex,
    outline: 'none !important',
};

function UserProfileMenuFooter({ id, label }: Props) {
    const { css } = useFela<ThemeProps>();

    return (
        <Button as="a" className={css(logoutButtonStyle)} href={`${getLogoutUrl()}`} dmId={id}>
            {label}
        </Button>
    );
}

export default UserProfileMenuFooter;
